<template>
  <div class="offcanvas-header-action" v-if="recurrency?.status === 'active'">
    <p
      class="text-subtitle cursor-pointer text-danger mb-0"
      @click="handleCancelConfirmed"
    >
      Cancelar recorrência
    </p>
  </div>

  <div class="offcanvas-body">
    <LoadingContainer v-if="loading" />
    <template v-else>
      <div class="d-flex align-items-center justify-content-between">
        <p class="text-pagesubtitle">{{ recurrency?.title }}</p>
      </div>
      <p class="text-paragraph">{{ recurrency?.description }}</p>
      <p class="text-subtitle mb-0" :class="recurrency?.status_color">
        {{ `Esta recorrência está ${recurrency?.status_label}` }}
      </p>

      <hr />
    </template>

    <template v-if="recurrency.next_appointment">
      <p class="text-header mb-0">Próxima</p>
      <div
        class="d-flex py-4 border-bottom align-items-center cursor-pointer mb-4"
        @click="handleAppointment(recurrency.next_appointment.id)"
      >
        <div>
          <p class="text-subtitle mb-2">
            {{ recurrency.next_appointment.formatted_datetime }}
          </p>
          <div class="d-flex align-items-center">
            <p class="text-label text-primary50 mb-0 me-2">
              R${{ recurrency.next_appointment.value }}
            </p>
            <p
              class="text-label mb-0"
              :class="recurrency.next_appointment.status_color"
            >
              {{ recurrency.next_appointment.status_label }}
            </p>
          </div>
        </div>
        <i class="fa-solid fa-chevron-right text-primary ms-auto fa-lg"></i>
      </div>
    </template>

    <template v-if="recurrency.past_appointments">
      <p class="text-header mb-0">Realizadas</p>
      <div
        class="d-flex py-4 border-bottom align-items-center cursor-pointer"
        @click="handleAppointment(item.id)"
        :key="index"
        v-for="(item, index) of recurrency.past_appointments"
      >
        <div>
          <p class="text-subtitle mb-2">
            {{ item.formatted_datetime }}
          </p>
          <div class="d-flex align-items-center">
            <p class="text-label text-primary50 mb-0 me-2">
              R${{ item.value }}
            </p>
            <p class="text-label mb-0" :class="item.status_color">
              {{ item.status_label }}
            </p>
          </div>
        </div>
        <i class="fa-solid fa-chevron-right text-primary ms-auto fa-lg"></i>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppointmentRecurrencyController from "./AppointmentRecurrencyController";
export default defineComponent({
  mixins: [AppointmentRecurrencyController],
});
</script>

<style scoped></style>
