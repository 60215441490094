<template>
  <div class="card">
    <div class="card-header">
      <p class="text-header mb-0">Sobre</p>
    </div>
    <div class="card-body">
      <div
        @click="item.action"
        class="d-flex align-items-center pb-4"
        :class="{
          'cursor-pointer': item.action,
          'border-bottom': index + 1 != items.length,
          'pt-4': index != 0,
        }"
        v-for="(item, index) in items"
        :key="index"
      >
        <p class="text-paragraph mb-0">
          {{ item.title }}
        </p>

        <i class="fa-solid fa-chevron-right ms-auto" v-if="item.action"></i>
        <p class="text-muted text-paragraph mb-0 ms-auto" v-if="item.value">
          {{ item.value }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  IOffcanvasConfig,
  OffcanvasViewKey,
  OffcanvasViewsConfig,
  navigateCanvas,
} from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    toggle() {
      return window.innerWidth > 964;
    },
  },
  data() {
    return {
      items: [
        {
          title: "Termos de uso",
          action: () => this.handleNavigate(OffcanvasViewsConfig.terms_of_use),
        },
        {
          title: "Política de privacidade",
          action: () =>
            this.handleNavigate(OffcanvasViewsConfig.privacy_policy),
        },
        {
          title: "Versão do aplicativo",
          value: this.$envString,
        },
      ],
    };
  },
  methods: {
    handleNavigate(view: IOffcanvasConfig) {
      navigateCanvas(view.path as OffcanvasViewKey, view, this.toggle);
    },
  },
});
</script>

<style scoped></style>
