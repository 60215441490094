<template>
  <div class="offcanvas-body">
    <LoadingContainer v-if="loadingItems" />
    <template v-else>
      <!-- PROFILE -->
      <Profile :user="item.user" :chat="item.chat" />

      <!-- BOLETO PAYMENT -->
      <BoletoPayment v-if="item?.is_waiting_boleto_payment" />

      <!-- PAYMENT ERROR -->
      <PaymentError v-if="item?.payment_error" />

      <!-- RESCHEDULE REQUEST -->
      <RescheduleRequest
        v-if="item?.rescheduleRequest"
        :request="item?.rescheduleRequest"
        :callback="getData"
      />

      <!-- INFO -->
      <Info :item="item" />

      <!-- RATING -->
      <Rating :rating="item.my_rating" v-if="item.my_rating" />

      <!-- RECURRENCY -->
      <Recurrency :recurrency="item?.recurrency" v-if="item?.recurrency" />

      <!-- VALUES -->
      <Values :item="item" />

      <!-- APPOINTMENT RECEIPT -->
      <Receipt
        v-if="item?.status === 'concluido'"
        :item="item"
        :callback="getData"
      />

      <!-- CALL LINK -->
      <CallLink v-if="item?.call_link" :callLink="item.call_link" />

      <!-- RECOMMENDATIONS -->
      <Recommendations />

      <!-- APPOINTMENT CANCELLATION -->
      <Cancellation :item="item" :callback="getData" />
    </template>
  </div>

  <!-- ACTIONS -->
  <div
    class="offcanvas-footer"
    v-if="
      !loadingItems &&
      item?.status !== apptStatus.CANCELADO &&
      item?.status !== apptStatus.CONCLUIDO &&
      item?.status !== apptStatus.RECUSADO
    "
  >
    <Actions :item="item" :callback="getData" />
  </div>
</template>

<script lang="ts">
import AppointmentDetailController from "./AppointmentDetailController";
export default AppointmentDetailController;
</script>
