<template>
  <div class="card">
    <div class="card-header">
      <p class="text-header mb-0">Valores personalizados</p>
      <p
        class="text-subtitle mb-0 text-secondary cursor-pointer"
        @click="handleAddCoupon"
      >
        Criar novo código
      </p>
    </div>
    <div class="card-body">
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <EmptyState
          v-if="!items.length"
          :totalItemsRegistered="totalItemsRegistered"
        >
          <template v-slot:noItemsRegistered>
            <span class="fa-2x fas fa-folder-open text-muted mb-2"></span>
            <p class="text-paragraph">Nenhum código criado</p>
          </template>
        </EmptyState>

        <div v-for="(item, index) in items" :key="index" class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <img src="@/assets/img/coupon_icon.svg" alt="" />
              <p class="text-subtitle mb-0 ms-2">
                {{ item.percent_off_label }}
              </p>
            </div>
            <div
              class="border border-primary border-2 rounded p-3 d-flex my-3"
              style="border-style: dashed !important"
            >
              <div>
                <p class="text-header mb-0">{{ item.code }}</p>
                <div v-if="item.redeem_by">
                  <p class="text-label text-danger mb-0" v-if="item.is_expired">
                    Expirado
                  </p>
                  <p class="text-label text-muted mb-0" v-else>
                    {{ item.redeem_by_label }}
                  </p>
                </div>
              </div>
              <div
                class="d-flex align-items-center ms-auto cursor-pointer"
                @click="handleCopyCoupon(item)"
                v-if="!item.is_expired"
              >
                <p
                  class="text-label text-primary50 me-2 mb-0"
                  v-if="copied && currentItem == item.id"
                >
                  Copiado
                </p>
                <i
                  class="fa-solid fa-copy display-5"
                  :class="{
                    'text-primary50': copied && currentItem == item.id,
                    'text-primary': !copied || currentItem !== item.id,
                  }"
                ></i>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div
                class="d-flex align-items-center cursor-pointer"
                @click="handleDeleteCoupon(item)"
              >
                <i class="fa-solid fa-trash-alt text-muted"></i>
                <p class="text-subtitle mb-0 ms-2 text-muted">Remover</p>
              </div>
              <p class="text-paragraph" v-if="item.max_redemptions_customers">
                {{ item.max_redemptions_customers_label }}
              </p>
            </div>
          </div>
        </div>
        <div class="card cursor-pointer">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                <img src="@/assets/img/coupon_icon.svg" alt="" />
                <p class="text-subtitle mb-0 ms-2">Criar novo código</p>
              </div>
              <i class="fa-solid fa-chevron-right text-primary ms-auto"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CouponsController from "./CouponsController";
export default CouponsController;
</script>
