<template>
  <div class="alert bg-warning-soft">
    <p class="text-subtitle">Ops! Houve um problema com o pagamento.</p>
    <p class="text-paragraph mb-0">
      Por favor, aguarde o paciente regularizar o pagamento para poder
      continuar.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped></style>
