import { withModifiers as _withModifiers, vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3733a7fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check form-switch d-flex align-items-center" }
const _hoisted_2 = ["id", "checked"]
const _hoisted_3 = ["id", "checked", "onclick"]
const _hoisted_4 = ["id", "checked"]
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.model)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          class: _normalizeClass([`switch-${_ctx.color}`, "form-check-input me-2"]),
          type: "checkbox",
          id: _ctx.id,
          onChange: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleChange($event)), ["prevent"])),
          checked: _ctx.checked,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.$model) = $event))
        }, null, 42, _hoisted_2)), [
          [_vModelCheckbox, _ctx.model.$model]
        ])
      : (_ctx.readonly)
        ? (_openBlock(), _createElementBlock("input", {
            key: 1,
            class: _normalizeClass([`switch-${_ctx.color}`, "form-check-input me-2"]),
            type: "checkbox",
            id: _ctx.id,
            checked: _ctx.checked,
            onclick: _ctx.handleChange
          }, null, 10, _hoisted_3))
        : (_openBlock(), _createElementBlock("input", {
            key: 2,
            class: _normalizeClass([`switch-${_ctx.color}`, "form-check-input me-2"]),
            type: "checkbox",
            id: _ctx.id,
            checked: _ctx.checked,
            onChange: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.handleChange($event)), ["prevent"]))
          }, null, 42, _hoisted_4)),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 3,
          class: _normalizeClass([`text-${_ctx.color}`, "form-check-label text-subtitle"]),
          for: _ctx.id
        }, _toDisplayString(_ctx.label), 11, _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(["ms-3 spinner-border spinner-border-sm", `text-${_ctx.color}`]),
          role: "status"
        }, null, 2))
      : _createCommentVNode("", true)
  ]))
}