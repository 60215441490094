<template>
  <div class="offcanvas-body">
    <ImageCropper
      required
      :accept="'.jpeg,.png,.jpg'"
      :aspectRatio="1 / 1"
      v-on:cropEnd="onImageUploaded($event, 'document_image')"
      class="mb-4"
      modalId="document_image"
      inputId="document_image_input"
      label="Foto do seu CRP"
      :image="images.document_image.path"
      backdrop="false"
    />
    <ImageCropper
      required
      :accept="'.jpeg,.png,.jpg'"
      :aspectRatio="1 / 1"
      v-on:cropEnd="onImageUploaded($event, 'document_in_hand')"
      class="mb-4"
      modalId="document_in_hand"
      inputId="document_image_input"
      label="Foto sua segurando seu CRP"
      :image="images.document_in_hand.path"
      backdrop="false"
    />

    <!-- <div class="mb-5">
      <template v-if="currentImagetoUpload === 'document_image'">
        <ImageUploader
          label="Foto do documento"
          :image="images.document_image.path"
          :uploading="uploading"
          @onImageUploaded="onImageUploaded"
          inputId="document_image_input"
          inputKey="document_image"
        />
      </template>

      <template v-if="currentImagetoUpload === 'document_in_hand'">
        <ImageUploader
          :image="images.document_in_hand.path"
          :uploading="uploading"
          @onImageUploaded="onImageUploaded"
          inputId="document_in_hand_input"
          inputKey="document_in_hand"
        />
      </template>
    </div> 
    <div class="d-flex justify-content-around align-items-center">
      <div>
        <div
          @click="setCurrentImageToUpload('document_image')"
          class="avatar avatar-xxl cursor-pointer border border-card border-4 rounded mb-3 shadow"
        >
          <img :src="images.document_image.path" class="avatar-img rounded" />
        </div>
        <p class="form-label text-muted text-center">CRP</p>
      </div>
      <div>
        <div
          @click="setCurrentImageToUpload('document_in_hand')"
          class="avatar avatar-xxl cursor-pointer border border-4 border-card rounded mb-3 shadow"
        >
          <img :src="images.document_in_hand.path" class="avatar-img rounded" />
        </div>
        <p class="form-label text-muted text-center">Segurando o CRP</p>
      </div>
    </div>
    -->
  </div>
  <div class="offcanvas-footer">
    <Button
      fullWidth
      label="Concluir"
      @onClick="updateDocuments"
      :loading="submitting"
      :marginVertical="false"
      :disabled="!currentImagetoUpload"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CRPController from "./CRPController";

export default defineComponent({
  mixins: [CRPController],
});
</script>
