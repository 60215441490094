
import ISession from '@/interfaces/ISession'
import { IOffcanvasConfig } from '@/views/OffcanvasViews/OffcanvasViewsConfig'
import { defineStore } from "pinia"

export interface IState {
  session: ISession | null
  token: string | null
  offcanvas_config: IOffcanvasConfig | null
  chat_room_config: IChatRoomConfig | null
  offcanvas_history: IOffcanvasConfig[]
  register_history: any
  appointment_rating_pending: any
}

interface IChatRoomConfig {
  id: number
  user_name: string
  date: string
  mode: string
}

const getStateFromStorage = (): IState => {
  const localState = localStorage.getItem(process.env.VUE_APP_STORAGE_KEY as string)
  const st: IState = JSON.parse(localState || '{}')
  const state: IState = {
    session: st.session,
    token: st.token,
    offcanvas_config: st.offcanvas_config,
    chat_room_config: st.chat_room_config,
    offcanvas_history: st.offcanvas_history || [],
    register_history: st.register_history,
    appointment_rating_pending: st.appointment_rating_pending,
  }
  return state
}

export const useMainStore = defineStore('main', {
  state: getStateFromStorage,
  getters: {
    getSession: (state) => state.session,
    getToken: (state) => state.token,
    getOffcanvasConfig: (state) => state.offcanvas_config,
    getChatRoomConfig: (state) => state.chat_room_config,
  },
  actions: {
    saveToken(token: string) {
      this.$patch({ token })
      this.saveState()
    },
    saveSession(session: ISession) {
      const newSession = { ...this.$state.session, ...session }
      this.$patch({ session: newSession })
      this.saveState()
    },
    clearSession() {
      this.$patch({ session: null, token: null, chat_room_config: null, offcanvas_config: null, offcanvas_history: [] })
      this.saveState()
    },
    saveChatRoomConfig(config: IChatRoomConfig) {
      this.chat_room_config = config
      this.saveState()
    },
    clearChatRoomConfig() {
      this.chat_room_config = null
      this.saveState()
    },
    saveOffcanvasConfig(config: IOffcanvasConfig) {
      let history: IOffcanvasConfig[] = JSON.parse(JSON.stringify(this.$state.offcanvas_history))
      if (history.length == 0) {
        history = []
      }
      history.push(config)
      this.offcanvas_config = config
      this.offcanvas_history = history
      this.saveState()
    },
    clearOffcanvasConfig() {
      this.offcanvas_config = null
      this.offcanvas_history = []
      this.saveState()
    },
    popOffcanvasHistory() {
      let history: IOffcanvasConfig[] = JSON.parse(JSON.stringify(this.$state.offcanvas_history))
      const last = history.pop()
      if (last) {
        this.offcanvas_config = history[history.length - 1] || null
        this.offcanvas_history = history
        this.saveState()
      }
    },
    saveRegisterHistory(params: any) {
      this.register_history = params
      this.saveState()
    },
    clearRegisterHistory() {
      this.register_history = null
      this.saveState()
    },
    saveAppointmentRatingPending(params: any) {
      this.appointment_rating_pending = params
      this.saveState()
    },
    clearAppointmentRatingPending() {
      this.appointment_rating_pending = null
      this.saveState()
    },
    saveState() {
      localStorage.setItem(process.env.VUE_APP_STORAGE_KEY as string, JSON.stringify(this.$state))
    },
  },
})