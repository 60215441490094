<template>
  <div class="form-check form-switch d-flex align-items-center">
    <input
      v-if="model"
      :class="`switch-${color}`"
      class="form-check-input me-2"
      type="checkbox"
      :id="id"
      @change.prevent="handleChange($event)"
      :checked="checked"
      v-model="model.$model"
    />
    <input
      v-else-if="readonly"
      :class="`switch-${color}`"
      class="form-check-input me-2"
      type="checkbox"
      :id="id"
      :checked="checked"
      :onclick="handleChange"
    />
    <input
      v-else
      :class="`switch-${color}`"
      class="form-check-input me-2"
      type="checkbox"
      :id="id"
      :checked="checked"
      @change.prevent="handleChange($event)"
    />
    <label
      v-if="label"
      :class="`text-${color}`"
      :for="id"
      class="form-check-label text-subtitle"
      >{{ label }}</label
    >
    <div
      v-if="loading"
      class="ms-3 spinner-border spinner-border-sm"
      :class="`text-${color}`"
      role="status"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, readonly } from "vue";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    model: {
      required: false,
      default: (): any => {},
    },
  },
  emits: ["handleChange"],
  methods: {
    handleChange(event?: any) {
      if (this.readonly) {
        this.$emit("handleChange");
        return false;
      }
      this.$emit("handleChange", event);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/theme/scss/user-variables";

.switch-primary.form-check-input:checked {
  background-color: $primary !important;
}
.switch-success.form-check-input:checked {
  background-color: $success !important;
}
.switch-secondary.form-check-input:checked {
  background-color: $secondary !important;
}
.switch-primary.form-check-input,
.switch-primary.form-check-input:focus:not(:checked) {
  background-color: $primary25 !important;
}
.switch-success.form-check-input,
.switch-success.form-check-input:focus:not(:checked) {
  background-color: $success25 !important;
}
.switch-secondary.form-check-input,
.switch-secondary.form-check-input:focus:not(:checked) {
  background-color: $secondary25 !important;
}
</style>
