<template>
  <div class="offcanvas-body">
    <div class="text-center mb-4">
      <img
        v-if="session?.profile_image"
        :src="session?.profile_image"
        alt="profile_image"
        class="border border-5 border-card shadow"
        width="250"
        height="auto"
        style="border-radius: 20px"
      />
    </div>

    <!-- NAME -->
    <div class="d-flex align-items-center mb-3">
      <p class="text-header mb-0 me-2">
        {{ session?.name }}
      </p>
      <i
        class="fa-solid fa-plus-circle text-info fa-lg"
        v-if="session?.is_premium"
      ></i>
      <i
        @click="handleShareProfile"
        class="fa-solid fa-arrow-up-from-bracket ms-auto text-primary fa-lg cursor-pointer"
      ></i>
    </div>

    <!-- RATINGS & APPOINTMENTS COUNT -->
    <div class="d-flex align-items-center">
      <i class="fa-solid fa-star text-warning me-2"></i>
      <p class="text-paragraph mb-0 me-4">
        {{ session?.formatted_ratings_as_psi_count }}
      </p>

      <i class="fa-solid fa-user-group text-primary me-2"></i>
      <p class="text-paragraph mb-0">
        {{ session?.formatted_valid_appointments_as_psi_count }}
      </p>
    </div>

    <!-- CRP -->
    <p class="text-paragraph mb-4">
      CRP: {{ session?.crp }} | {{ session?.address_state }}
    </p>

    <!-- LANGUAGES -->
    <div class="d-flex flex-wrap mb-1">
      <div
        v-for="(language, index) in session?.formatted_languages"
        :key="index"
        class="border rounded-5 py-1 px-3 me-2 mb-3"
      >
        <p class="text-paragraph mb-0">
          {{ language }}
        </p>
      </div>
    </div>

    <!-- INTRO VIDEO -->
    <Button
      v-if="session?.intro_video_link"
      label="Assistir miha introdução"
      fullWidth
      color="primary"
      @onClick="handleIntroVideo"
    >
      <template v-slot:leftImg>
        <img
          src="@/assets/img/youtube_icon.png"
          alt="youtube_icon"
          class="me-3"
        />
      </template>
    </Button>

    <hr class="my-4" />

    <!-- BIO -->
    <div>
      <p class="text-header">Biografia</p>
      <p class="text-paragraph">
        {{ session?.bio }}
      </p>
    </div>

    <hr class="my-4" />

    <!-- SPECIALTIES -->
    <div>
      <div class="d-flex align-items-center justify-content-between">
        <p class="text-header">Especialidades</p>
        <p
          v-if="session?.occupations.length > 3"
          class="text-subtitle text-secondary cursor-pointer"
          @click="toggleModal('specialties_modal')"
        >
          Ver todas
        </p>
      </div>

      <div class="d-flex flex-wrap">
        <div
          v-for="(specialty, index) in specialties"
          :key="index"
          class="border rounded-5 py-1 px-3 me-2 mb-3"
        >
          <p class="text-paragraph mb-0">
            {{ specialty.occupation.title }}
          </p>
        </div>
      </div>
    </div>

    <hr class="my-4" />

    <!-- APPROACHES -->
    <div>
      <div class="d-flex align-items-center justify-content-between">
        <p class="text-header">Abordagens</p>
        <p
          v-if="session?.approaches.length > 3"
          class="text-subtitle text-secondary cursor-pointer"
          @click="toggleModal('approaches_modal')"
        >
          Ver todas
        </p>
      </div>

      <div class="d-flex flex-wrap">
        <div
          v-for="(approache, index) in approaches"
          :key="index"
          class="border rounded-5 py-1 px-3 me-2 mb-3"
        >
          <p class="text-paragraph mb-0">
            {{ approache.info.title }}
          </p>
        </div>
      </div>
    </div>

    <hr class="my-4" />

    <!-- EDUCATION -->
    <div>
      <p class="text-header">Formação</p>
      <p class="text-paragraph">
        {{ session?.education }}
      </p>
    </div>

    <hr class="my-4" />

    <!-- EXPERIENCE -->
    <div>
      <p class="text-header">Experiência</p>
      <p class="text-paragraph">
        {{ session?.formatted_years_exp }}
      </p>
    </div>

    <hr class="my-4" />

    <!-- RATINGS -->
    <div>
      <div class="d-flex align-items-center justify-content-between">
        <p class="text-header">{{ session?.formatted_ratings_as_psi_count }}</p>
        <p
          class="text-subtitle text-secondary cursor-pointer"
          @click="handleRatings"
          v-if="session?.formatted_ratings_as_psi_count"
        >
          Ver todas
        </p>
      </div>

      <ScrollHorizontal :items="session.formatted_latest_ratings">
        <template v-slot:default="{ item }">
          <div class="card rounded-4">
            <div class="card-body">
              <div class="d-flex align-items-center mb-4">
                <img
                  :src="item.formatted_profile_image"
                  alt=""
                  height="40"
                  width="40"
                  class="rounded-circle me-2"
                />
                <div>
                  <p class="mb-1 text-subtitle">
                    {{ item.user.name.split(" ")[0] }}
                  </p>
                  <p class="text-label mb-0">
                    {{ item.formatted_date }}
                  </p>
                </div>
              </div>
              <p class="text-paragraph text-wrap">{{ item.testimonial }}</p>
            </div>
          </div>
        </template>
      </ScrollHorizontal>
    </div>
  </div>
</template>

<script>
import MyProfileController from "./MyProfileController";
export default MyProfileController;
</script>

<style scoped>
.card {
  width: 280px;
  height: 168px;
  border-radius: 10px;
}
</style>
