import ModalComponent, { toggleModal } from "@/components/ModalComponent.vue";
import { dataURLtoFile } from "@/utils/Helper";
import { Modal } from "bootstrap";
import "cropperjs/dist/cropper.css";
import { defineComponent, ref } from "vue";
import VueCropper from "vue-cropperjs";

export default defineComponent({
  props: {
    accept: {
      type: String,
      default: "image/*,.pdf",
    },
    customFileInputWidth: {
      type: Number,
      default: 100,
    },
    aspectRatio: {
      type: Number,
      default: 1,
    },
    inputId: {
      type: String,
      default: `def-${new Date().getTime()}`,
    },
    hiddenInput: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: `${new Date().getTime()}`,
    },
    maxImgSize: {
      type: Number,
      default: 8,
    },
    customIcon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    backdrop: {
      type: String,
      default: "static",
    },
    image: {
      type: String,
      default: "",
    }
  },
  computed: {
    modal_id(): string {
      return `image-modal-${this.modalId}`;
    },
    fileName(): string {
      return this.originalFile ? `${this.originalFile.name}` : ``;
    },
    cropper_ref(): string {
      return `${this.modalId}-cropper`;
    },
    input_name(): string {
      return `${this.inputId}-name`;
    },
    button_id(): string {
      return `${this.inputId}_button`;
    }
  },
  mounted() {
    this.$eventBus.$on("clearImageCropper", () => {
      this.imgSrc = null;
      this.cropImg = this.image;
      this.fileImage = null;
    });
  },
  data() {
    return {
      imgSrc: null as string | ArrayBuffer | null,
      cropImg: this.image,
      fileImage: null as File | null,
      percentage: 0,
      uploading: false,
      counter: null as ReturnType<typeof setTimeout> | null,
      eventLabel: "Selecione a imagem",
      originalFile: null as File | null,
      imageModal: null as Modal | null,
    };
  },
  components: {
    VueCropper,
    ModalComponent,
  },
  methods: {
    cropImage() {
      const cropper = this.$refs[this.cropper_ref] as any;
      this.cropImg = cropper.getCroppedCanvas().toDataURL();
      this.fileImage = dataURLtoFile(
        cropper.getCroppedCanvas().toDataURL("image/jpeg"),
        "text.jpeg"
      );

      if (this.fileImage.size > this.maxImgSize * 1024 * 1024) {
        this.fileImage = dataURLtoFile(
          cropper.getCroppedCanvas().toDataURL("image/jpeg", 0.7),
          "text.jpeg"
        );
      }
      // if (this.imageModal) this.imageModal.toggle();
      toggleModal(this.modal_id)
      this.upload();
    },
    isImageSizeValid(value: File | null, maxSizeInMb = 8): boolean {
      if (!value) {
        return true;
      }
      return value.size / 1024 / 1024 <= maxSizeInMb;
    },
    setImage(e: Event) {
      const target = e.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        this.originalFile = target.files[0];
        if (!this.isImageSizeValid(this.originalFile, this.maxImgSize)) {
          alert(
            `Sua imagem tem ${(this.originalFile.size / 1024 / 1024).toFixed(
              2
            )}MB. Selecione uma imagem com no máximo ${this.maxImgSize}MB`
          );
          return;
        }

        toggleModal(this.modal_id)

        if (this.originalFile.type.indexOf("image/") === -1) {
          alert("Por favor selecione uma imagem");
          return;
        }
        if (typeof FileReader === "function") {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.imgSrc = event.target?.result ?? null;
            const cropper = this.$refs[this.cropper_ref] as any;
            cropper.replace(event.target?.result);
          };
          reader.readAsDataURL(this.originalFile);
        } else {
          alert("Sorry, FileReader API not supported");
        }
      }
    },
    upload() {
      this.uploading = true;
      let timer = Math.floor(Math.random() * 500) + 500;
      this.percentage = 100;
      timer = 1;
      this.counter = setTimeout(() => {
        this.setCounter();
      }, timer);
    },
    cancelUpload() {
      this.uploading = false;
      this.percentage = 0;
      this.cropImg = "";
      if (this.counter) {
        clearTimeout(this.counter);
      }
    },
    setCounter() {
      if (this.percentage < 100) {
        let max = 100;
        let p =
          Math.floor(Math.random() * (max - this.percentage + 1)) +
          this.percentage;
        this.percentage = p >= 100 ? 100 : p;
        this.upload();
      } else {
        this.uploading = false;
        this.percentage = 0;
        if (this.counter) {
          clearTimeout(this.counter);
        }
        const result = { file: this.fileImage, base64: this.cropImg };
        this.$emit("cropEnd", result);
        this.cropClosed();
      }
    },
    cropClosed() {
      const inputNameRef = this.$refs[this.input_name] as HTMLInputElement | null;
      if (inputNameRef) {
        inputNameRef.value = "";
      }
    },
  },
});
