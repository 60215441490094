<template>
  <div class="offcanvas-body">
    <MyAccount v-if="currentPath == 'minha-conta'" />
    <MyPlan v-if="currentPath == 'meu-plano'" />
    <ProfileSettings v-if="currentPath == 'gerenciar-perfil'" />

    <template v-if="currentPath == 'configurar-sessoes'">
      <div class="card">
        <div class="card-header">
          <p class="text-header mb-0">Configurar sessões</p>
        </div>
        <div class="card-body">
          <AvailabilitySettings type="screen" />
        </div>
      </div>
    </template>

    <BankAccount v-if="currentPath == 'conta-bancaria'" />
    <Notifications v-if="currentPath == 'notificacoes'" />
    <Coupons v-if="currentPath == 'valor-personalizado'" />
    <Support v-if="currentPath == 'ajuda'" />
    <AboutApp v-if="currentPath == 'sobre-o-app'" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AvailabilitySettings from "../Home/AvailabilitySettings.vue";
import AboutApp from "./AboutApp/AboutApp.vue";
import BankAccount from "./BankAccount/BankAccount.vue";
import Coupons from "./Coupons/Coupons.vue";
import MyAccount from "./MyAccount/MyAccount.vue";
import MyPlan from "./MyPlan/MyPlan.vue";
import Notifications from "./Notifications/Notifications.vue";
import ProfileSettings from "./ProfileSettings/ProfileSettings.vue";
import Support from "./Support/Support.vue";

export default defineComponent({
  mounted() {
    this.currentPath = this.$route.params.path;
  },
  watch: {
    "$route.params"(value) {
      this.currentPath = value.path;
    },
  },
  data() {
    return {
      currentPath: "gerenciar-perfil",
    };
  },
  components: {
    MyAccount,
    MyPlan,
    ProfileSettings,
    AvailabilitySettings,
    BankAccount,
    Notifications,
    Coupons,
    Support,
    AboutApp,
  },
});
</script>
