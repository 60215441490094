<template>
  <div class="offcanvas-body">
    <LoadingContainer v-if="loadingItems" />
    <template v-else>
      <div class="card">
        <div class="card-body">
          <div
            class="d-flex align-items-center justify-content-between mb-3 border-bottom"
          >
            <p class="text-paragraph">Valor total</p>
            <p class="text-subtitle">{{ item.formatted_value }}</p>
          </div>
          <div class="border-bottom mb-3">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <p class="mb-0 text-paragraph">Previsão de pagamento</p>
              <p class="mb-0 text-subtitle">
                {{ item.formatted_arrival_date }}
              </p>
            </div>
            <p class="text-paragraph text-warning">
              A data de recebimento do repasse pode variar conforme o banco.
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <p class="text-paragraph">Status</p>
            <p
              class="text-subtitle"
              :class="item.status_config?.payout_detail.color"
            >
              {{ item.status_config?.payout_detail.status }}
            </p>
          </div>
        </div>
      </div>

      <div class="card" v-if="item?.transactions">
        <div class="card-body">
          <p class="text-header">Extrato do repasse</p>
          <div
            v-for="(elem, index) in item?.transactions"
            class="mb-3"
            :class="{
              'border-bottom': index + 1 < item?.transactions.length,
              'cursor-pointer': elem.has_action,
            }"
            :key="index"
            @click="handleDetail(elem)"
          >
            <div class="d-flex align-items-center justify-content-between mb-3">
              <div>
                <p class="mb-0 text-paragraph">{{ elem.payout_type }}</p>
                <template v-if="elem.appointment">
                  <p class="mb-0 text-label text-muted">
                    {{ elem.appointment_user_name }}
                  </p>
                  <p class="mb-0 text-label text-muted">
                    {{ elem.appointment_date }}
                  </p>
                </template>
                <p class="mb-0 text-label text-muted" v-if="elem.payout_date">
                  {{ elem.payout_date }}
                </p>
              </div>
              <p class="mb-0 text-subtitle">
                {{ elem.payout_net }}
                <i class="fa-solid fa-chevron-right" v-if="elem.has_action"></i>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <p class="text-header">Banco</p>

          <div
            class="d-flex align-items-center justify-content-between mb-3 border-bottom"
          >
            <p class="text-paragraph">Banco</p>
            <p class="text-subtitle">{{ item.bank_account?.bank_name }}</p>
          </div>

          <div
            class="d-flex align-items-center justify-content-between mb-3 border-bottom"
          >
            <p class="text-paragraph">Agência</p>
            <p class="text-subtitle">{{ item.bank_account?.routing_number }}</p>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mb-3 border-bottom"
          >
            <p class="text-paragraph">Tipo de conta</p>
            <p class="text-subtitle">{{ item.bank_account?.type_label }}</p>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <p class="text-paragraph">Conta (últimos dígitos)</p>
            <p class="text-subtitle">{{ item.bank_account?.last_digits }}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import DetailMixinController from "@/mixins/DetailMixinController";
import { useMainStore } from "@/store";
import { defineComponent } from "vue";
import {
  OffcanvasViewKey,
  OffcanvasViewsConfig,
  navigateCanvas,
} from "../OffcanvasViews/OffcanvasViewsConfig";

export default defineComponent({
  mixins: [DetailMixinController],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  setup() {
    const handleDetail = (transaction: any) => {
      if (!transaction.has_action) return;
      const detail_id = transaction?.refer_to_another_payout
        ? transaction.source_payout.id
        : transaction.appointment.id;
      const title = transaction?.refer_to_another_payout
        ? OffcanvasViewsConfig.payout_detail.title
        : OffcanvasViewsConfig.appointment_detail.title;
      const path = transaction?.refer_to_another_payout
        ? OffcanvasViewsConfig.payout_detail.path
        : OffcanvasViewsConfig.appointment_detail.path;

      const offcanvas_config = {
        path,
        title,
        detail_id,
      };

      navigateCanvas(path as OffcanvasViewKey, offcanvas_config, false);
    };

    return {
      handleDetail,
    };
  },
  methods: {
    fetchPromise() {
      console.log(useMainStore().offcanvas_config);
      return this.$appointmentService.showPayout(
        useMainStore().offcanvas_config?.detail_id as number
      );
    },
    // async fetchPromise() {

    //   return await this.$appointmentService.showPayout(
    //     useMainStore().offcanvas_config?.detail_id as number
    //   );
    // },
    prepareResponse(data: any) {
      return this.$appointmentService.preparePayout(data);
    },
  },
});
</script>

<style scoped></style>
