<template>
  <div class="offcanvas-body">
    <div class="row align-items-center" v-if="tabs">
      <div class="col">
        <ul class="nav nav-tabs nav-overflow border-0">
          <li class="nav-item" v-for="(item, index) of tabs" :key="index">
            <a
              href=""
              @click.prevent="handleTabClicked(item)"
              class="nav-link text-subtitle mt-0"
              :class="{ active: item.active }"
            >
              {{ item.value }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <LoadingContainer v-if="loadingItems" />
    <template v-else>
      <div v-for="(item, index) of items" :key="index">
        <MyPatientItem
          :item="item"
          :currentTab="currentTab"
          @handlePatientClicked="handlePatientClicked"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import MyPatientsController from "./MyPatientsController";
export default MyPatientsController;
</script>

<style scoped></style>
