<template>
  <div class="card" v-if="request?.pendingByMe">
    <div class="card-body">
      <p class="text-header">Aceita reagendar esta sessão?</p>
      <p class="text-paragraph mb-0">
        Paciente solicitou um novo horário para esta sessão, você aceita alterar
        a data da sessão?
      </p>

      <div
        class="d-flex align-items-center py-4 border-bottom justify-content-between"
      >
        <p class="text-paragraph mb-0">Novo dia</p>
        <p class="text-subtitle mb-0">{{ request?.formattedDate }}</p>
      </div>
      <div class="d-flex align-items-center py-4 justify-content-between">
        <p class="text-paragraph mb-0">Novo horário</p>
        <p class="text-subtitle mb-0">{{ request?.formattedTime }}</p>
      </div>

      <div class="d-flex">
        <Button
          label="Rejeitar"
          outline
          :loading="rejectingReschedule"
          fullWidth
          class="me-2"
          @onClick="handleRescheduleRequest('rejected')"
        />
        <Button
          label="Aceitar"
          :loading="acceptingReschedule"
          fullWidth
          class="ms-2"
          @onClick="handleRescheduleRequest('acecpted')"
        />
      </div>
    </div>
  </div>
  <div v-else class="alert" :class="[request?.bgColor, request?.color]">
    <div class="d-flex align-items-center mb-3">
      <i :class="request?.icon" />
      <p class="text-subtitle mb-0 ms-2">
        {{ request?.title }}
      </p>
    </div>
    <div v-html="request?.description"></div>
  </div>
</template>

<script lang="ts">
import AppointmentService from "@/services/AppointmentService";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["request", "callback"],
  data() {
    return {
      rejectingReschedule: false,
      acceptingReschedule: false,
    };
  },
  methods: {
    async handleRescheduleRequest(status: string) {
      try {
        this.acceptingReschedule =
          status == AppointmentService.RESCHEDULE_STATUS.ACCEPTED;
        this.rejectingReschedule =
          status == AppointmentService.RESCHEDULE_STATUS.REJECTED;

        await this.$appointmentService.updateRescheduleRequest(
          this.request?.id,
          { status }
        );
        this.callback();
      } catch (error) {
        console.log(
          "ERRO NO HANDLE RESCHEDULE REQUEST NO APPOINTMENT DETAIL SCREEN",
          error
        );
      } finally {
        this.rejectingReschedule = false;
        this.acceptingReschedule = false;
      }
    },
  },
});
</script>

<style scoped></style>
