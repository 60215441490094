<template>
  <div class="offcanvas-body">
    <div class="h-100 flex-column d-flex align-items-center">
      <p class="text-paragraph text-center justify-self-start">
        Altere sua foto clicando na imagem abaixo
      </p>
      <div class="h-100 d-flex align-items-center">
        <AvatarUploader
          :avatar="avatar"
          :uploading="uploading"
          @onAvatarUploaded="onAvatarUploaded"
          :placeholder="placeholder"
        />
      </div>
      <Button
        fullWidth
        label="Concluir"
        @onClick="updateProfileImage"
        :loading="submitting"
      />
    </div>
  </div>
</template>

<script lang="ts">
import ProfileImageController from "./ProfileImageController";
export default ProfileImageController;
</script>
