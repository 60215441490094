<template>
  <div class="offcanvas-body">
    <LoadingContainer v-if="loading" />
    <CheckboxInput
      v-else
      :options="occupationAreas"
      :model="v$.form.occupation_areas"
      @onChange="handleSelect"
    />
  </div>
  <div class="offcanvas-footer">
    <FormButton
      :marginVertical="false"
      :form="v$.form"
      label="Concluir"
      :loading="submitting"
      @onClick="submit"
      :disabled="v$.invalid"
    />
  </div>
</template>

<script>
import SpecialtiesController from "./SpecialtiesController";
export default SpecialtiesController;
</script>
