import Calendar from "@/components/Calendar.vue";
import moment from "moment";
import { defineComponent } from "vue";
import { EOffcanvasViews, OffcanvasViewsConfig, goBackCanvas, navigateCanvas } from "../OffcanvasViewsConfig";

export interface ITime {
    formattedDate: string;
    online: boolean;
    date: string;
}

export default defineComponent({
    props: {
        appointmentId: {
            type: Number,
            required: true,
        },
    },
    mounted() {

        console.log(this.appointmentId)
        this.loading = true
        this.getAvailableDays()
        this.getAvailableTimes(moment().format("YYYY-MM-DD"))
        this.getAppointment()

        this.$eventBus.$on('CALENDAR_DATE_CHANGED', (params: any) => {
            this.loading = true
            this.getAvailableTimes(params.formattedDate);
        })
    },
    unmounted() {
        this.$eventBus.$off('CALENDAR_DATE_CHANGED');
    },
    data() {
        return {
            availableDays: [],
            availableTimes: [] as ITime[],
            loading: false,
            submitting: false,
            selectedDate: moment().format("YYYY-MM-DD"),
            paymentMethod: ''
        }
    },
    methods: {
        async getAppointment() {
            try {
                const res = await this.$appointmentService.show(this.appointmentId);
                this.paymentMethod = res.data.payment_method
            } catch (error) {
                console.error(error);
            }
        },
        async getAvailableDays() {
            try {
                const res = await this.$userService.availableDays();
                this.availableDays = res.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getAvailableTimes(date: string) {
            try {
                const res = await this.$userService.availableTimes(date);
                this.availableTimes = res.data
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        handleSelectDate(item: any) {
            this.selectedDate = item.date
        },
        async handleReschedule() {
            try {
                this.submitting = true;
                const params = {
                    appointment_id: this.appointmentId,
                    new_date: this.selectedDate,
                };
                await this.$appointmentService.rescheduleAppointment(params);

                this.$eventBus.$emit('showToast', {
                    title: "Reagendamento solicitado",
                    msg: "Seu paciente deverá aceitar sua solicitação de reagendamento."
                })

                goBackCanvas()
            } catch (error: any) {
                console.log(error);
                alert(error.message)
            } finally {
                this.submitting = false
            }
        }
    },
    components: {
        Calendar,
    }
})