<template>
  <div class="offcanvas-body">
    <div class="card bg-warning-soft">
      <div class="card-body">
        <div class="d-flex align-items-center mb-3">
          <i class="fa-solid fa-circle-exclamation me-2 text-warning" />
          <p class="mb-0 text-subtitle">Importante</p>
        </div>
        <p class="text-paragraph">
          Se você alterar o valor da sessão e tiver cupons de desconto,
          lembre-se de que qualquer valor de cupom inferior a 50 reais será
          ajustado automaticamente para 50 reais como mínimo.
        </p>
      </div>
    </div>
    <MoneyInput
      required
      :label="`Valor da sessão agendada`"
      :model="v$.form.appointment_price"
      :errorMsg="v$.form.appointment_price.required?.$message"
    />
    <MoneyInput
      required
      :label="`Valor da conversa inicial`"
      :model="v$.form.first_appointment_price"
      :errorMsg="v$.form.first_appointment_price.required?.$message"
    />

    <div class="d-flex align-items-center mb-3">
      <i class="fa-solid fa-circle-exclamation me-2 text-primary" />
      <p class="mb-0 text-subtitle">Lembrete:</p>
    </div>

    <ul class="text-primary text-paragraph ps-4">
      <li>
        É descontado uma taxa de 20% em cima do valor das sessões realizadas.
      </li>
      <li>Valor mínimo aceito para qualquer tipo de sessão é de R$50.</li>
    </ul>

    <FormButton
      label="Concluir"
      :disabled="v$.$invalid"
      @onClick="submit"
      :loading="submitting"
      :form="v$.form"
    />
  </div>
</template>

<script lang="ts">
import ISession from "@/interfaces/ISession";
import AppointmentService from "@/services/AppointmentService";
import { useMainStore } from "@/store";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { defineComponent } from "vue";
import { goBackCanvas } from "../OffcanvasViewsConfig";

export default defineComponent({
  computed: {
    session(): ISession | null {
      return useMainStore().session;
    },
  },
  mounted() {
    if (this.session) {
      this.form.appointment_price = this.session.appointment_price;
      this.form.first_appointment_price =
        this.session.first_appointment_price || this.session.appointment_price;
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      submitting: false,
      minimumPrice: AppointmentService.MINIMUM_PRICE,
      maxPrice: AppointmentService.MAX_PRICE,
      form: {
        appointment_price: AppointmentService.MINIMUM_PRICE,
        first_appointment_price: AppointmentService.MINIMUM_PRICE,
      },
    };
  },
  validations() {
    return {
      form: {
        appointment_price: {
          required: helpers.withMessage(
            `Informe um valor entre R$${this.minimumPrice},00 e R$${this.maxPrice},00`,
            required
          ),
          minMax: helpers.withMessage(
            `Informe um valor entre R$${this.minimumPrice},00 e R$${this.maxPrice},00`,
            (value: string) => {
              let v = parseFloat(
                value.replace("R$", "").replace(".", "").replace(",", ".")
              );
              return v >= this.minimumPrice && v <= this.maxPrice;
            }
          ),
        },
        first_appointment_price: {
          required: helpers.withMessage(
            `Informe um valor entre R$${this.minimumPrice},00 e R$${this.maxPrice},00`,
            required
          ),
          minMax: helpers.withMessage(
            `Informe um valor entre R$${this.minimumPrice},00 e R$${this.maxPrice},00`,
            (value: string) => {
              let v = parseFloat(
                value.replace("R$", "").replace(".", "").replace(",", ".")
              );
              return v >= this.minimumPrice && v <= this.maxPrice;
            }
          ),
        },
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.submitting = true;
        await this.$userService.update(this.form);
        this.closeOffcanvas();
      } catch (error) {
        console.log(error);
      } finally {
        this.submitting = false;
      }
    },
    closeOffcanvas() {
      goBackCanvas();
    },
  },
});
</script>

<style scoped></style>
