
import Button from '@/components/Button.vue'
import ConfirmationAlert from '@/components/ConfirmationAlert/ConfirmationAlert.vue'
import Container from '@/components/Container.vue'
import EmptyState from '@/components/EmptyState.vue'
import EditorInput from '@/components/FormComponents/EditorInput.vue'
import FormButton from '@/components/FormComponents/FormButton.vue'
import PasswordInput from '@/components/FormComponents/PasswordInput.vue'
import SelectInput from '@/components/FormComponents/SelectInput.vue'
import TextAreaInput from '@/components/FormComponents/TextAreaInput.vue'
import TextInput from '@/components/FormComponents/TextInput.vue'
import Header from '@/components/Header.vue'
import HomeCard from '@/components/HomeCard.vue'
import LoadingContainer from '@/components/LoadingContainer.vue'
import ModalComponent from '@/components/ModalComponent.vue'
import Navbar from '@/components/Navbar.vue'
import SearchBar from '@/components/SearchBar.vue'
import Sidebar from '@/components/Sidebar.vue'
import TableHead from '@/components/TableHead.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import '@popperjs/core'
import 'bootstrap'
import 'jquery'
import "moment/locale/pt-br"
import { createPinia } from 'pinia'
import money from 'v-money3'
import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/styles.scss'
import './assets/theme/scss/theme.scss'
import FileUploader from './components/FileUploader/FileUploader.vue'
import CheckboxInput from './components/FormComponents/CheckboxInput.vue'
import MoneyInput from './components/FormComponents/MoneyInput.vue'
import RadioInput from './components/FormComponents/RadioInput.vue'
import SwitchInput from './components/FormComponents/SwitchInput.vue'
import YesOrNoInput from './components/FormComponents/YesOrNoInput.vue'
import ImageCropper from './components/ImageCropper/ImageCropper.vue'
import OffcanvasComponent from './components/OffcanvasComponent.vue'
import ScrollHorizontal from './components/ScrollHorizontal.vue'
import router from './router'
import paths from './router/paths'
import AppointmentService from './services/AppointmentService'
import ChatService from './services/ChatService'
import CouponsService from './services/CouponsService'
import NotificationsService from './services/NotificationsService'
import PaymentService from './services/PaymentService'
import SessionService from './services/SessionService'
import SubscriptionService from './services/SubscriptionService'
import UserService from './services/UserService'
import WebSocketService from './services/WebSocketService'
import { EventBus } from './utils/EventBus'
import './utils/Prototypes/prototypeFunctions'
import pkg from './../package.json'
import AvatarUploader from './components/AvatarUploader/AvatarUploader.vue'
import YoutubeInput from './components/YoutubeInput/YoutubeInput.vue'
import PatientsService from './services/PatientsService'
import ImageUploader from './components/ImageUploader/ImageUploader.vue'
import VotingStars from './components/VotingStars.vue'

const app = createApp(App)

app.config.globalProperties.$envString = `${pkg.version} ${process.env.VUE_APP_ENV_NAME}`
app.config.globalProperties.$eventBus = EventBus
app.config.globalProperties.$paths = paths
app.config.globalProperties.$router = router
app.config.globalProperties.$userService = new UserService()
app.config.globalProperties.$appointmentService = new AppointmentService()
app.config.globalProperties.$webSocketService = new WebSocketService();
app.config.globalProperties.$subscriptionService = new SubscriptionService();
app.config.globalProperties.$paymentService = new PaymentService();
app.config.globalProperties.$sessionService = new SessionService();
app.config.globalProperties.$chatService = new ChatService();
app.config.globalProperties.$notificationsService = new NotificationsService();
app.config.globalProperties.$couponsService = new CouponsService();
app.config.globalProperties.$patientsService = new PatientsService();

app.component('Sidebar', Sidebar)
app.component('Navbar', Navbar)
app.component('LoadingContainer', LoadingContainer)
app.component('EmptyState', EmptyState)
app.component('Container', Container)
app.component('SearchBar', SearchBar)
app.component('TextInput', TextInput)
app.component('SelectInput', SelectInput)
app.component('TextAreaInput', TextAreaInput)
app.component('PasswordInput', PasswordInput)
app.component('EditorInput', EditorInput)
app.component('FormButton', FormButton)
app.component('TableHead', TableHead)
app.component('ModalComponent', ModalComponent)
app.component('Header', Header)
app.component('ConfirmationAlert', ConfirmationAlert)
app.component('HomeCard', HomeCard)
app.component('ImageCropper', ImageCropper)
app.component('RadioInput', RadioInput)
app.component('YesOrNoInput', YesOrNoInput)
app.component('MoneyInput', MoneyInput)
app.component('CheckboxInput', CheckboxInput)
app.component('FileUploader', FileUploader)
app.component('Button', Button)
app.component('ScrollHorizontal', ScrollHorizontal)
app.component('OffcanvasComponent', OffcanvasComponent)
app.component('SwitchInput', SwitchInput)
app.component('AvatarUploader', AvatarUploader)
app.component('YoutubeInput', YoutubeInput)
app.component('ImageUploader', ImageUploader)
app.component('VotingStars', VotingStars)
app
    .use(createPinia())
    .use(money)
    .use(router)
    .mount('#app')
