
import NetworkService, { Endpoint } from "./NetworkService";

export default class NotificationsService {
    private network: NetworkService;
    constructor() {
        this.network = new NetworkService()
    }

    list = (params: any) => {
        return this.network.get(`${Endpoint.notifications}`)
    }

    settings = (key: string, params: any) => {
        return this.network.put(`${Endpoint.notificationSettings}/${key}`, params)
    }
}