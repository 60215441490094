import ListControllerMixin from "@/mixins/ListControllerMixin";
import { EOffcanvasViews, OffcanvasViewsConfig, navigateCanvas } from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import { defineComponent } from "vue";

interface ICoupon {
    id: number;
    code: string;
    percent_off: number;
    percent_off_label: string;
    max_redemptions_customers: number;
    redeem_by: string;
    redeem_by_label: string;
    is_expired: boolean;
    max_redemptions_customers_label: string
}

export default defineComponent({
    mounted() {
        this.$eventBus.$on('UPDATE_COUPONS', () => this.getData())
    },
    mixins: [ListControllerMixin],
    computed: {
        toggle() {
            return window.innerWidth > 964;
        }
    },
    data() {
        return {
            copied: false,
            currentItem: 0,
            items: [] as ICoupon[]
        }
    },
    methods: {
        fetchPromise() {
            return this.$couponsService.list({});
        },
        handleAddCoupon() {
            navigateCanvas(EOffcanvasViews.add_coupon, null, this.toggle)
        },
        handleDeleteCoupon(item: ICoupon) {
            try {
                this.$eventBus.$emit('showConfirmationAlert', {
                    title: 'Remover código',
                    message: `Tem certeza que deseja remover este código?`,
                    confirmationButtonLabel: 'Sim, remover',
                    cancellationButtonLabel: 'Não, voltar',
                    buttonColor: 'del',
                    confirmCallback: async () => {
                        await this.$couponsService.remove(item?.id);
                        this.getData()
                    }
                })
            } catch (error) {
                console.log("ERRO NO DELETE COUPON DO COUPONS HOOKS", error);
            }
        },
        handleCopyCoupon(item: ICoupon) {
            navigator.clipboard.writeText(item.code);
            this.currentItem = item.id
            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, 2000);
        }
    }
})