import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "offcanvas-body" }
const _hoisted_2 = {
  key: 0,
  class: "offcanvas-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingContainer = _resolveComponent("LoadingContainer")!
  const _component_Profile = _resolveComponent("Profile")!
  const _component_BoletoPayment = _resolveComponent("BoletoPayment")!
  const _component_PaymentError = _resolveComponent("PaymentError")!
  const _component_RescheduleRequest = _resolveComponent("RescheduleRequest")!
  const _component_Info = _resolveComponent("Info")!
  const _component_Rating = _resolveComponent("Rating")!
  const _component_Recurrency = _resolveComponent("Recurrency")!
  const _component_Values = _resolveComponent("Values")!
  const _component_Receipt = _resolveComponent("Receipt")!
  const _component_CallLink = _resolveComponent("CallLink")!
  const _component_Recommendations = _resolveComponent("Recommendations")!
  const _component_Cancellation = _resolveComponent("Cancellation")!
  const _component_Actions = _resolveComponent("Actions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loadingItems)
        ? (_openBlock(), _createBlock(_component_LoadingContainer, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_Profile, {
              user: _ctx.item.user,
              chat: _ctx.item.chat
            }, null, 8, ["user", "chat"]),
            (_ctx.item?.is_waiting_boleto_payment)
              ? (_openBlock(), _createBlock(_component_BoletoPayment, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.item?.payment_error)
              ? (_openBlock(), _createBlock(_component_PaymentError, { key: 1 }))
              : _createCommentVNode("", true),
            (_ctx.item?.rescheduleRequest)
              ? (_openBlock(), _createBlock(_component_RescheduleRequest, {
                  key: 2,
                  request: _ctx.item?.rescheduleRequest,
                  callback: _ctx.getData
                }, null, 8, ["request", "callback"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Info, { item: _ctx.item }, null, 8, ["item"]),
            (_ctx.item.my_rating)
              ? (_openBlock(), _createBlock(_component_Rating, {
                  key: 3,
                  rating: _ctx.item.my_rating
                }, null, 8, ["rating"]))
              : _createCommentVNode("", true),
            (_ctx.item?.recurrency)
              ? (_openBlock(), _createBlock(_component_Recurrency, {
                  key: 4,
                  recurrency: _ctx.item?.recurrency
                }, null, 8, ["recurrency"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Values, { item: _ctx.item }, null, 8, ["item"]),
            (_ctx.item?.status === 'concluido')
              ? (_openBlock(), _createBlock(_component_Receipt, {
                  key: 5,
                  item: _ctx.item,
                  callback: _ctx.getData
                }, null, 8, ["item", "callback"]))
              : _createCommentVNode("", true),
            (_ctx.item?.call_link)
              ? (_openBlock(), _createBlock(_component_CallLink, {
                  key: 6,
                  callLink: _ctx.item.call_link
                }, null, 8, ["callLink"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Recommendations),
            _createVNode(_component_Cancellation, {
              item: _ctx.item,
              callback: _ctx.getData
            }, null, 8, ["item", "callback"])
          ], 64))
    ]),
    (
      !_ctx.loadingItems &&
      _ctx.item?.status !== _ctx.apptStatus.CANCELADO &&
      _ctx.item?.status !== _ctx.apptStatus.CONCLUIDO &&
      _ctx.item?.status !== _ctx.apptStatus.RECUSADO
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Actions, {
            item: _ctx.item,
            callback: _ctx.getData
          }, null, 8, ["item", "callback"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}