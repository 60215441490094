import DetailMixinController from "@/mixins/DetailMixinController";
import { useMainStore } from "@/store";
import { defineComponent } from "vue";
import Actions from "./Actions.vue";
import CallLink from "./CallLink.vue";
import Cancellation from "./Cancellation.vue";
import Info from "./Info.vue";
import PaymentError from "./PaymentError.vue";
import Profile from "./Profile.vue";
import Receipt from "./Receipt.vue";
import Recommendations from "./Recommendations.vue";
import Recurrency from "./Recurrency.vue";
import RescheduleRequest from "./RescheduleRequest.vue";
import Values from "./Values.vue";
import AppointmentService from "@/services/AppointmentService";
import BoletoPayment from "./BoletoPayment.vue";
import Rating from "./Rating.vue";

export default defineComponent({
    mixins: [DetailMixinController],
    mounted() {
        this.loadingItems = true;
        this.getData();
    },
    data() {
        return {
            apptStatus: AppointmentService.STATUS
        }
    },
    methods: {
        fetchPromise() {
            return this.$appointmentService.show(useMainStore().offcanvas_config?.detail_id);
        },
        prepareResponse(data: any) {
            return this.$appointmentService.prepareAppointment(data);
        },
    },
    components: {
        Profile,
        Info,
        PaymentError,
        Values,
        Receipt,
        Recommendations,
        Cancellation,
        CallLink,
        Recurrency,
        Actions,
        RescheduleRequest,
        BoletoPayment,
        Rating
    }
});