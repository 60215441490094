<template>
  <div class="card">
    <div class="card-header">
      <span class="text-header"> Valor da sessão </span>
    </div>
    <div class="card-body">
      <p class="text-paragraph">
        Até 30 dias após as sessões serem realizadas é feito o repasse
        automático para a conta bancária.
      </p>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          <span class="text-paragraph">Valor total</span>
          <div>
            <span
              v-if="item?.coupon && item?.original_value"
              class="text-paragraph text-decoration-line-through me-2"
              >{{ item?.original_value.toCurrency() }}
            </span>

            <span v-if="item?.partnership" class="text-paragraph">{{
              item?.original_value.toCurrency()
            }}</span>

            <span
              v-else-if="item?.value"
              :class="{
                'text-paragraph': item?.status === 'concluido' && item?.payout,
                'text-subtitle': !item?.payout || item?.status !== 'concluido',
              }"
              >{{ item?.value.toCurrency() }}</span
            >
          </div>
        </li>

        <li
          v-if="item?.coupon"
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          <span class="text-paragraph">Código aplicado</span>
          <span class="text-subtitle">{{
            `${item?.coupon?.code} (${item?.coupon?.percent_off}%)`
          }}</span>
        </li>

        <template v-if="item?.partnership">
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="text-paragraph">Benefício empresa</span>
            <span
              class="text-paragraph"
              :class="{
                'text-danger': !item?.payout || item?.status !== 'concluido',
              }"
              >{{
                `-${(item?.original_value - item?.value).toCurrency()}`
              }}</span
            >
          </li>

          <li
            v-if="!item?.payout || item?.status !== 'concluido'"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="text-paragraph">Valor a receber</span>
            <span class="text-subtitle">{{ item?.value.toCurrency() }}</span>
          </li>
        </template>

        <template
          v-if="
            item?.payout &&
            item?.payout?.status !== 'pending' &&
            item?.status === 'concluido'
          "
        >
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="text-paragraph">Taxa Psiapp (20%)</span>
            <span class="text-paragraph"
              >-{{ item?.payout?.fee.toCurrency() }}</span
            >
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="text-paragraph">Valor a receber</span>
            <span class="text-subtitle">{{
              item?.payout?.net.toCurrency()
            }}</span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="text-paragraph">Repasse</span>
            <span
              class="text-subtitle"
              :class="item?.payout?.status_config['appointment_detail'].color"
              >{{
                item?.payout?.status_config["appointment_detail"].status
              }}</span
            >
          </li>
          <li
            v-if="item?.payout?.main_payout_info?.arrival_date"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="text-paragraph">Previsão de repasse</span>
            <span class="text-subtitle">{{
              item?.payout?.formatted_arrival_date
            }}</span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <div
              class="rounded-4 border p-4 d-flex align-items-center cursor-pointer w-100"
              @click="handlePayout(item)"
            >
              <p class="text-subtitle mb-0">
                <i class="fa-solid fa-sack-dollar fa-lg me-1"></i>
                Visualizar repasse
              </p>
              <i class="fa-solid fa-chevron-right text-subtitle ms-auto"></i>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { useMainStore } from "@/store";
import {
  EOffcanvasViews,
  OffcanvasViewsConfig,
  navigateCanvas,
} from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import { mapState } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    ...mapState(useMainStore, ["offcanvas_config"]),
  },
  methods: {
    handlePayout(item: any) {
      const offcanvas_config = {
        ...OffcanvasViewsConfig.payout_detail,
        detail_id: item.payout.payout_id,
      };
      navigateCanvas(EOffcanvasViews.payout_detail, offcanvas_config, false);
    },
  },
});
</script>

<style scoped></style>
