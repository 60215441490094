import { toggleModal } from "@/components/ModalComponent.vue";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        this.loading = true
        this.getItems()
    },
    data() {
        return {
            loading: false,
            submitting: false,
            ratings: [] as any[],
            v$: useVuelidate(),
            form: {
                reason_report: ''
            },
            currentRating: null as any
        }
    },
    validations() {
        return {
            form: {
                reason_report: {
                    required: helpers.withMessage('Informe por qual motivo deseja reportar', required)
                }
            }
        }
    },
    methods: {
        async getItems() {
            try {
                const res = await this.$appointmentService.callRatings()
                this.ratings = res.data
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        },
        handleReport(rating: any) {
            this.currentRating = rating
            toggleModal('report_rating_modal')
        },
        async handleConfirmReport() {
            try {
                this.submitting = true

                const params = {
                    appointment_id: this.currentRating?.appointment?.id,
                    called_rating_id: this.currentRating?.id,
                    reason_report: this.form.reason_report,
                };

                await this.$appointmentService.reviewCallRatings(params);

                this.$eventBus.$emit('showToast', {
                    title: "Avaliação em análise",
                    msg: "Nosso time irá analisar o conteúdo da sua avaliação.",

                })

                await this.getItems();

                this.resetForm()
            } catch (error) {
                console.log("ERRO NO HANDLE CONFIRM REMOVE DA RATINGS SCREEN", error);
            } finally {
                this.submitting = false
            }
        },
        resetForm() {
            this.v$.form.$reset()
            this.form.reason_report = ''
            toggleModal('report_rating_modal')
        }
    }
})