<template>
  <div class="overflow-x-scroll">
    <ul class="w-space-nowrap ps-0 mb-0">
      <li
        class="d-inline-block me-4"
        v-for="(item, index) in items"
        :key="index"
      >
        <slot :item="item"></slot>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
