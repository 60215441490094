<template>
  <div class="offcanvas-body">
    <div class="card">
      <div class="card-header">
        <p class="text-subtitle mb-0">
          Quais são seus horários de atendimento?
        </p>
      </div>
      <div class="card-body">
        <p class="text-label text-muted">Sessões duram 50 minutos</p>

        <div
          v-for="(weekDay, weekDayIndex) in form.workingHours"
          :key="weekDayIndex"
          class="mb-3 pb-3"
          :class="{
            'border-bottom': weekDayIndex + 1 != form.workingHours.length,
          }"
        >
          <div class="row align-items-center d-flex">
            <div class="col">
              <span
                @click="handleChangeAvailability(weekDayIndex)"
                class="cursor-pointer"
              >
                <span
                  v-if="weekDay.isAvailable"
                  class="fa-solid fa-check-square text-secondary"
                />
                <span v-else class="far fa-square text-muted" />
                <span class="text-subtitle text-primary ms-2">{{
                  weekDay.title
                }}</span>
              </span>
            </div>
            <div class="col-auto d-flex">
              <p
                class="cursor-pointer text-primary mb-0 me-3"
                v-if="weekDay.isAvailable"
                @click="handleCopyTimesClicked(weekDayIndex)"
              >
                Copiar
                <span class="fa-regular fa-copy" />
              </p>
              <p
                class="text-primary cursor-pointer mb-0"
                @click="addTime(weekDayIndex)"
              >
                Adicionar
                <span class="fa-solid fa-plus-circle" />
              </p>
            </div>
          </div>

          <div v-for="(hour, hourIndex) in weekDay.hours" :key="hourIndex">
            <div class="row my-3">
              <div class="col">
                <label class="form-label text-muted">Início</label>
                <input
                  class="form-control"
                  v-model="hour.start_time"
                  type="time"
                  placeholder="Inicio"
                />
                <!-- :class="{
                    'is-invalid':
                      v$.form.workingHours.$each.$response.$errors[weekDayIndex]
                        .hours[0]?.$response.$errors[hourIndex]?.start_time
                        .length,
                  }"
                <div class="invalid-feedback">
                  <small>{{
                    v$.form.workingHours.$each.$response.$errors[weekDayIndex]
                      .hours[0]?.$response.$errors[hourIndex]?.start_time[0]
                      ?.$message
                  }}</small>
                </div> -->
              </div>
              <div class="col">
                <label class="form-label text-muted">Final</label>
                <input
                  class="form-control"
                  v-model="hour.end_time"
                  type="time"
                  placeholder="Final"
                />
                <!-- :class="{
                    'is-invalid':
                      v$.form.workingHours.$each.$response.$errors[weekDayIndex]
                        .hours[0]?.$response.$errors[hourIndex]?.end_time.length,
                  }"
                <div class="invalid-feedback">
                  <small>{{
                    v$.form.workingHours.$each.$response.$errors[weekDayIndex]
                      .hours[0]?.$response.$errors[hourIndex]?.end_time[0]
                      ?.$message
                  }}</small>
                </div> -->
              </div>

              <div class="col-auto">
                <span
                  @click="removeTime(weekDayIndex, hourIndex)"
                  class="fa-solid fa-trash-alt text-secondary p-2 mt-5 cursor-pointer"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-danger text-center">
      <small>{{ errorMessage }}</small>
    </div>

    <div v-if="comingFrom == 'register'" class="row align-items-center">
      <div class="col-12 col-md-4">
        <button @click="stepChanged(false)" class="btn btn-light text-subtitle">
          Voltar
        </button>
      </div>
      <div class="col-12 col-md-4 text-center">
        <p class="text-label mb-0 text-muted">{{ step }} / {{ totalSteps }}</p>
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-end">
        <FormButton
          label="Concluir"
          :disabled="v$.$invalid"
          @onClick="stepChanged(true)"
          :fullWidth="false"
          :form="v$.form"
          :loading="submitting"
          id="register_submit_5"
        />
      </div>
    </div>

    <FormButton
      v-if="comingFrom == 'offcanvas'"
      label="Concluir"
      :disabled="v$.$invalid"
      @onClick="submit"
      :form="v$.form"
      :loading="isSubmitting"
    />

    <ModalComponent
      :title="copyModalTitle"
      :id="`schedule_copy_times_${comingFrom}`"
    >
      <template v-slot:body>
        <div>
          <span>Copiar horários para</span>
          <div
            v-for="(item, index) in form.workingHours"
            :key="'option_' + index"
            class="col-12 mt-3 mb-3"
          >
            <div v-if="item.weekday != timesToCopy?.weekday">
              <input
                type="checkbox"
                style="display: none"
                :id="item.weekday"
                :value="item.weekday"
                v-model="copyDaysChecked"
              />
              <label :for="item.weekday" class="cursor-pointer">
                <span
                  :for="item.weekday"
                  v-if="copyDaysChecked.includes(item.weekday)"
                  class="fa-solid fa-square-check fa-medium-size mb-0 text-primary me-2"
                ></span>
                <span
                  :for="item.weekday"
                  v-else
                  class="far fa-square fa-medium-size mb-0 text-muted me-2"
                ></span>
                {{ item.title }}
              </label>
            </div>
            <div v-else class="opacity-25">
              <label
                :for="item.weekday"
                class="cursor-pointer text-decoration-line-through"
              >
                <span
                  :for="item.weekday"
                  class="fa-solid fa-square-check fa-medium-size mb-0 text-primary me-2"
                ></span>
                {{ item.title }}
              </label>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          @click="handleCancelCopy"
          class="btn btn-white w-15"
        >
          Cancelar
        </button>
        <button
          type="button"
          @click="handleCopy"
          class="btn btn-secondary w-15"
        >
          Aplicar
        </button>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import ScheduleInfoController from "./ScheduleInfoController";
export default ScheduleInfoController;
</script>
