<template>
  <div>
    <div class="card">
      <div class="card-header">
        <p class="text-header mb-0">Meu plano</p>
      </div>
      <LoadingContainer v-if="loading" />
      <div class="card-body" v-else>
        <p class="text-paragraph">
          Aqui você irá encontrar as informações referentes a assinatura do
          Psiapp+.
        </p>

        <ul class="list-group list-group-flush mb-4">
          <li
            v-for="(item, index) in items"
            :key="index"
            class="list-group-item px-0"
          >
            <div
              class="d-flex justify-content-between"
              :class="{ 'mb-2': item.label }"
            >
              <span class="text-paragraph">
                {{ item.key }}
              </span>
              <span class="text-subtitle">{{ item.value }}</span>
            </div>
            <template v-if="item.subvalue">
              <p class="text-subtitle text-danger mb-2">
                {{ item?.subvalue?.label }}
              </p>
              <p class="text-paragraph text-danger mb-2">
                {{ item?.subvalue?.sublabel }}
              </p>
            </template>
          </li>
        </ul>

        <template v-if="mySub?.actionsEnabled">
          <template v-if="mySub?.isActive">
            <Button
              @onClick="handleCreditCards"
              fullWidth
              outline
              label="Alterar cartão de crédito"
            />

            <Button
              v-if="mySub?.manualPayEnabled"
              @onClick="handleManualPay"
              :loading="manualPaying"
              fullWidth
              label="Tentar realizar pagamento novamente"
            />

            <Button
              v-if="mySub?.hasCanceled"
              @onClick="handleReactivate"
              :loading="reactivating"
              fullWidth
              label="Reativar assinatura"
            />
          </template>
          <a
            v-else
            href=""
            @click.prevent="handlePsiappPlus"
            class="text-secondary text-subtitle"
          >
            Quero saber mais
          </a>
        </template>
      </div>
    </div>

    <div
      class="card"
      v-if="mySub?.actionsEnabled && mySub?.isActive && !mySub?.hasCanceled"
    >
      <div class="card-body">
        <p class="text-header">Precisa cancelar a assinatura?</p>
        <p class="text-paragraph">
          Caso você não queira mais ser um membro do Psiapp Mais e deseja perder
          todos os benefícios, você pode cancelar sua assinatura clicando
          abaixo:
        </p>

        <a
          href=""
          @click.prevent="handleToggleModal"
          class="text-danger text-subtitle"
        >
          Cancelar assinatura
        </a>
      </div>
    </div>

    <ModalComponent id="cancel_subs" title="Cancelar assinatura">
      <template v-slot:body>
        <p class="text-paragraph">
          Tem certeza que deseja cancelar sua assinatura?
        </p>
      </template>
      <template v-slot:footer>
        <button class="btn btn-del" @click="handleCancel">Sim, cancelar</button>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import MyPlanController from "./MyPlanController.ts";
export default MyPlanController;
</script>
