<template>
  <div class="offcanvas-body">
    <p class="text-paragraph">
      Compartilhe qualquer horário específico com seu paciente para agendar uma
      sessão na sua agenda.
    </p>

    <TextInput
      required
      label="Data da sessão"
      :model="v$.form.date"
      type="date"
      placeholder="01/01/2000"
    />
    <TextInput
      required
      label="Horário de inicio da sessão"
      :model="v$.form.time"
      type="time"
      placeholder="08:00"
    />
    <FormButton
      label="Compartilhar"
      :disabled="v$.$invalid"
      @onClick="submit"
      :form="v$.form"
      :loading="submitting"
    />

    <div class="card mt-4" v-if="link">
      <div class="card-header">
        <p class="text-header mb-0">Link gerado</p>
        <button
          class="btn btn-sm"
          @click="copyLink"
          :class="{
            'btn-outline-primary': !linkCopied,
            'btn-success': linkCopied,
          }"
          :disabled="linkCopied"
        >
          <span v-if="linkCopied">
            <i class="fa-solid fa-check-circle"></i> Copiado
          </span>
          <span v-else> <i class="fas fa-copy"></i> Copiar </span>
        </button>
      </div>
      <div class="card-body">
        <p class="text-paragraph mb-0">
          {{ link }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ShareScheduleController from "./ShareScheduleController";
export default ShareScheduleController;
</script>
