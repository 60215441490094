import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-paragraph mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    id: "specialties_modal",
    title: "Especialidades"
  }, {
    body: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specialties, (specialty, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["py-4", {
          'border-bottom': index < _ctx.specialties.length - 1,
          'border-0': index === _ctx.specialties.length - 1,
          'pt-0': index === 0,
        }])
        }, [
          _createElementVNode("p", _hoisted_1, _toDisplayString(specialty.occupation.title), 1)
        ], 2))
      }), 128))
    ]),
    _: 1
  }))
}