import { useMainStore } from "@/store";
import { defineComponent } from "vue";
import { EOffcanvasViews, OffcanvasViewsConfig, navigateCanvas } from "../OffcanvasViewsConfig";

export default defineComponent({
    mounted() {
        this.getRecurrency()
    },
    data() {
        return {
            loading: true,
            submitting: false,
            recurrencyId: useMainStore().offcanvas_config?.detail_id as number,
            recurrency: {} as any
        }
    },
    methods: {
        async getRecurrency() {
            try {
                const res = await this.$appointmentService.recurrencies(useMainStore().offcanvas_config?.detail_id as number);
                this.recurrency = res.data
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        handleAppointment(id: number) {
            navigateCanvas(EOffcanvasViews.appointment_detail, { ...OffcanvasViewsConfig.appointment_detail, detail_id: id }, false)
        },
        async handleCancelConfirmed() {
            this.$eventBus.$emit("showConfirmationAlert", {
                title: 'Cancelar recorrência',
                message: 'Tem certeza que deseja cancelar esta recorrência?',
                confirmationButtonLabel: 'Sim, cancelar esta recorrência',
                cancellationButtonLabel: 'Não, voltar',
                buttonColor: 'del',
                confirmCallback: async () => {
                    try {
                        this.submitting = true
                        await this.$appointmentService.cancelRecurrency(this.recurrencyId);
                        this.getRecurrency()
                    } catch (error) {
                        console.log(error);
                    } finally {
                        this.submitting = false
                    }
                },
            });
        }
    }
})