import moment from "moment";
import NetworkService, { Endpoint } from "./NetworkService";

export default class CouponsService extends NetworkService {
    constructor() {
        super()
    }
    create = (params: any) => {
        let values: any = {
            name: "Código Psiapp Pro",
            code: params.code,
            percent_off: parseInt(params.percent_off.replace("%", "")),
        };

        if (params?.redeem_by) {
            let rb = params.redeem_by.split("/");
            let redeemBy = `${rb[2]}-${rb[1]}-${rb[0]}`;
            values.redeem_by = redeemBy;
        }

        if (params.max_redemptions_customers) {
            values.max_redemptions_customers = params?.max_redemptions_customers
                ? parseInt(params?.max_redemptions_customers.replace("x", ""))
                : null;
        }

        return this.post(Endpoint.coupons, values);
    };
    list = async (params: any) => {
        const res = await this.get(Endpoint.coupons, params);
        res.data = res.data.map((coupon: any) => this.prepareCoupon(coupon))
        return res;
    };
    show = (id: number) => {
        return this.get(`${Endpoint.coupons}/${id}`);
    };
    remove = (id: number) => {
        return this.delete(`${Endpoint.coupons}/${id}`);
    };
    update = (id: number, params: any) => {
        return this.put(`${Endpoint.coupons}/${id}`, params);
    };
    prepareCoupon = (coupon: any) => {
        coupon.percent_off_label = `Código de ${coupon.percent_off}%`
        coupon.is_expired = coupon?.redeem_by
            ? moment(coupon?.redeem_by).isBefore()
            : false;
        coupon.redeem_by_label = `Válido até ${moment(coupon?.redeem_by).format(
            "DD/MM/YYYY"
        )}`
        coupon.max_redemptions_customers_label = `${coupon.max_redemptions_customers}x por paciente`

        return coupon
    };

}
