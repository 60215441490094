import moment from "moment";
import NetworkService, { Endpoint } from "./NetworkService";
import UserService from "./UserService";
import { useMainStore } from "@/store";

export default class ChatService extends NetworkService {
    constructor() {
        super()
    }

    static defaultMessage = "Esta conversa foi criada automaticamente com paciente após a confirmação do agendamento.";

    async list(params: any) {
        let res = await this.get(Endpoint.chats, params);
        res.data = res.data
            .map((r: any) => {
                const d = r.latest_message ? r.latest_message.created_at : r.created_at;
                r.formattedDate = this.getFormattedDate(
                    r.latest_message ? r.latest_message.created_at : r.created_at
                );
                r.formattedLatestMessage = r.latest_message
                    ? r.latest_message.message
                    : ChatService.defaultMessage;

                r.shortLatestMessage = r.formattedLatestMessage.length > 45 ? `${r.formattedLatestMessage.substring(0, 45)}...` : r.formattedLatestMessage;

                r.lastMessageDateTimestamp = moment(d).valueOf();
                r.profileImg = require('@/assets/img/avatars/1.png')
                if (r.user?.profile_image) {
                    r.profileImg = new UserService().getAvatar(r?.user?.profile_image)
                }
                return r;
            })
            .sort((a: any, b: any) => {
                if (a.lastMessageDateTimestamp > b.lastMessageDateTimestamp) {
                    return -1;
                }
                if (a.lastMessageDateTimestamp < b.lastMessageDateTimestamp) {
                    return 1;
                }
                return 0;
            });
        return res;
    }
    show(id: string | number) {
        return this.get(`${Endpoint.chats}/${id}`);
    }
    sendMessage(params: any) {
        return this.post(Endpoint.chatMessages, params);
    }
    async getMessages(id: number, created_at: string, sectionItems: Array<any>) {
        const store = useMainStore()
        const res = await this.get(`${Endpoint.chatMessages}`, {
            appointment_chat_id: id,
        });

        let sections: any = [];

        // if (sectionItems.length == 0) {
        res.data.push({
            isDefault: true,
            created_at
        });
        // }

        res.data.map((elem: any, index: any, arr: any) => {
            const currDate = moment(elem.created_at).format("YYYY-MM-DD");
            const nextDate = arr[index + 1]
                ? moment(arr[index + 1].created_at).format("YYYY-MM-DD")
                : null;
            const title = this.getFormattedDate(currDate, true);

            let item = {
                id: elem.created_at,
                isHeader: true,
                title,
            };

            elem.image =
                elem?.user_id == store.session?.id
                    ? `${process.env.VUE_APP_IMG_BASE_URL}${elem?.user?.profile_image}`
                    : new UserService().getAvatar(elem?.user?.profile_image);
            elem.name = elem.user?.name;
            elem.time = moment(elem.created_at).format("HH:mm")

            sections.push({ ...elem, title });

            if (currDate !== nextDate && !elem.isDefault) {
                sections.push(item);
            }
        });
        return sections.reverse()
    }
    getFormattedDate(date: string, showTodayAsString = false) {
        if (moment(date).isSame(moment(), "day")) {
            return showTodayAsString ? "Hoje" : moment(date).format("HH:mm");
        } else if (moment(date).isSame(moment().add(-1, "days"), "day")) {
            return "Ontem";
        }
        return moment(date).format("DD/MM/YYYY");
    }
    updateMessages(message: any, sectionItems: Array<any>) {
        const store = useMainStore()
        let sections: any = [...sectionItems];
        let index = sectionItems.length - 1;

        const currDate = moment(message.created_at).format("YYYY-MM-DD");
        const prevDate = moment(sections[index].created_at).format("YYYY-MM-DD");

        let item = {
            id: message.created_at,
            isHeader: true,
            title: this.getFormattedDate(currDate, true),
        };

        message.image =
            message?.user_id == store.session?.id
                ? `${process.env.VUE_APP_IMG_BASE_URL}${message?.user?.profile_image}`
                : new UserService().getAvatar(message?.user?.profile_image);
        message.name = message.user?.name;
        message.time = moment(message.created_at).format("HH:mm")

        if (currDate !== prevDate) {
            sections.push(item);
        }

        sections.push(message);

        return sections
    }
}
