<template>
  <nav
    class="navbar navbar-vertical navbar-light fixed-start navbar-expand-md d-md-none"
    id="sidebar"
  >
    <div class="container-fluid">
      <!-- Toggler -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse"
        aria-expanded="true"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Brand -->
      <router-link class="navbar-brand text-start" :to="$paths.home">
        <img
          src="@/assets/img/sidebar_logo.svg"
          class="navbar-brand-img mx-auto"
          alt="..."
        />
      </router-link>

      <!-- User (xs) -->
      <div class="navbar-user d-md-none">
        <!-- Dropdown -->
        <div class="dropdown">
          <!-- Toggle -->
          <a
            href="#"
            id="sidebarIcon"
            class="dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="avatar avatar-sm">
              <img
                v-if="session?.profile_image"
                :src="session.profile_image"
                class="avatar-img rounded-circle"
                alt="..."
              />
              <i
                v-else
                class="fa-solid fa-user-circle text-muted"
                style="font-size: 40px"
              />
            </div>
          </a>

          <!-- Menu -->
          <div
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="sidebarIcon"
          >
            <span
              @click="logout"
              class="dropdown-item text-danger cursor-pointer"
              >Sair</span
            >
            <!-- <hr class="dropdown-divider" /> -->
          </div>
        </div>
      </div>

      <!-- Collapse -->
      <div class="navbar-collapse collapse" id="sidebarCollapse" style="">
        <!-- Navigation -->
        <ul class="navbar-nav">
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              href="#sidebarDashboards"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="true"
              aria-controls="sidebarDashboards"
            >
              <i class="fe fe-home"></i> Dashboards
            </a>
            <div class="collapse show" id="sidebarDashboards">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <a href="./index.html" class="nav-link active"> Default </a>
                </li>
                <li class="nav-item">
                  <a
                    href="./dashboard-project-management.html"
                    class="nav-link"
                  >
                    Project Management
                  </a>
                </li>
                <li class="nav-item">
                  <a href="./dashboard-ecommerce.html" class="nav-link">
                    E-Commerce
                  </a>
                </li>
              </ul>
            </div>
          </li> -->

          <router-link active-class="router-link-active" :to="$paths.home">
            <li class="nav-item">
              <a class="nav-link">Home</a>
            </li>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.appointments.list"
          >
            <li class="nav-item">
              <a class="nav-link text-paragraph">Agenda</a>
            </li>
          </router-link>
          <router-link active-class="router-link-active" :to="$paths.chat.list">
            <li class="nav-item">
              <a class="nav-link text-paragraph">Chat</a>
            </li>
          </router-link>
          <router-link
            :class="{ 'router-link-active': financialLinkActive }"
            :to="`${$paths.financial.list}/repasses`"
          >
            <li class="nav-item">
              <a class="nav-link">Financeiro </a>
            </li>
          </router-link>
          <router-link
            :to="`${$paths.more}/minha-conta`"
            :class="{ 'router-link-active': moreLinkActive }"
          >
            <li class="nav-item">
              <a class="nav-link text-paragraph">Mais</a>
            </li>
          </router-link>
        </ul>

        <!-- Divider -->
        <!-- <hr class="navbar-divider my-3" /> -->

        <!-- Heading -->
        <!-- <h6 class="navbar-heading">Documentation</h6> -->
      </div>
      <!-- / .navbar-collapse -->
    </div>
  </nav>
</template>

<script lang="ts">
import { useMainStore } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    session() {
      return useMainStore().session;
    },
    moreLinkActive() {
      return this.$route.path.includes("mais");
    },
    financialLinkActive() {
      return this.$route.path.includes("financeiro");
    },
  },
  methods: {
    logout() {
      useMainStore().clearSession();
      this.$router.push(this.$paths.login);
    },
  },
});
</script>

<style scoped>
.sidebar-icon {
  width: 25px;
  text-align: left;
}
</style>
