import { useMainStore } from "@/store";
import { mapState } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        uploading: {
            type: Boolean,
            default: false
        },
        inputId: {
            type: String,
            default: 'avatar_uploader_input'
        },
        placeholder: {
            type: String,
            default: ''
        },
        avatar: {
            type: String,
            default: ''
        }
    },
    emits: ['onAvatarUploaded'],
    methods: {
        openModalForFile() {
            document.getElementById(this.inputId)?.click()
        },
        async fileUploaded(event: any) {
            this.$emit('onAvatarUploaded', event)
        },
    }
})