<template>
  <div class="d-flex">
    <div
      v-for="index in loop"
      :key="index"
      @click="handlePress(index - 1)"
      :disabled="disabled"
      class="me-1"
      :class="{ 'cursor-pointer': !disabled }"
    >
      <i class="fas fa-star" :class="[isSolid(index), size]" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    color: {
      type: String,
      default: "text-warning",
    },
    size: {
      type: String,
      default: "",
    },
    loop: {
      type: Number,
      default: 5,
    },
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onClick"],
  data() {
    return {
      rating: this.value,
    };
  },
  methods: {
    isSolid(index: number): string {
      return this.rating >= index ? this.color : "text-primary25";
    },
    handlePress(index: number): void {
      if (this.disabled) return;
      if (this.rating === index + 1) {
        this.rating = index;
      } else {
        this.rating = index + 1;
      }
      this.$emit("onClick", this.rating);
    },
  },
});
</script>
