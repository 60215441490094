<template>
  <div
    class="align-items-center d-flex flex-fill justify-content-center"
    :class="height"
  >
    <span
      class="spinner-border spinner-border text-primary"
      role="status"
      aria-hidden="true"
    ></span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    height: {
      required: false,
      default: "vh-50",
    },
  },
});
</script>

<style scoped>
.vh-50 {
  height: 50vh;
}
</style>
