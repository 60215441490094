import { useMainStore } from "@/store";
import useVuelidate from "@vuelidate/core";
import { helpers, required, url } from "@vuelidate/validators";
import { mapState } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        ...mapState(useMainStore, ['session'])
    },
    data() {
        return {
            v$: useVuelidate(),
            submitting: false,
            form: {
                intro_video_link: ''
            }
        }
    },
    validations: {
        form: {
            intro_video_link: {
                required: helpers.withMessage('Informe um link válido', required),
                // url: helpers.withMessage('Intro video link is not valid', url)
            }
        }
    },
    methods: {
        async submit() {
            try {
                this.submitting = true
                await this.$userService.update(this.form)
                this.$eventBus.$emit('showToast', {
                    title: "Alterações feitas",
                    msg: "Suas informações foram salvas",
                })
            } catch (error) {
                console.error(error)
            } finally {
                this.submitting = false
            }
        }
    }
})