
import { toggleOffcanvas } from "@/components/OffcanvasComponent.vue";
import { useMainStore } from "@/store";
import { Component } from "vue";
import AppointmentDetail from "@/views/Appointments/AppointmentDetail/AppointmentDetail.vue";
import AddCoupon from "@/views/More/Coupons/AddCoupon/AddCoupon.vue";
import AccountUpdate from "@/views/More/MyAccount/AccountUpdate/AccountUpdate.vue";
import PasswordUpdate from "@/views/More/MyAccount/PasswordUpdate/PasswordUpdate.vue";
import About from "@/views/OffcanvasViews/About/About.vue";
import AddCreditCard from "@/views/OffcanvasViews/AddCreditCard/AddCreditCard.vue";
import AppointmentPrice from "@/views/OffcanvasViews/AppointmentPrice/AppointmentPrice.vue";
import Approaches from "@/views/OffcanvasViews/Approaches/Approaches.vue";
import CreditCards from "@/views/OffcanvasViews/CreditCards/CreditCards.vue";
import Gender from '@/views/OffcanvasViews/Gender/Gender.vue';
import ImproveYourProfile from '@/views/OffcanvasViews/ImproveYourProfile/ImproveYourProfile.vue';
import IntroVideo from '@/views/OffcanvasViews/IntroVideo/IntroVideo.vue';
import Languages from "@/views/OffcanvasViews/Languages/Languages.vue";
import MyProfile from '@/views/OffcanvasViews/MyProfile/MyProfile.vue';
import PrivacyPolicy from "@/views/OffcanvasViews/PrivacyPolicy/PrivacyPolicy.vue";
import ProfileEdit from '@/views/OffcanvasViews/ProfileEdit/ProfileEdit.vue';
import ProfileImage from '@/views/OffcanvasViews/ProfileImage/ProfileImage.vue';
import Ratings from '@/views/OffcanvasViews/Ratings/Ratings.vue';
import ShareSchedule from '@/views/OffcanvasViews/ShareSchedule/ShareSchedule.vue';
import Specialties from "@/views/OffcanvasViews/Specialties/Specialties.vue";
import TermsOfUse from "@/views/OffcanvasViews/TermsOfUse/TermsOfUse.vue";
import ScheduleInfo from "@/views/Register/ScheduleInfo/ScheduleInfo.vue";
import PayoutDetail from "../Financial/PayoutDetail.vue";
import AvailabilitySettings from "../Home/AvailabilitySettings.vue";
import MoreContent from "../More/MoreContent.vue";
import AppointmentRecurrency from "./AppointmentRecurrency/AppointmentRecurrency.vue";
import CRP from "./CRP/CRP.vue";
import MyPatientDetail from "./MyPatientDetail/MyPatientDetail.vue";
import MyPatients from "./MyPatients/MyPatients.vue";
import RescheduleAppointment from "./RescheduleAppointment/RescheduleAppointment.vue";

export enum EOffcanvasViews {
    add_credit_card = "add_credit_card",
    add_coupon = "add_coupon",
    appointment_detail = "appointment_detail",
    appointment_recurrency = "appointment_recurrency",
    availability_settings = "availability_settings",
    crp = "crp",
    improve_your_profile = "improve_your_profile",
    my_patient_detail = "my_patient_detail",
    my_patients = "my_patients",
    my_profile = "my_profile",
    payout_detail = "payout_detail",
    privacy_policy = "privacy_policy",
    profile_edit = "profile_edit",
    profile_image = "profile_image",
    ratings = "ratings",
    reschedule_appointment = "reschedule_appointment",
    schedule_info = "schedule_info",
    share_schedule = "share_schedule",
    specialties = "specialties",
    terms_of_use = "terms_of_use",
    languages = "languages",
    appointment_price = "appointment_price",
    password_update = "password_update",
    credit_cards = "credit_cards",
    gender = 'gender',
    intro_video = 'intro_video',
    approaches = 'approaches',
    about = 'about',
    more = 'more',
    account_update = 'account_update'
}

export type OffcanvasViewKey = keyof typeof EOffcanvasViews;

export interface IOffcanvasConfig {
    title: string
    path: string
    detail_id?: number
    props?: any
    component?: Component
}

interface IOffcanvasPages {
    title: string
    component: Component
}

export type IOffcanvasViewsConfig = Record<OffcanvasViewKey, IOffcanvasConfig>;

const pages: Record<OffcanvasViewKey, IOffcanvasPages> = {
    [EOffcanvasViews.add_credit_card]: {
        title: "Adicionar cartão",
        component: AddCreditCard
    },
    [EOffcanvasViews.appointment_price]: {
        title: "Valor da sessão",
        component: AppointmentPrice
    },
    [EOffcanvasViews.appointment_detail]: {
        title: "Detalhes da sessão",
        component: AppointmentDetail
    },
    [EOffcanvasViews.payout_detail]: {
        title: "Detalhes do repasse",
        component: PayoutDetail
    },
    [EOffcanvasViews.availability_settings]: {
        title: "Configurar sessões",
        component: AvailabilitySettings
    },
    [EOffcanvasViews.schedule_info]: {
        title: "Disponibilidade de horários",
        component: ScheduleInfo
    },
    [EOffcanvasViews.account_update]: {
        title: "Editar conta",
        component: AccountUpdate
    },
    [EOffcanvasViews.password_update]: {
        title: "Alterar senha",
        component: PasswordUpdate
    },
    [EOffcanvasViews.credit_cards]: {
        title: "Informações de pagamento",
        component: CreditCards
    },
    [EOffcanvasViews.terms_of_use]: {
        title: "Termos de uso",
        component: TermsOfUse
    },
    [EOffcanvasViews.privacy_policy]: {
        title: "Política de privacidade",
        component: PrivacyPolicy
    },
    [EOffcanvasViews.add_coupon]: {
        title: "Adicionar código",
        component: AddCoupon
    },
    [EOffcanvasViews.improve_your_profile]: {
        title: "Melhore seu perfil",
        component: ImproveYourProfile
    },
    [EOffcanvasViews.share_schedule]: {
        title: "Compartilhar horário avulso",
        component: ShareSchedule
    },
    [EOffcanvasViews.profile_edit]: {
        title: "Editar perfil",
        component: ProfileEdit
    },
    [EOffcanvasViews.my_profile]: {
        title: 'Meu perfil',
        component: MyProfile
    },
    [EOffcanvasViews.ratings]: {
        title: 'Depoimentos',
        component: Ratings
    },
    [EOffcanvasViews.profile_image]: {
        title: 'Foto de perfil',
        component: ProfileImage
    },
    [EOffcanvasViews.gender]: {
        title: 'Qual seu gênero?',
        component: Gender
    },
    [EOffcanvasViews.intro_video]: {
        title: 'Video de introdução',
        component: IntroVideo
    },
    [EOffcanvasViews.languages]: {
        title: 'Idiomas',
        component: Languages
    },
    [EOffcanvasViews.specialties]: {
        title: 'Especialidades',
        component: Specialties
    },
    [EOffcanvasViews.approaches]: {
        title: 'Abordagens',
        component: Approaches
    },
    [EOffcanvasViews.about]: {
        title: 'Informações de formação',
        component: About
    },
    [EOffcanvasViews.more]: {
        title: "Mais",
        component: MoreContent
    },
    [EOffcanvasViews.reschedule_appointment]: {
        title: "Reagendar sessão",
        component: RescheduleAppointment
    },
    [EOffcanvasViews.my_patients]: {
        title: "Meus pacientes",
        component: MyPatients
    },
    [EOffcanvasViews.my_patient_detail]: {
        title: "Detalhes do paciente",
        component: MyPatientDetail
    },
    [EOffcanvasViews.appointment_recurrency]: {
        title: ' ',
        component: AppointmentRecurrency
    },
    [EOffcanvasViews.crp]: {
        title: 'Fotos do CRP',
        component: CRP
    },
};

export const OffcanvasViewsConfig = Object.fromEntries(Object.entries(pages).map(([key, value]) => [key, { path: key as OffcanvasViewKey, title: value.title }])) as IOffcanvasViewsConfig
export const Views = Object.fromEntries(Object.entries(pages).map(([key, value]) => [key, value.component])) as Record<OffcanvasViewKey, IOffcanvasConfig>

export const navigateCanvas = (to: OffcanvasViewKey, config?: IOffcanvasConfig | null, toggle: boolean = true) => {
    toggle = toggle || !useMainStore().offcanvas_history.length

    const last = JSON.stringify(useMainStore().offcanvas_history)
    const conf = config || OffcanvasViewsConfig[to]

    if (last === JSON.stringify(conf)) return

    if (toggle) toggleOffcanvas("offcanvas_views");

    useMainStore().saveOffcanvasConfig(conf);
};

export const goBackCanvas = () => {
    const toggle = useMainStore().offcanvas_history.length == 1
    if (toggle) {
        closeOffCanvas()
    } else {
        useMainStore().popOffcanvasHistory()
    }
}

export const closeOffCanvas = () => {
    useMainStore().clearOffcanvasConfig()
    const offCanvasRef = document.getElementById("offcanvas_views");
    const hasOffCanvasOpened = offCanvasRef?.className.includes("show")
    if (hasOffCanvasOpened) {
        toggleOffcanvas("offcanvas_views");
    }
}