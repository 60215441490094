<template>
  <div class="offcanvas-body">
    <LoadingContainer v-if="loading" />
    <div v-html="privacyPolicy"></div>
  </div>
</template>

<script lang="ts">
import LoadingContainer from "@/components/LoadingContainer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  mounted() {
    this.getPrivacyPolicy();
  },
  data() {
    return {
      privacyPolicy: "",
      loading: true,
    };
  },
  methods: {
    async getPrivacyPolicy() {
      try {
        const res = await this.$userService.getPrivacyPolicy();
        this.privacyPolicy = res.data.terms_of_use;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped></style>
