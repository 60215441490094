
import AppointmentService from "./AppointmentService";
import UserService from "./UserService";
import NetworkService, { Endpoint } from "./NetworkService";
import moment from "moment";

export default class PatientsService extends NetworkService {
    constructor() {
        super();
    }

    list = async () => {
        const res = await this.get(Endpoint.patients);
        res.data = this.preparePatient(res.data);
        res.data.single = res.data.single.map(this.prepareSingleAppointment);
        res.data.recurrent = res.data.recurrent.map(
            this.prepareRecurrentAppointment
        );
        return res;
    };

    show = async (id: number) => {
        const res = await this.get(`${Endpoint.patients}/${id}`);
        res.data = this.preparePatient(res.data);
        res.data.recurrencies = res.data.recurrencies.map(
            this.prepareRecurrentAppointment
        );
        res.data.appointments = res.data.appointments.map(
            this.prepareSingleAppointment
        );

        return res;
    };

    prepareSingleAppointment = (data: any) => {
        const apptService = new AppointmentService();
        data.profile_image_formatted = new UserService().getAvatar(
            data.profile_image
        );

        data.formatted_datetime = `${moment(data.date).format("DD/MM")} - ${moment(data.date)
            .format(`dddd`)
            .capitalizeFirst()} às ${moment(data.date).format(`HH[h]mm`)}`
        data.status_label = apptService.getStatus(data);
        data.status_color = apptService.getStatusColor(data);

        data.valid_appointments_count_formatted = `${data.valid_appointments_count
            } ${data.valid_appointments_count == 1 ? "Sessão" : "Sessões"}`;

        return data;
    };

    prepareRecurrentAppointment = (data: any) => {
        data.profile_image_formatted = new UserService().getAvatar(
            data.profile_image
        );

        if (data.latest_recurrency?.frequency) {
            data.latest_recurrency.frequency = this.fixFrequency(
                data.latest_recurrency?.frequency,
                data.interval
            );

            data.latest_recurrency_frequency =
                AppointmentService.FREQUENCY_TYPES_LABEL[
                data.latest_recurrency?.frequency
                ];
        }

        if (data?.frequency) {
            data.frequency = this.fixFrequency(data.frequency, data.interval);

            data.title = `Sessões ${AppointmentService.FREQUENCY_TYPES_PLURAL_LABEL[data.frequency]
                }`;
            data.description = new AppointmentService().getFrequencyDateText(
                data?.frequency,
                data.interval,
                data.reference_date,
                true
            );
        }

        data.value = Number(data.value).toCurrency()

        data.status_label = data.status == "active" ? "Ativa" : "Cancelada";

        data.status_bg_color =
            data.status == "active" ? 'bg-success' : 'bg-primary50';

        return data;
    };

    preparePatient = (data: any) => {
        data.user = {
            name: data.name,
            profile_image: data.profile_image,
            profileImage: new UserService().getAvatar(data.profile_image),
            handicapped: data.handicapped,
            deleted_at: data.deleted_at,
        };
        return data;
    };

    fixFrequency = (frequency: string, interval: number) => {
        return frequency == AppointmentService.FREQUENCY_TYPES.WEEKLY &&
            interval == 2
            ? "fortnightly"
            : frequency;
    };
}
