import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "data-bs-target"]
const _hoisted_2 = ["id", "data-bs-backdrop"]
const _hoisted_3 = {
  key: 0,
  class: "modal-header border-0"
}
const _hoisted_4 = { class: "text-header mb-0" }
const _hoisted_5 = ["id"]
const _hoisted_6 = {
  key: 2,
  class: "modal-footer border-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      hidden: "",
      id: `${_ctx.id}_modal_button`,
      type: "button",
      "data-bs-toggle": "modal",
      "data-bs-target": `#${_ctx.id}`
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: "modal fade",
      id: _ctx.id,
      tabindex: "-1",
      "aria-labelledby": "exampleModalLabel",
      "aria-hidden": "true",
      "data-bs-backdrop": _ctx.backdrop
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-dialog modal-dialog-centered modal-dialog-scrollable", _ctx.size])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal-content shadow-lg", { 'vh-100': _ctx.fullHeight }])
        }, [
          (_ctx.hasHeader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
                (_ctx.hasCloseButton)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      type: "button",
                      class: "fas fa-times text-muted",
                      "data-bs-dismiss": "modal",
                      "aria-label": "Close",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.modalClosed && _ctx.modalClosed(...args)))
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.$slots.body)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["modal-body border-0", _ctx.bodyClass]),
                id: _ctx.modalBodyId
              }, [
                _renderSlot(_ctx.$slots, "body")
              ], 10, _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.$slots.footer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "footer")
              ]))
            : _createCommentVNode("", true),
          (_ctx.$slots.content)
            ? _renderSlot(_ctx.$slots, "content", { key: 3 })
            : _createCommentVNode("", true)
        ], 2)
      ], 2)
    ], 8, _hoisted_2)
  ]))
}