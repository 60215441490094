import moment from 'moment';

export function isNameValid(value: string) {
    var ok = true
    var pattern = new RegExp('^(?=.*[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF])([a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)$');
    try {
        const fullName = value.trim().split(' ')
        if (fullName.length < 2) {
            return false
        }
        if (fullName[0].length < 2) return false
        fullName.map(r => {
            if (!pattern.test(r) || r.length < 2) {
                ok = false
            }
        })
        return ok
    } catch (e) {
        return false
    }
}
export function isDateFuture(value: string) {
    if (!value) return false
    try {
        const date = moment(value)
        if (!date.isValid()) return false
        return date.isSameOrAfter(moment(), 'day')
    } catch (e) {
        return false
    }
}

export function checkMinAgeLimit(value: string, minAgeLimit = 18) {
    if (!value) return false;
    const date = moment(value);
    // const date = moment(value, "DD[/]MM[/]YYYY", true);
    const now = moment();
    const validFormat = date.isValid();
    if (!validFormat) return false;
    if (minAgeLimit) {
        const diff = now.diff(date, "years");
        return diff >= minAgeLimit;
    } else {
        return true;
    }
}

export function checkDateIsValid(start: string, end: string) {
    try {
        if (start.length < 5 || end.length < 5) return false;
        const date1 = start.replace(":", "");
        const date2 = end.replace(":", "");
        return date1 < date2;
    } catch (e) {
        return false;
    }
}

export function isCPFValid(strCPF: string) {
    if (!strCPF) return false;
    var Soma;
    var Resto;
    var i;

    strCPF = strCPF.replace(/[^\w\s]/gi, "").replace(" ", "");

    Soma = 0;
    if (strCPF == "00000000000" || !strCPF) return false;

    for (i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
}