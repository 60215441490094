import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "text-subtitle mb-0" }
const _hoisted_4 = {
  class: "card-body overflow-scroll py-0",
  id: "chat_card_body",
  style: {"height":"calc(100vh - 442px)"}
}
const _hoisted_5 = { class: "card-footer" }
const _hoisted_6 = {
  class: "modal-body py-0 d-flex flex-column",
  id: "chat_modal_body"
}
const _hoisted_7 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingContainer = _resolveComponent("LoadingContainer")!
  const _component_ChatRoomContent = _resolveComponent("ChatRoomContent")!
  const _component_ChatRoomInput = _resolveComponent("ChatRoomInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.type === 'screen')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.user_name), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.loadingChat)
              ? (_openBlock(), _createBlock(_component_LoadingContainer, { key: 0 }))
              : (_openBlock(), _createBlock(_component_ChatRoomContent, {
                  key: 1,
                  formattedDate: _ctx.formatted_date,
                  sectionItems: _ctx.sectionItems,
                  defaultMessage: _ctx.defaultMessage
                }, null, 8, ["formattedDate", "sectionItems", "defaultMessage"]))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ChatRoomInput, { onHandleSend: _ctx.handleSend }, null, 8, ["onHandleSend"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type === 'modal')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.loadingChat)
              ? (_openBlock(), _createBlock(_component_LoadingContainer, { key: 0 }))
              : (_openBlock(), _createBlock(_component_ChatRoomContent, {
                  key: 1,
                  formattedDate: _ctx.formatted_date,
                  sectionItems: _ctx.sectionItems,
                  defaultMessage: _ctx.defaultMessage
                }, null, 8, ["formattedDate", "sectionItems", "defaultMessage"]))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ChatRoomInput, { onHandleSend: _ctx.handleSend }, null, 8, ["onHandleSend"])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}