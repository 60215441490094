import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "offcanvas-body" }
const _hoisted_2 = { class: "offcanvas-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageCropper = _resolveComponent("ImageCropper")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ImageCropper, {
        required: "",
        accept: '.jpeg,.png,.jpg',
        aspectRatio: 1 / 1,
        onCropEnd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onImageUploaded($event, 'document_image'))),
        class: "mb-4",
        modalId: "document_image",
        inputId: "document_image_input",
        label: "Foto do seu CRP",
        image: _ctx.images.document_image.path,
        backdrop: "false"
      }, null, 8, ["image"]),
      _createVNode(_component_ImageCropper, {
        required: "",
        accept: '.jpeg,.png,.jpg',
        aspectRatio: 1 / 1,
        onCropEnd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onImageUploaded($event, 'document_in_hand'))),
        class: "mb-4",
        modalId: "document_in_hand",
        inputId: "document_image_input",
        label: "Foto sua segurando seu CRP",
        image: _ctx.images.document_in_hand.path,
        backdrop: "false"
      }, null, 8, ["image"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        fullWidth: "",
        label: "Concluir",
        onOnClick: _ctx.updateDocuments,
        loading: _ctx.submitting,
        marginVertical: false,
        disabled: !_ctx.currentImagetoUpload
      }, null, 8, ["onOnClick", "loading", "disabled"])
    ])
  ], 64))
}