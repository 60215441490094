<template>
  <ConfirmationAlert />
  <SubscriptionModal />
  <NotificationToast />
  <OffcanvasViews />
  <ChatRoomModal />
  <Specialties />
  <Approaches />
  <router-view />
  <AppointmentRating />
</template>

<script>
import NotificationToast from "@/components/NotificationToast";
import { computed, defineComponent, onMounted } from "vue";
import { toggleOffcanvas } from "./components/OffcanvasComponent.vue";
import { startFirebase } from "./firebaseinit";
import { useMainStore } from "./store";
import { toggleModal } from "./utils/Helper";
import ChatRoomModal from "./views/Chat/ChatRoom/ChatRoomModal.vue";
import OffcanvasViews from "./views/OffcanvasViews/OffcanvasViews";
import SubscriptionModal from "./views/Modals/SubscriptionModal/SubscriptionModal.vue";
import Specialties from "./views/Modals/Specialties/Specialties.vue";
import Approaches from "./views/Modals/Approaches/Approaches.vue";
import AppointmentRating from "./views/Appointments/AppointmentRating/AppointmentRating";

export default defineComponent({
  setup() {
    const store = useMainStore();
    const chatId = computed(() => store?.chat_room_config?.id);
    const chatMode = computed(() => store?.chat_room_config?.mode);
    const offcanvasPath = computed(() => store?.offcanvas_config?.path);
    const appointmentRatingPending = computed(
      () => store?.appointment_rating_pending
    );

    onMounted(() => {
      if (offcanvasPath.value) {
        toggleOffcanvas("offcanvas_views");
      }
      if (chatId.value && chatMode.value === "modal") {
        toggleModal("chat_room");
      }
      if (appointmentRatingPending.value) {
        toggleModal("appointment_rating_pending");
      }
    });
  },
  mounted() {
    console.log("VERSION:", this.$envString);

    /**
     * NOTE: SPRINT 26
     * ITEM 1201
     * app criado na conta da phurshell, fiz igual o psi pacientes e so funciona em prod
     * os dados do FB estao como autoday... não me pergunte
     */
    startFirebase();
  },
  created() {
    document.title = "Psiapp Pro | Web";
  },
  components: {
    NotificationToast,
    SubscriptionModal,
    OffcanvasViews,
    ChatRoomModal,
    Specialties,
    Approaches,
    AppointmentRating,
  },
});
</script>
