<template>
  <div class="mb-4" :class="{ 'border-bottom': hasBorder }">
    <div class="d-flex justify-content-between align-items-center">
      <p class="text-header">
        <i :class="icon"></i>
        {{ title }}
      </p>
      <p
        class="text-subtitle text-secondary cursor-pointer"
        @click="handleAction"
      >
        <i class="text-secondary" :class="actionIcon"></i>
        {{ actionLabel }}
      </p>
    </div>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: String,
    icon: String,
    actionLabel: String,
    actionIcon: String,
    hasBorder: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["action"],
  methods: {
    handleAction() {
      this.$emit("action");
    },
  },
});
</script>
