import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "d-flex justify-content-between" }
const _hoisted_3 = {
  key: 0,
  class: "form-label text-muted"
}
const _hoisted_4 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_5 = ["value"]
const _hoisted_6 = ["data-maska", "type", "maxlength", "placeholder", "min"]
const _hoisted_7 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            (_ctx.required)
              ? (_openBlock(), _createElementBlock("i", _hoisted_4, "*"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "labelContentEnd")
    ]),
    (_ctx.readOnly)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          class: "form-control form-disabled",
          value: _ctx.value,
          disabled: "",
          style: _normalizeStyle(_ctx.inputStyle)
        }, null, 12, _hoisted_5))
      : _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 1,
          class: _normalizeClass(["form-control", { 'is-invalid': _ctx.model.$error }]),
          "data-maska": _ctx.mask,
          lang: 'pt-BR',
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.$model) = $event)),
          type: _ctx.type,
          maxlength: _ctx.maxLength,
          placeholder: _ctx.placeholder,
          min: _ctx.minDate,
          style: _normalizeStyle(_ctx.inputStyle)
        }, null, 14, _hoisted_6)), [
          [_directive_maska],
          [
            _vModelDynamic,
            _ctx.model.$model,
            void 0,
            { trim: true }
          ]
        ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("small", null, _toDisplayString(_ctx.errorMessage), 1)
    ]),
    _renderSlot(_ctx.$slots, "content")
  ]))
}