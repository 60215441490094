<template>
  <div class="offcanvas-body">
    <LoadingContainer v-if="loading" />
    <template v-else>
      <Profile :user="patient?.user" :chat="patient?.chat" />

      <Section
        title="Recorrências"
        icon="fa-solid fa-arrow-rotate-left"
        :hasBorder="false"
        v-if="patient.recurrencies && patient.recurrencies.length"
      >
        <ScrollHorizontal :items="patient.recurrencies">
          <template v-slot:default="{ item }">
            <div
              @click="handleRecurrency(item.id)"
              class="card rounded-4 cursor-pointer"
              style="max-width: 220px; min-height: 200px"
            >
              <div class="card-body d-flex flex-column">
                <p class="text-subtitle">{{ item?.title }}</p>
                <p
                  class="text-paragraph"
                  style="white-space: normal !important"
                >
                  {{ item?.description }}
                </p>
                <div
                  class="d-flex align-items-center justify-content-between mt-auto"
                >
                  <p class="text-paragraph mb-0">
                    {{ item?.value }}
                  </p>
                  <span class="badge" :class="item?.status_bg_color">
                    {{ item?.status_label }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </ScrollHorizontal>
      </Section>

      <hr class="mb-5" />

      <Section
        title="Sessões"
        icon="fa-regular fa-calendar-days"
        :hasBorder="false"
      >
        <div
          class="d-flex py-4 border-bottom align-items-center cursor-pointer"
          @click="handleAppointment(item.id)"
          v-for="(item, index) of patient.appointments"
          :key="index"
        >
          <div>
            <p class="text-subtitle mb-2">{{ item.formatted_datetime }}</p>
            <div class="d-flex align-items-center">
              <p class="text-label text-primary50 mb-0 me-2">
                R${{ item.value }}
              </p>
              <p class="text-label mb-0" :class="item.status_color">
                {{ item.status_label }}
              </p>
            </div>
          </div>
          <i class="fa-solid fa-chevron-right text-primary ms-auto fa-lg"></i>
        </div>
      </Section>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MyPatientDetailController from "./MyPatientDetailController";

export default defineComponent({
  mixins: [MyPatientDetailController],
});
</script>

<style scoped></style>
