import { toggleModal } from "@/components/ModalComponent.vue";
import { useMainStore } from "@/store";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            v$: useVuelidate(),
            submitting: false,
            form: {
                rating: 0,
                description: "",
            }
        };
    },
    validations() {
        return {
            form: {
                rating: {},
                description: {},
            },
        };
    },
    methods: {
        async submitRating() {
            try {
                this.submitting = true
                const params = {
                    appointment_id: parseInt(useMainStore().appointment_rating_pending),
                    trouble: false,
                    ...this.form
                }
                await this.$appointmentService.callRating(params);
                this.handleFinish();
            } catch (error) {
                console.log(error);
            } finally {
                this.submitting = false
            }
        },
        setRating(rating: number) {
            this.form.rating = rating;
        },
        reset() {
            this.form.rating = 0;
            this.form.description = "";
        },
        handleFinish() {
            this.reset();
            useMainStore().clearAppointmentRatingPending();
            toggleModal("appointment_rating_pending");
        }
    },
})