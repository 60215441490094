<template>
  <div class="offcanvas-body">
    <div class="h-100 d-flex flex-column justify-content-between">
      <RadioInput :options="options" :model="v$.form.gender" />
      <FormButton
        :form="v$.form"
        label="Concluir"
        :loading="submitting"
        @onClick="submit"
        :disabled="v$.invalid"
      />
    </div>
  </div>
</template>

<script lang="ts">
import GenderController from "./GenderController";
export default GenderController;
</script>
