import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "offcanvas-body" }
const _hoisted_2 = { class: "offcanvas-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingContainer = _resolveComponent("LoadingContainer")!
  const _component_CheckboxInput = _resolveComponent("CheckboxInput")!
  const _component_FormButton = _resolveComponent("FormButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoadingContainer, { key: 0 }))
        : (_openBlock(), _createBlock(_component_CheckboxInput, {
            key: 1,
            options: _ctx.languages,
            model: _ctx.v$.form.languages
          }, null, 8, ["options", "model"]))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormButton, {
        marginVertical: false,
        form: _ctx.v$.form,
        label: "Concluir",
        loading: _ctx.submitting,
        onOnClick: _ctx.submit,
        disabled: _ctx.v$.invalid
      }, null, 8, ["form", "loading", "onOnClick", "disabled"])
    ])
  ], 64))
}