<template>
  <div class="card py-3 sticky-md-top">
    <!-- HEADER -->
    <div class="card-header px-3 pt-1">
      <div class="d-flex align-items-center">
        <i
          class="fa-solid fa-chevron-circle-left text-secondary fa-xl cursor-pointer"
          @click="prevMonth"
        />
        <p class="text-subtitle mb-0 mx-3">
          {{ currentMonthYear.capitalizeFirst() }}
        </p>
        <i
          class="fa-solid fa-chevron-circle-right text-secondary fa-xl cursor-pointer"
          @click="nextMonth"
        />
      </div>
      <p
        class="text-subtitle text-secondary cursor-pointer mb-0"
        @click="changeToToday"
      >
        Hoje
      </p>
    </div>

    <!-- WEEKDAYS -->
    <div class="d-flex justify-content-around">
      <div
        class="d-flex justify-content-center w-100 py-3"
        v-for="day in weekDays"
        :key="day"
      >
        <span class="text-label">
          {{ day }}
        </span>
      </div>
    </div>

    <!-- WEEKS -->
    <div
      class="d-flex justify-content-around"
      v-for="(week, index) in calendar"
      :key="index"
    >
      <!-- DAYS -->
      <div
        v-for="(day, index) in week"
        :key="index"
        :class="{ 'cursor-pointer': day.enabled }"
        class="d-flex justify-content-center w-100 py-3 pb-1 flex-column align-items-center"
        @click="day.enabled ? onDayClick(day) : null"
      >
        <span
          :class="getDayStyleClasses(day)"
          class="d-flex justify-content-center align-items-center"
          style="width: 2rem; height: 2rem"
        >
          {{ day.day }}
        </span>
        <div class="d-flex align-items-center justify-content-between">
          <div
            v-if="day.hasPendingAppointment"
            class="rounded-circle mt-1 appointment-circle bg-secondary ms-1"
          ></div>
          <div
            v-if="day.hasAcceptedAppointment"
            class="rounded-circle mt-1 appointment-circle bg-primary mx-1"
          ></div>
          <div
            v-if="day.hasConcludedAppointment"
            class="rounded-circle mt-1 appointment-circle bg-primary25 me-1"
          ></div>

          <div
            v-if="
              !day.hasPendingAppointment &&
              !day.hasAcceptedAppointment &&
              !day.hasConcludedAppointment
            "
            class="rounded-circle mt-1 appointment-circle bg-white"
          ></div>
        </div>
      </div>
    </div>
    <!-- <div class="card-footer">TESTE</div> -->
  </div>
</template>

<script lang="ts">
import { EventBus } from "@/utils/EventBus";
import Weekdays from "@/utils/Weekdays";
import moment from "moment";
import { computed, defineComponent, onMounted, PropType, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "Calendar",
  props: {
    appointments: {
      type: Array,
      default: () => [] as any[],
    },
    days: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    enableDaySelection: {
      type: Boolean,
      default: true,
    },
    showOnlyFutureDates: {
      type: Boolean,
      default: false,
    },
    routerControl: {
      type: Boolean,
      default: false,
    },
    paymentMethod: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const date = props.routerControl
      ? moment(route.query.date as string)
      : moment();

    const state = reactive({
      currentDate: date, // Data inicial como hoje
      selectedDate: date.format("YYYY-MM-DD"),
    });

    onMounted(() => {
      if (props.routerControl) {
        router.replace({ query: { date: state.selectedDate } });
      }
    });

    const currentMonthYear = computed(() => {
      return state.currentDate.format("MMMM YYYY");
    });

    const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

    const calendar = computed(() => {
      const startOfMonth = state.currentDate.clone().startOf("month");
      const endOfMonth = state.currentDate.clone().endOf("month");
      const startDate = startOfMonth.clone().startOf("week");
      const endDate = endOfMonth.clone().endOf("week");

      const weeks = [];
      let currentDay = startDate.clone();

      while (currentDay.isBefore(endDate, "day")) {
        const week = [];
        for (let i = 0; i < 7; i++) {
          const formattedDate = currentDay.format("YYYY-MM-DD");

          const hasPendingAppointment = checkIfHasAppointment(
            formattedDate,
            "aguardando"
          );
          const hasAcceptedAppointment = checkIfHasAppointment(
            formattedDate,
            "agendado"
          );
          const hasConcludedAppointment = checkIfHasAppointment(
            formattedDate,
            "concluido"
          );
          const hasFreeTime = checkIfHasFreeTime(currentDay.weekday());
          const today = moment().format("YYYY-MM-DD");
          const isCurrentMonth = currentDay.isSame(state.currentDate, "month");
          const hasAppointment =
            hasPendingAppointment ||
            hasAcceptedAppointment ||
            hasConcludedAppointment;
          let enabled = hasAppointment || (hasFreeTime && isCurrentMonth);

          if (props.showOnlyFutureDates) {
            let start =
              props.paymentMethod === "boleto"
                ? moment().add(7, "days")
                : moment();

            enabled = enabled && currentDay.isSameOrAfter(start, "day");
          }

          week.push({
            date: currentDay,
            isCurrentMonth,
            currentDate: moment(formattedDate),
            formattedDate,
            hasPendingAppointment,
            hasAcceptedAppointment,
            hasConcludedAppointment,
            appointmentActive: today == formattedDate || currentDay.isAfter(),
            day: currentDay.date(),
            isToday: today == formattedDate,
            enabled,
          });
          currentDay.add(1, "day");
        }
        weeks.push(week);
      }
      return weeks;
    });

    const checkIfHasAppointment = (day: any, type: any) => {
      if (props.appointments) {
        return props.appointments.some(
          (e: any) => e.status == type && e.date.split(" ")[0] == day
        );
      } else {
        return false;
      }
    };

    const checkIfHasFreeTime = (date: any) => {
      if (props.days) {
        return props.days.some(
          (elem: any) => elem.weekday == Weekdays.eng[date]
        );
      }
    };

    const changeToToday = () => {
      const date = moment();
      const formattedDate = date.format("YYYY-MM-DD");
      const currYearMonth = state.currentDate.format("YYYY-MM");
      const todaysYearMonth = date.format("YYYY-MM");
      if (currYearMonth != todaysYearMonth) {
        state.currentDate = moment();
      }
      state.selectedDate = formattedDate;

      if (props.routerControl) {
        router.replace({ query: { date: formattedDate } });
      }
    };

    const prevMonth = () => {
      state.currentDate = state.currentDate.clone().subtract(1, "month");
      state.selectedDate = state.currentDate
        .startOf("month")
        .format("YYYY-MM-DD");

      if (state.currentDate.isSame(moment(), "month")) {
        state.selectedDate = moment().format("YYYY-MM-DD");
      }

      if (props.routerControl) {
        router.replace({ query: { date: state.selectedDate } });
      }
    };

    const nextMonth = () => {
      state.currentDate = state.currentDate.clone().add(1, "month");
      state.selectedDate = state.currentDate
        .startOf("month")
        .format("YYYY-MM-DD");

      if (state.currentDate.isSame(moment(), "month")) {
        state.selectedDate = moment().format("YYYY-MM-DD");
      }

      if (props.routerControl) {
        router.replace({ query: { date: state.selectedDate } });
      }
    };

    const onDayClick = (date: any) => {
      if (props.enableDaySelection) {
        state.selectedDate = date.formattedDate;

        if (props.routerControl) {
          router.replace({ query: { date: date.formattedDate } });
        } else {
          EventBus.$emit("CALENDAR_DATE_CHANGED", date);
        }
      }
    };

    const getDayStyleClasses = (day: any) => {
      if (!day.enabled) {
        return ["text-primary25", "text-paragraph"];
      } else if (!day.isToday && day.formattedDate != state.selectedDate) {
        return ["text-primary", "text-subtitle"];
      } else if (day.formattedDate == state.selectedDate) {
        return ["text-white", "bg-primary", "rounded-circle", "text-subtitle"];
      } else if (day.isToday) {
        return ["text-secondary", "text-subtitle"];
      }
    };

    return {
      currentMonthYear,
      weekDays,
      calendar,
      prevMonth,
      nextMonth,
      onDayClick,
      changeToToday,
      getDayStyleClasses,
    };
  },
});
</script>

<style scoped>
.appointment-circle {
  width: 0.5rem;
  height: 0.5rem;
}
</style>
