import { sortCols } from '@/utils/Helper'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ListControllerMixin',
    // Declare o tipo genérico aqui, com um tipo padrão "any"
    // props: {
    //     itemsType: {
    //         type: null, // Para TypeScript, podemos ignorar o tipo no Vue
    //         required: false,
    //     }
    // },
    mounted() {
        this.loadingItems = true
        this.getData()
    },
    data<T>() {  // Use o tipo genérico <T>
        return {
            totalItemsRegistered: 0,
            items: [] as T[],  // Aplique o tipo genérico aqui
            countItemsShowing: 0,
            countItemsTotal: 0,
            moreItemsToLoad: true,
            loadingMoreItems: false,
            loadingItems: false,
            filters: {
                page: 1,
                q: ''
            },
            columns: [] as any[]
        }
    },
    methods: {
        async getData<T>(loadingMore = false) {
            try {
                if (!loadingMore) {
                    this.filters.page = 1
                }
                const res: any = await this.fetchPromise()
                const data = this.prepareResponse(res.data) as T[]  // Cast para T[]
                if (!loadingMore) {
                    this.items = data
                } else {
                    this.items = this.items.concat(data)
                }
                this.moreItemsToLoad = res.next_page_url != null
                this.countItemsShowing = res.to || 0
                this.countItemsTotal = res.total

                if (this.filters.page == 1 && this.filters.q == '') {
                    this.totalItemsRegistered = res.total
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.loadingItems = false
                this.loadingMoreItems = false
                this.columns = this.columns.map(c => { return { ...c, loading: false } })
            }
        },
        async fetchPromise() {
            throw new Error('Método não implementado')
        },
        prepareResponse(data: any) {
            return data
        },
        loadMore() {
            this.loadingMoreItems = true
            this.filters.page = this.filters.page + 1
            this.getData(true)
        },
        search(string: string) {
            this.filters.q = string
            this.getData()
        },
        sort(colIndex: number | string) {
            this.columns = sortCols(this.columns, colIndex)
            this.getData()
        }
    }
})


// import { sortCols } from '@/utils/Helper'
// import { defineComponent } from 'vue'

// export default defineComponent({
//     name: 'ListControllerMixin',
//     mounted() {
//         this.loadingItems = true
//         this.getData()
//     },
//     data() {
//         return {
//             totalItemsRegistered: 0,
//             items: [],
//             countItemsShowing: 0,
//             countItemsTotal: 0,
//             moreItemsToLoad: true,
//             loadingMoreItems: false,
//             loadingItems: false,
//             filters: {
//                 page: 1,
//                 q: ''
//             },
//             columns: [] as any[]
//         }
//     },
//     methods: {
//         async getData(loadingMore = false) {
//             try {
//                 if (!loadingMore) {
//                     this.filters.page = 1
//                 }
//                 const res: any = await this.fetchPromise()
//                 const data = this.prepareResponse(res.data)
//                 if (!loadingMore) {
//                     this.items = data
//                 } else {
//                     this.items = this.items.concat(data)
//                 }
//                 this.moreItemsToLoad = res.next_page_url != null
//                 this.countItemsShowing = res.to || 0
//                 this.countItemsTotal = res.total

//                 if (this.filters.page == 1 && this.filters.q == '') {
//                     // so seta o o totalzao quando nao tem filtro
//                     this.totalItemsRegistered = res.total
//                 }
//             } catch (e) {
//                 console.log(e)
//             } finally {
//                 this.loadingItems = false
//                 this.loadingMoreItems = false
//                 this.columns = this.columns.map(c => { return { ...c, loading: false } })
//             }
//         },
//         /**
//            * @return promise da lista que vc quer chamar
//            * -fazer o override na tela pra chamar a funcao/service certo
//            */
//         async fetchPromise() {
//             throw new Error('Método não implementado')
//         },
//         /**
//              * @return faz as mudanças na resposta e retorna pro getData
//              * -fazer o override na tela pra tratar a resposta conforme necessario
//              */
//         prepareResponse(data: any) {
//             return data
//         },
//         loadMore() {
//             this.loadingMoreItems = true
//             this.filters.page = this.filters.page + 1
//             this.getData(true)
//         },
//         search(string: string) {
//             this.filters.q = string
//             this.getData()
//         },
//         sort(colIndex: number | string) {
//             this.columns = sortCols(this.columns, colIndex)
//             this.getData()
//         }
//     }
// }) 