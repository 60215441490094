<template>
  <div>
    <button
      hidden
      :id="`${id}_modal_button`"
      type="button"
      data-bs-toggle="modal"
      :data-bs-target="`#${id}`"
    ></button>
    <div
      class="modal fade"
      :id="id"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :data-bs-backdrop="backdrop"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        :class="size"
      >
        <div class="modal-content shadow-lg" :class="{ 'vh-100': fullHeight }">
          <div class="modal-header border-0" v-if="hasHeader">
            <h2 class="text-header mb-0">{{ title }}</h2>
            <i
              v-if="hasCloseButton"
              type="button"
              class="fas fa-times text-muted"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="modalClosed"
            ></i>
          </div>

          <template v-if="$slots.body">
            <div
              class="modal-body border-0"
              :id="modalBodyId"
              :class="bodyClass"
            >
              <slot name="body"></slot>
            </div>
          </template>

          <template v-if="$slots.footer">
            <div class="modal-footer border-0">
              <slot name="footer"></slot>
            </div>
          </template>

          <template v-if="$slots.content">
            <slot name="content"></slot>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export function toggleModal(id: string) {
  const button = document.getElementById(`${id}_modal_button`);
  if (button) {
    button?.click();
  }
}

export default defineComponent({
  emits: ["modalClosed"],
  props: {
    id: String,
    title: String,
    size: String,
    modalBodyId: String,
    bodyClass: {
      type: String,
      default: "",
    },
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    backdrop: {
      type: String,
      default: "static",
    },
  },
  methods: {
    modalClosed() {
      this.$emit("modalClosed");
    },
  },
});
</script>
