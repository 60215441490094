import { useMainStore } from "@/store";
import { mapState } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        // this.images.document_image.path = useMainStore().session?.document_image || ''
        // this.images.document_in_hand.path = useMainStore().session?.document_in_hand || ''
    },
    data() {
        return {
            submitting: false,
            uploading: false,
            currentImagetoUpload: 'document_image',
            images: {
                document_image: {
                    path: useMainStore().session?.document_image || '',
                    file: ''
                },
                document_in_hand: {
                    path: useMainStore().session?.document_in_hand || '',
                    file: ''
                },
            } as { [key: string]: { path: string, file: any } }
        }
    },
    computed: {
        ...mapState(useMainStore, ['session']),
    },
    methods: {
        async onImageUploaded(event: any, type: string) {
            try {
                this.uploading = true
                this.images[type].path = event.base64
                this.images[type].file = event.file
            } catch (error) {
                console.error(error)
            } finally {
                this.uploading = false
            }
        },
        async updateDocuments() {
            try {
                this.submitting = true
                const params = {
                    document_image: this.images.document_image.file,
                    document_in_hand: this.images.document_in_hand.file
                }
                await this.$userService.updateProfileImage(params)
                await this.$userService.syncSession()
                this.$eventBus.$emit('showToast', {
                    title: "Alterações feitas",
                    msg: "Suas informações foram salvas",
                })
            } catch (error) {
                console.error(error)
            } finally {
                this.submitting = false
            }
        },
        setCurrentImageToUpload(key: string) {
            this.currentImagetoUpload = key
        }
    }
})