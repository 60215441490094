import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = ["onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loop, (index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        onClick: ($event: any) => (_ctx.handlePress(index - 1)),
        disabled: _ctx.disabled,
        class: _normalizeClass(["me-1", { 'cursor-pointer': !_ctx.disabled }])
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["fas fa-star", [_ctx.isSolid(index), _ctx.size]])
        }, null, 2)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}