<template>
  <div
    class="d-flex justify-content-center"
    v-if="uploading"
    style="margin-bottom: 1.5rem"
  >
    <div class="shadow rounded">
      <div class="d-flex justify-content-center align-items-center avatar-huge">
        <span
          class="spinner-border spinner-border text-primary"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>
  </div>
  <div
    @click="openModalForFile"
    class="d-flex justify-content-center align-items-center mb-4"
    v-else
  >
    <div
      class="flex-column avatar avatar-huge cursor-pointer border border-4 border-card shadow rounded d-flex align-items-center justify-content-center bg-secondary-soft"
    >
      <template v-if="image">
        <img :src="image" class="avatar-img rounded" />
      </template>
      <template v-else>
        <i class="fa-solid fa-image fa-2xl text-secondary"></i>
      </template>
      <span
        class="position-absolute rounded-circle bg-secondary border border-4 border-card shadow d-flex justify-content-center align-items-center update-icon"
      >
        <i class="fa-solid fa-pen text-white" />
      </span>
    </div>
  </div>
  <ImageCropper
    :modalId="`${inputId}_modal`"
    :accept="'.jpeg,.png,.jpg'"
    :aspectRatio="1 / 1"
    v-on:cropEnd="fileUploaded($event)"
    :inputId="inputId"
    :hiddenInput="true"
    backdrop="false"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ImageUploaderController from "./ImageUploaderController";
export default defineComponent({
  mixins: [ImageUploaderController],
});
</script>

<style scoped>
.avatar-huge {
  width: 22rem;
  height: 22rem;
}
.update-icon {
  bottom: -15px;
  right: -15px;
  width: 2.5rem;
  height: 2.5rem;
}
</style>
