import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "offcanvas-body" }
const _hoisted_2 = { class: "offcanvas-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_TextAreaInput = _resolveComponent("TextAreaInput")!
  const _component_FormButton = _resolveComponent("FormButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TextInput, {
        required: "",
        label: "Formação",
        model: _ctx.v$.form.education,
        placeholder: "Qual sua formação?"
      }, null, 8, ["model"]),
      _createVNode(_component_TextInput, {
        required: "",
        label: "Anos de experiência",
        model: _ctx.v$.form.years_exp,
        placeholder: "Você trabalha há quantos anos na área?"
      }, null, 8, ["model"]),
      _createVNode(_component_TextInput, {
        required: "",
        label: "CRP",
        model: _ctx.v$.form.crp,
        mask: "##/######",
        placeholder: "00/000000"
      }, null, 8, ["model"]),
      _createVNode(_component_TextInput, {
        label: "E-psi",
        model: _ctx.v$.form.epsi,
        placeholder: "Qual número do seu E-psi?"
      }, null, 8, ["model"]),
      _createVNode(_component_TextAreaInput, {
        required: "",
        label: "Resumo do currículo",
        model: _ctx.v$.form.bio,
        placeholder: "Descreva um pouco sobre sua experiencia"
      }, null, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormButton, {
        marginVertical: false,
        form: _ctx.v$.form,
        label: "Concluir",
        loading: _ctx.submitting,
        onOnClick: _ctx.submit,
        disabled: _ctx.v$.invalid
      }, null, 8, ["form", "loading", "onOnClick", "disabled"])
    ])
  ], 64))
}