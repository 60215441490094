import { useMainStore } from "@/store";
import { isDateFuture } from "@/utils/FormValidators";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import moment from "moment";
import { mapState } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            v$: useVuelidate(),
            submitting: false,
            form: {
                date: '',
                time: ''
            },
            link: '',
            linkCopied: false
        }
    },
    computed: {
        ...mapState(useMainStore, ['session'])
    },
    validations() {
        return {
            form: {
                date: {
                    required: helpers.withMessage('Informe a data da sessão', required),
                    isDateFuture: helpers.withMessage('Informe uma data válida', isDateFuture)
                },
                time: {
                    required: helpers.withMessage('Informe o horário da sessão', required),
                    isDateFuture: helpers.withMessage('Informe um horário válido', (value) => {
                        if (!value) return false
                        const date = `${this.form.date} ${value}`
                        return moment(date).isSameOrAfter(moment(), "m")
                    })
                }
            }
        }
    },
    methods: {
        submit() {
            try {
                this.submitting = true
                const uri = `${process.env.VUE_APP_WEB_APP_URL}horario-avulso/${this.form.date} ${this.form.time}/${this.session?.id}`;
                setTimeout(() => {
                    this.link = encodeURI(uri)
                    this.form.date = ''
                    this.form.time = ''
                    setTimeout(() => this.v$.$reset(), 1)
                    this.$eventBus.$emit("showToast", {
                        title: "Link gerado",
                        msg: `Um link com seu horário avulso foi gerado para você poder copiar e compartilhar`,
                    });
                    this.submitting = false
                }, 1000)
            } catch (error: any) {
                console.log(error.message);
            }
        },
        copyLink() {
            if (this.link) {
                this.linkCopied = true
                setTimeout(() => {
                    this.linkCopied = false
                }, 3000);
                navigator.clipboard.writeText(this.link);
            }
        }
    }
})