<template>
  <div class="offcanvas-body">
    <div class="card">
      <div class="card-body">
        <p class="text-paragraph">
          Reunimos algumas dicas que podem melhorar seu perfil e atrair mais
          pacientes.
        </p>

        <div class="d-flex align-items-center justify-content-between">
          <p class="text-subtitle text-success">
            {{ session?.profile_improvement_values?.concludedCount }} concluídas
          </p>
          <p class="text-subtitle">
            {{ session?.profile_improvement_values?.concludedCount }}/{{
              session?.profile_improvement_values?.totalCount
            }}
          </p>
        </div>

        <div class="progress">
          <div
            class="progress-bar bg-success"
            role="progressbar"
            :style="{
              width: `${session?.profile_improvement_values?.percentage}%`,
            }"
          ></div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div
          v-for="(item, index) in improvements"
          :key="index"
          :class="{
            'cursor-pointer': item.enabled,
          }"
          @click="item.enabled ? item.action() : null"
        >
          <div class="d-flex justify-content-between align-items center">
            <p
              class="text-subtitle mb-0"
              :class="{ 'text-muted': item.premium && !item.enabled }"
            >
              <i
                class="fa-solid fa-check-circle text-success"
                v-if="session?.profile_improvement[item.key]"
              ></i>
              <i class="fa-regular fa-check-circle" v-else></i>
              {{ item.title }}
              <span
                v-if="item.premium && !item.enabled"
                class="ms-2 badge bg-info rounded-4"
                >Paiapp +</span
              >
            </p>
            <i class="fa-solid fa-chevron-right ms-auto text-subtitle"></i>
          </div>
          <p
            class="text-paragraph mb-0"
            :class="{ 'text-muted': item.premium && !item.enabled }"
          >
            {{ item.description }}
          </p>
          <hr v-if="index + 1 !== improvements.length" class="my-4" />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <p class="text-header mb-0">Outras sugestões</p>
      </div>
      <div class="card-body">
        <div v-for="(item, index) in suggestions" :key="index">
          <p class="text-subtitle mb-0">
            <i class="fa-solid fa-check-circle"></i>
            {{ item.title }}
          </p>
          <p class="text-paragraph mb-0">
            {{ item.description }}
          </p>
          <hr v-if="index + 1 !== suggestions.length" class="my-4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ImproveYourProfileController from "./ImproveYourProfileController";
export default ImproveYourProfileController;
</script>
