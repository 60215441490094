<template>
  <button
    @click.prevent="clicked()"
    :disabled="loading || disabled"
    class="btn d-flex align-items-center justify-content-center rounded-5"
    :class="[
      {
        'w-100': fullWidth,
        'btn-sm': small,
        'my-3': marginVertical,
      },
      btnColor,
    ]"
  >
    <span
      v-if="loading"
      class="spinner-border spinner-border-sm me-2"
      role="status"
      aria-hidden="true"
    ></span>
    <i :class="leftIcon" v-if="leftIcon" class="me-2 text-action" />
    <!-- TODO: Arrumar o hover quando tiver imagem  -->

    <slot name="leftImg" v-if="$slots.leftImg" />

    <p class="mb-0 text-action">{{ label }}</p>

    <i :class="rightIcon" v-if="rightIcon" class="ms-2 text-action" />

    <slot name="rightImg" v-if="$slots.rightImg" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Button",
  computed: {
    btnColor(): string {
      if (this.outline) {
        return `btn-outline-${this.outlineColor}`;
      } else {
        return `btn-${this.color}`;
      }
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    marginVertical: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "Button Label",
    },
    color: {
      type: String,
      default: "secondary",
    },
    outlineColor: {
      type: String,
      default: "primary",
    },
    leftIcon: {
      type: String,
      default: "",
    },
    rightIcon: {
      type: String,
      default: "",
    },
  },
  methods: {
    clicked() {
      if (this.disabled) return;
      this.$emit("onClick");
    },
  },
});
</script>
