import ListControllerMixin from "@/mixins/ListControllerMixin"
import { defineComponent } from "vue"
import MyPatientItem from "./MyPatientItem.vue"
import { EOffcanvasViews, OffcanvasViewsConfig, navigateCanvas } from "../OffcanvasViewsConfig"

export default defineComponent({
    mixins: [ListControllerMixin],
    data() {
        return {
            single: [] as any[],
            recurrent: [] as any[],
            currentTab: "single",
            tabs: [
                {
                    id: "single",
                    value: "Avulsos",
                    active: true
                },
                {
                    id: "recurrent",
                    value: "Recorrentes",
                    active: false
                },
            ]
        }
    },
    methods: {
        handleTabClicked(tab: any) {
            this.currentTab = tab.id
            this.tabs = this.tabs.map(elem => ({ ...elem, active: elem.id == tab.id }))
            this.items = this.currentTab == "single" ? this.single : this.recurrent
        },
        fetchPromise() {
            return this.$patientsService.list();
        },
        prepareResponse(data: any) {
            this.single = data.single
            this.recurrent = data.recurrent
            return this.currentTab == "single" ? data.single : data.recurrent
        },
        handlePatientClicked(item: any) {
            navigateCanvas(EOffcanvasViews.my_patient_detail, { ...OffcanvasViewsConfig.my_patient_detail, detail_id: item.id }, false)
        },
    },
    components: {
        MyPatientItem
    }
})