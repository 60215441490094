import { toggleOffcanvas } from "@/components/OffcanvasComponent.vue";
import IPhoneCode from "@/interfaces/IPhoneCode";
import { useMainStore } from "@/store";
import { isNameValid } from "@/utils/FormValidators";
import PhoneCodes from "@/utils/PhoneCodes";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        if (this.session) {
            this.form = {
                name: this.session.name,
                country_code: this.session.country_code,
                phone: this.session.phone,
            }
        }
    },
    data() {
        return {
            v$: useVuelidate(),
            phoneCodes: PhoneCodes,
            form: {
                name: '',
                country_code: '',
                phone: '',
            },
            phoneMask: '(##) #####-####',
            phoneCode: PhoneCodes[0] as IPhoneCode,
            submitting: false,
            errorMessage: ''
        }
    },
    computed: {
        session() {
            return useMainStore().session;
        },
    },
    validations() {
        return {
            form: {
                name: {
                    required: helpers.withMessage('Informe um nome válido', required),
                    minLength: helpers.withMessage('Informe um nome válido', minLength(3)),
                    fullName: helpers.withMessage('Informe um nome válido', (value: string) => isNameValid(value)),
                },
                country_code: {
                    required: helpers.withMessage('Informe o DDI', required),
                },
                phone: {
                    required: helpers.withMessage('Informe um telefone válido', required),
                    minLength: helpers.withMessage('Informe um telefone válido', minLength(this.phoneCode?.mask.length)),
                },
            }
        }
    },
    methods: {
        async handleAccountUpdate() {
            try {
                this.submitting = true;
                const params = {
                    ...this.form,
                    phone: this.form.phone.clear()
                };
                await this.$userService.update(params);
                this.$eventBus.$emit("showToast", {
                    title: "Informações alteradas",
                    msg: `Suas informações form alteradas com sucesso`,
                });
                toggleOffcanvas('offcanvas_views')
            } catch (error: any) {
                console.log(error);
                this.errorMessage = error.message
            } finally {
                this.submitting = false;
            }
        },
        countryCodeChanged(value: string) {
            this.form.phone = ''
            const pc = this.phoneCodes.find(elem => elem.dial_code == value)
            if (pc) {
                this.phoneCode = pc
                this.phoneMask = pc?.mask || '##########'
            }
        }
    }
})