import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "offcanvas-body" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingContainer = _resolveComponent("LoadingContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingContainer, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", { innerHTML: _ctx.terms }, null, 8, _hoisted_2)
  ]))
}