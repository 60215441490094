import ISession from "@/interfaces/ISession";
import UserService from "@/services/UserService";
import { useMainStore } from "@/store";
import { checkDateIsValid } from '@/utils/FormValidators';
import { toggleModal } from "@/utils/Helper";
import { timeMask } from "@/utils/Masks";
import { goBackCanvas } from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import useVuelidate from "@vuelidate/core";
import { helpers } from '@vuelidate/validators';
import { vMaska } from "maska";
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        if (this.session) {
            this.initWorkingHours()
        }
    },
    directives: {
        maska: vMaska,
    },
    props: {
        errorMessage: String,
        submitting: Boolean,
        comingFrom: {
            type: String,
            default: 'offcanvas'
        }
    },
    emits: ['handleStepChanged'],
    computed: {
        copyModalTitle(): string {
            return this.timesToCopy ? `Copiar horários de ${this.timesToCopy.title}` : ''
        },
        session(): ISession | null {
            return useMainStore().session;
        }
    },
    data() {
        return {
            step: 5,
            totalSteps: UserService.TOTAL_REGISTER_STEPS,
            timeMask: timeMask,
            v$: useVuelidate(),
            isSubmitting: false,
            form: {
                workingHours: [
                    {
                        weekday: 'monday',
                        title: 'Segunda-feira',
                        hours: [],
                        isAvailable: false
                    },
                    {
                        weekday: 'tuesday',
                        title: 'Terça-feira',
                        hours: [],
                        isAvailable: false
                    },
                    {
                        weekday: 'wednesday',
                        title: 'Quarta-feira',
                        hours: [],
                        isAvailable: false
                    },
                    {
                        weekday: 'thursday',
                        title: 'Quinta-feira',
                        hours: [],
                        isAvailable: false
                    },
                    {
                        weekday: 'friday',
                        title: 'Sexta-feira',
                        hours: [],
                        isAvailable: false
                    },
                    {
                        weekday: 'saturday',
                        title: 'Sábado',
                        hours: [],
                        isAvailable: false
                    },
                    {
                        weekday: 'sunday',
                        title: 'Domingo',
                        hours: [],
                        isAvailable: false
                    },
                ],
            },
            timesToCopy: {
                weekday: '',
                title: '',
                hours: [],
            },
            copyDaysChecked: [] as Array<string>,
        }
    },
    validations() {
        return {
            form: {
                workingHours: {
                    isComplete: helpers.withMessage('Preencha os horários corretamente ou remova-os', (value: Array<any>) => {
                        return !value.some(elem => elem.hours && elem.hours.some((hour: any) => {
                            return !checkDateIsValid(hour?.start_time, hour?.end_time) || !hour?.start_time || !hour?.end_time
                        }))
                    }),
                    // $each: helpers.forEach({
                    //     hours: {
                    //         $each: helpers.forEach({
                    //             start_time: {
                    //                 required: helpers.withMessage('Insira o horário de início', required),
                    //                 minLength: helpers.withMessage('Insira o horário de início', minLength(5)),
                    //             },
                    //             end_time: {
                    //                 required: helpers.withMessage('Insira o horário de encerramento', required),
                    //                 valid_date: helpers.withMessage('Insira o horário de encerramento', (value: any, sibilings: any) => {
                    //                     return checkDateIsValid(sibilings?.start_time, value)
                    //                 })
                    //             },
                    //         })
                    //     }
                    // })
                }
            }
        }
    },
    methods: {
        async stepChanged(forward: boolean) {
            this.$emit('handleStepChanged', { forward, form: this.prepareWorkingHours(this.form.workingHours) })
        },
        async submit() {
            try {
                this.isSubmitting = true
                const values = this.prepareWorkingHours(this.form.workingHours)
                await this.$userService.update(values)

                this.closeOffcanvas()
            } catch (error) {
                console.log(error)
            } finally {
                this.isSubmitting = false
            }
        },
        initWorkingHours() {
            this.form.workingHours = this.form.workingHours.map((workingHour, index, arr) => {
                const hours = this.session?.hours.map((hour: any) => {
                    if (workingHour.weekday === hour.weekday) {
                        if (hour?.start_time && hour?.end_time) {
                            arr[index].isAvailable = true
                            return {
                                start_time: hour?.start_time,
                                end_time: hour?.end_time
                            }
                        }
                    }
                }).filter((elem: any) => elem)
                return { ...workingHour, hours }
            })
        },
        prepareWorkingHours(hours: any) {
            let weekdays: any = [];
            let start_time: any = [];
            let end_time: any = [];
            let actualHours: any = []
            hours.filter((t: any) => t.hours.length).map((weekDay: any) => {
                weekDay.hours.map((wh: any) => {
                    weekdays.push(weekDay.weekday);
                    start_time.push(wh?.start_time);
                    end_time.push(wh?.end_time);
                })
            })
            actualHours = {
                weekdays,
                start_time,
                end_time,
            };
            return actualHours
        },
        addTime(weekIndex: any) {
            let hours: any = this.form.workingHours
            hours[weekIndex].hours.push({
                start_time: '00:00',
                end_time: '00:00',
            })
            hours[weekIndex].isAvailable = true
            this.form.workingHours = hours
        },
        handleCopyTimesClicked(weekIndex: any) {
            this.timesToCopy = this.form.workingHours[weekIndex]
            toggleModal(`schedule_copy_times_${this.comingFrom}`)
        },
        handleChangeAvailability(weekIndex: any) {
            if (this.form.workingHours[weekIndex].isAvailable) {
                this.form.workingHours[weekIndex].hours = []
                this.form.workingHours[weekIndex].isAvailable = false
            } else {
                this.addTime(weekIndex)
            }
        },
        removeTime(weekIndex: any, hourIndex: any) {
            let hours = this.form.workingHours
            hours[weekIndex].hours.splice(hourIndex, 1)
            if (!hours[weekIndex].hours.length) {
                hours[weekIndex].isAvailable = false
            }
            this.form.workingHours = hours
        },
        handleCancelCopy() {
            toggleModal(`schedule_copy_times_${this.comingFrom}`)
        },
        handleCopy() {
            let workingHours = JSON.parse(JSON.stringify(this.form.workingHours))
            workingHours.forEach((elem: any, index: any) => {
                if (this.copyDaysChecked.includes(elem.weekday)) {
                    workingHours[index].hours = [...this.timesToCopy.hours]
                    workingHours[index].isAvailable = true
                }
            });
            this.form.workingHours = workingHours
            this.copyDaysChecked = []
            this.handleCancelCopy()
        },
        closeOffcanvas() {
            goBackCanvas();
        },
    },
})