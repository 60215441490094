import { toggleOffcanvas } from "@/components/OffcanvasComponent.vue";
import { useMainStore } from "@/store";
import { mapActions, mapState } from "pinia";
import { Component, defineComponent } from "vue";
import { OffcanvasViewKey, Views } from "./OffcanvasViewsConfig";

export default defineComponent({
    computed: {
        ...mapState(useMainStore, ['offcanvas_config', 'offcanvas_history']),
        View(): Component {
            return Views[this.offcanvas_config?.path as OffcanvasViewKey] || null; // Seleciona o componente correspondente
        },
        viewProps(): any {
            return this.offcanvas_config?.props || null; // Seleciona o componente correspondente
        },
        title(): string {
            return this.offcanvas_config?.title || '';
        }
    },
    methods: {
        ...mapActions(useMainStore, ['popOffcanvasHistory', 'clearOffcanvasConfig']),
        offcanvasClosed() {
            if (this.offcanvas_history.length == 1) {
                toggleOffcanvas("offcanvas_views");
                setTimeout(() => {
                    this.clearOffcanvasConfig()
                    this.popOffcanvasHistory()
                }, 200);
            } else {
                this.popOffcanvasHistory()
            }
        }
    }
});