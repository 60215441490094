<template>
  <OffcanvasComponent
    :id="`offcanvas_views`"
    :title="title"
    @offcanvasClosed="offcanvasClosed"
  >
    <component :is="View" v-bind="viewProps" />
  </OffcanvasComponent>
</template>

<script lang="ts">
import OffcanvasViewsController from "./OffcanvasViewsController";
export default OffcanvasViewsController;
</script>
