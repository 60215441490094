<template>
  <div>
    <div class="card">
      <div class="card-header">
        <p class="text-header mb-0">Frequencia</p>
      </div>
      <div class="card-body">
        <div
          class="d-flex align-items-center justify-content-between py-4 border-bottom"
        >
          <p class="text-paragraph mb-0">Período</p>
          <p class="text-subtitle mb-0">{{ recurrency.frequencyLabel }}</p>
        </div>

        <div
          v-if="recurrency?.nextAppointmentDate"
          class="d-flex align-items-center justify-content-between py-4 border-bottom"
        >
          <p class="text-paragraph mb-0">Próxima sessão</p>
          <p class="text-subtitle mb-0">{{ recurrency.nextAppointmentDate }}</p>
        </div>

        <div class="d-flex align-items-center justify-content-between py-4">
          <p class="text-paragraph mb-0">
            Está sendo reservado sessões
            <span class="text-subtitle"> {{ recurrency?.frequencyText }}</span>
          </p>
        </div>

        <div class="rounded-4 border p-4 d-flex align-items-center">
          <img src="@/assets/img/file_money.svg" class="me-4" />
          <p class="text-paragraph mb-0">
            Os pagamentos futuros serão efetuados 72h antes de começar a
            sessões.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["recurrency"],
});
</script>

<style scoped></style>
