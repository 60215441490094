<template>
  <div class="card">
    <div class="card-body">
      <div
        class="d-flex justify-content-between align-items-center"
        :class="{ 'mb-4': hasChat }"
      >
        <div>
          <div class="avatar avatar-lg me-3">
            <i
              v-if="user?.deleted_at"
              class="fa-solid fa-question d-flex justify-content-center align-items-center avatar-img rounded-circle bg-light text-primary"
            />
            <img
              v-else
              :src="user?.profileImage"
              alt="User Profile Picture"
              class="avatar-img rounded-circle"
            />
          </div>
          <span class="text-subtitle">{{
            user?.deleted_at ? "Paciente removido" : user?.name
          }}</span>
        </div>
      </div>

      <Button
        v-if="hasChat"
        label="Falar com paciente"
        fullWidth
        outline
        @onClick="handleChat"
        leftIcon="fa-regular fa-comment-dots"
      >
        <!-- <template v-slot:leftImg>
          <img src="@/assets/img/chat.svg" class="me-2" />
        </template> -->
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import { toggleModal } from "@/components/ModalComponent.vue";
import { useMainStore } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["user", "chat"],
  data() {
    return {
      hasChat:
        this.chat && this.chat?.status === "active" && !this.user?.deleted_at,
    };
  },
  methods: {
    handleChat() {
      const store = useMainStore();

      const chat_room_config = {
        id: this.chat.id,
        date: this.chat.created_at,
        user_name: this.user.name,
        mode: "modal",
      };

      toggleModal("chat_room");
      store.saveChatRoomConfig(chat_room_config);
    },
  },
});
</script>
