<template>
  <div>
    <ModalComponent
      title="Tem certeza que deseja deletar sua conta?"
      id="account_delete"
      size="modal-lg"
    >
      <template v-slot:body>
        <!-- <p class="text-header">Tem certeza que deseja deletar sua conta?</p> -->
        <p class="text-paragraph">
          Ao deletar sua conta, você irá perder acesso ao aplicativo e todas as
          suas configurações. Deseja realmente deletar sua conta?
        </p>
      </template>
      <template v-slot:footer>
        <button class="btn btn-del" @click="handleAccountDelete">
          <span
            v-if="submitting"
            class="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else> Sim, deletar conta </span>
        </button>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import AccountDeleteController from "./AccountDeleteController.ts";
export default AccountDeleteController;
</script>
