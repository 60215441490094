<template>
  <div class="offcanvas-body">
    <LoadingContainer v-if="loading" />
    <template v-else>
      <div v-for="(rating, index) in ratings" :key="index">
        <div class="card rounded-4">
          <div class="card-body">
            <div class="d-flex align-items-center mb-4">
              <img
                :src="rating.formatted_profile_image"
                alt=""
                height="40"
                width="40"
                class="rounded-circle me-2"
              />
              <div>
                <p class="mb-1 text-subtitle">
                  {{ rating.user.name.split(" ")[0] }}
                </p>
                <p class="text-label mb-0">
                  {{ rating.formatted_date }}
                </p>
              </div>
              <div class="ms-auto">
                <p
                  class="text-subtitle text-warning"
                  v-if="rating?.review_requested && !rating?.reviewed_by"
                >
                  Em análise
                </p>

                <p
                  v-else-if="!rating?.reviewed_by"
                  class="text-subtitle text-muted cursor-pointer"
                  @click="handleReport(rating)"
                >
                  Reportar
                </p>
              </div>
            </div>
            <p class="text-paragraph text-wrap">{{ rating.testimonial }}</p>
          </div>
        </div>
      </div>
    </template>
    <ModalComponent
      title="Deseja reportar esta avaliação?"
      id="report_rating_modal"
      backdrop="false"
    >
      <template v-slot:body>
        <p class="text-paragraph">
          Esta avaliação será analisada, e se for inadequada será removida.
        </p>
        <TextAreaInput
          class="mb-0"
          :model="v$.form.reason_report"
          placeholder="Qual motivo deseja reportar?"
          :maxLength="200"
        />
      </template>
      <template v-slot:footer>
        <FormButton
          color="danger"
          fullWidth
          :form="v$.form"
          :loading="submitting"
          @onClick="handleConfirmReport"
          label="Reportar"
          :disabled="v$.$invalid"
        />
      </template>
    </ModalComponent>
  </div>
</template>

<script lang="ts">
import RatingsController from "./RatingsController";
export default RatingsController;
</script>
