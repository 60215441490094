<template>
  <div class="card">
    <div class="card-header">
      <p class="text-header mb-0">
        <i class="fa-solid fa-link me-1"></i>
        Link da videochamada
      </p>
    </div>
    <div class="card-body">
      <p class="text-paragraph">
        Se você precisar convidar alguém de fora do Psiapp para a sua sessão,
        basta compartilhar o link abaixo
      </p>
      <div class="d-flex align-items-center">
        <p
          class="text-subtitle me-3 mb-0 cursor-pointer"
          @click="handleCallLink"
        >
          {{ callLink }}
        </p>

        <button v-if="copied" class="btn btn-outline-success" disabled>
          <i class="fa-solid fa-check-circle"></i>
        </button>
        <button v-else class="btn btn-custom" @click="handleCopyCallLink">
          <i class="fa-regular fa-copy"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["callLink"],
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    handleCallLink() {
      window.open(this.callLink, "_blank");
    },
    handleCopyCallLink() {
      navigator.clipboard.writeText(this.callLink);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
  },
});
</script>

<style scoped></style>
