<template>
  <!-- TODO: TENTAR PASSAR ESSE BOTÃO PRO HEADER DO OFFCANVAS -->
  <!-- <button class="btn btn-sm" @click="handleSelectAll">
    {{ allSelected ? "Desmarcar todos" : "Marcar todos" }}
  </button> -->
  <div class="offcanvas-body">
    <LoadingContainer v-if="loading" />
    <CheckboxInput :options="approaches" :model="v$.form.approaches" />
  </div>
  <div class="offcanvas-footer">
    <FormButton
      :marginVertical="false"
      :form="v$.form"
      label="Concluir"
      :loading="submitting"
      @onClick="submit"
      :disabled="v$.invalid"
    />
  </div>
</template>

<script>
import ApproachesController from "./ApproachesController";
export default ApproachesController;
</script>
