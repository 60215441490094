<template>
  <ModalComponent
    id="appointment_rating_pending"
    title="Como foi sua sessão?"
    @modalClosed="handleFinish"
  >
    <template v-slot:body>
      <p class="text-header">Avalie a Qualidade da ligação</p>
      <p class="text-paragraph">
        De uma nota abaixo baseado na qualidade do áudio e vídeo que durante a
        sessão.
      </p>
      <VotingStars
        size="fa-xl"
        @onClick="(rating: number) => setRating(rating)"
      />

      <hr class="mt-4" />

      <p class="text-header">Teve algum problema?</p>
      <p class="text-paragraph">
        Caso teve algum problema ou deseja relatar alguma ocorrência, descreva
        abaixo:
      </p>
      <TextAreaInput :model="v$.form.description" />
    </template>
    <template v-slot:footer>
      <Button
        label="Enviar avaliação"
        @onClick="submitRating"
        :loading="submitting"
      />
    </template>
  </ModalComponent>
</template>

<script lang="ts">
import AppointmentRatingController from "./AppointmentRatingController";
export default AppointmentRatingController;
</script>
