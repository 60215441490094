<template>
  <div class="offcanvas-body">
    <LoadingContainer v-if="loading" />
    <CheckboxInput v-else :options="languages" :model="v$.form.languages" />
  </div>
  <div class="offcanvas-footer">
    <FormButton
      :marginVertical="false"
      :form="v$.form"
      label="Concluir"
      :loading="submitting"
      @onClick="submit"
      :disabled="v$.invalid"
    />
  </div>
</template>

<script lang="ts">
import LanguagesController from "./LanguagesController";
export default LanguagesController;
</script>
