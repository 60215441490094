import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "spinner-border spinner-border-sm me-2",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "mb-0 text-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.clicked()), ["prevent"])),
    disabled: _ctx.loading || _ctx.disabled,
    class: _normalizeClass(["btn d-flex align-items-center justify-content-center rounded-5", [
      {
        'w-100': _ctx.fullWidth,
        'btn-sm': _ctx.small,
        'my-3': _ctx.marginVertical,
      },
      _ctx.btnColor,
    ]])
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.leftIcon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 1,
          class: _normalizeClass([_ctx.leftIcon, "me-2 text-action"])
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.$slots.leftImg)
      ? _renderSlot(_ctx.$slots, "leftImg", { key: 2 })
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.label), 1),
    (_ctx.rightIcon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 3,
          class: _normalizeClass([_ctx.rightIcon, "ms-2 text-action"])
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.$slots.rightImg)
      ? _renderSlot(_ctx.$slots, "rightImg", { key: 4 })
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}