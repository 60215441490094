import { useMainStore } from "@/store";
import Profile from "@/views/Appointments/AppointmentDetail/Profile.vue";
import { defineComponent } from "vue";
import Section from "@/views/Home/Section.vue";
import { EOffcanvasViews, OffcanvasViewsConfig, navigateCanvas } from "../OffcanvasViewsConfig";

export default defineComponent({
    mounted() {
        this.patientId = useMainStore().offcanvas_config?.detail_id || 0
        this.loading = true
        this.getPatient()
    },
    data() {
        return {
            patientId: 0,
            patient: {} as any,
            loading: false
        }
    },
    methods: {
        async getPatient() {
            try {
                const res = await this.$patientsService.show(this.patientId);
                this.patient = res.data
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        handleAppointment(id: number) {
            navigateCanvas(EOffcanvasViews.appointment_detail, { ...OffcanvasViewsConfig.appointment_detail, detail_id: id }, false)
            // setOffcanvasConfig({ ...OffcanvasViewsConfig.appointmentDetail, detail_id: id, coming_from_detail_id: this.patientId }, false, EOffcanvasViews.myPatientDetail)
        },
        handleRecurrency(id: number) {
            navigateCanvas(EOffcanvasViews.appointment_recurrency, { ...OffcanvasViewsConfig.appointment_recurrency, detail_id: id }, false)
            // setOffcanvasConfig({ ...OffcanvasViewsConfig.appointmentRecurrency, detail_id: id, coming_from_detail_id: this.patientId }, false, EOffcanvasViews.myPatientDetail)
        }
    },
    components: {
        Profile,
        Section
    }
})