<template>
  <ModalComponent
    id="chat_room"
    :title="userName"
    :fullHeight="true"
    @modalClosed="updateScreen"
  >
    <template v-slot:content>
      <ChatRoom type="modal" />
    </template>
  </ModalComponent>
</template>

<script lang="ts">
import ChatService from "@/services/ChatService";
import UserService from "@/services/UserService";
import WebSocketService from "@/services/WebSocketService";
import { EventBus } from "@/utils/EventBus";
import { computed, defineComponent } from "vue";
import ChatRoom from "@/views/Chat/ChatRoom/ChatRoom.vue";
import { useMainStore } from "@/store";

export default defineComponent({
  setup() {
    const store = useMainStore();
    const userName = computed(() => store?.chat_room_config?.user_name);
    const id = computed(() => store?.chat_room_config?.id);
    const date = computed(() => store?.chat_room_config?.date);

    const updateScreen = async () => {
      const chatId = id.value;
      const chatDate = date.value;
      store.clearChatRoomConfig();
      await new UserService().syncSession();
      if (chatId && chatDate) {
        new WebSocketService().unsubscribe(`appointment_chat.${chatId}`);
        await new ChatService().getMessages(chatId, chatDate, []);
      }
      EventBus.$emit("UPDATE_CHATS");
    };

    return {
      userName,
      updateScreen,
    };
  },
  components: {
    ChatRoom,
  },
});
</script>

<style scoped></style>
