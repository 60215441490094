<template>
  <div class="card">
    <div class="card-header">
      <p class="text-header mb-0">Notificações</p>
    </div>
    <div class="card-body">
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <EmptyState
          v-if="!items.length"
          :totalItemsRegistered="totalItemsRegistered"
        >
          <template v-slot:noItemsRegistered>
            <span class="fa-2x fas fa-folder-open text-muted mb-2"></span>
            <p class="text-paragraph">
              Você ainda não possui nenhuma notificação
            </p>
          </template>
        </EmptyState>

        <div
          v-for="(item, index) in items"
          :key="index"
          class="d-flex py-4"
          :class="{
            'border-bottom': index !== items.length - 1,
            'pt-2': index === 0,
          }"
        >
          <div>
            <p class="text-subtitle mb-1" :class="{ 'text-muted': item.read }">
              {{ item.title }}
            </p>
            <p class="text-paragraph mb-1" :class="{ 'text-muted': item.read }">
              {{ item.description }}
            </p>
            <p class="text-label mb-0" :class="{ 'text-muted': item.read }">
              {{ item.formatted_date }}
            </p>
          </div>
          <i
            class="fa-solid fa-circle ms-auto text-secondary"
            v-if="!item.read"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ListControllerMixin from "@/mixins/ListControllerMixin";
import { getContentDate } from "@/utils/Helper";
import moment from "moment";
import { defineComponent } from "vue";

interface INotification {
  id: string;
  title: string;
  description: string;
  created_at: string;
  read: boolean;
  formatted_date: string;
}

export default defineComponent({
  mixins: [ListControllerMixin],
  data() {
    return {
      items: [] as INotification[],
    };
  },
  methods: {
    async fetchPromise() {
      return this.$notificationsService.list({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
    prepareResponse(data: any): INotification[] {
      return data.map((item: any) => {
        return {
          ...item,
          formatted_date: `${getContentDate(item.created_at)} • ${moment(
            item.created_at
          ).format("hh:mm")}`,
        };
      });
    },
  },
});
</script>

<style scoped></style>
