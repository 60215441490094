<template>
  <div v-if="type === 'screen'" class="card">
    <div class="card-header">
      <p class="text-subtitle mb-0">{{ user_name }}</p>
    </div>
    <div
      class="card-body overflow-scroll py-0"
      id="chat_card_body"
      style="height: calc(100vh - 442px)"
    >
      <LoadingContainer v-if="loadingChat" />
      <ChatRoomContent
        v-else
        :formattedDate="formatted_date"
        :sectionItems="sectionItems"
        :defaultMessage="defaultMessage"
      />
    </div>
    <div class="card-footer">
      <ChatRoomInput @handleSend="handleSend" />
    </div>
  </div>

  <template v-if="type === 'modal'">
    <div class="modal-body py-0 d-flex flex-column" id="chat_modal_body">
      <LoadingContainer v-if="loadingChat" />
      <ChatRoomContent
        v-else
        :formattedDate="formatted_date"
        :sectionItems="sectionItems"
        :defaultMessage="defaultMessage"
      />
    </div>
    <div class="modal-footer">
      <ChatRoomInput @handleSend="handleSend" />
    </div>
  </template>
</template>

<script lang="ts">
import ChatRoomController from "./ChatRoomController";
export default ChatRoomController;
</script>
