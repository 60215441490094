import PaymentService from '@/services/PaymentService';
import SubscriptionService from '@/services/SubscriptionService';
import UserService from '@/services/UserService';
import { useMainStore } from '@/store';
import { EventBus } from '@/utils/EventBus';
import { EOffcanvasViews, goBackCanvas, navigateCanvas } from '@/views/OffcanvasViews/OffcanvasViewsConfig';
import { loadStripe } from '@stripe/stripe-js';
import { defineComponent, onBeforeMount, ref } from 'vue';
import { StripeElement, StripeElements } from 'vue-stripe-js';

export default defineComponent({
    setup() {
        const stripeKey = ref(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
        const instanceOptions = ref({
            // https://stripe.com/docs/js/initializing#init_stripe_js-options
        })
        const elementsOptions = ref({
            // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
        })
        const stripeLoaded = ref(false)
        const elms = ref()
        const cardNumberOptions = ref({})
        const cardExpiryOptions = ref({})
        const cardCvcOptions = ref({})
        const cardNumber = ref()
        const cardExpiry = ref()
        const cardCvc = ref()
        let submitting = ref(false)

        const store = useMainStore()
        const session = store.session

        onBeforeMount(() => {
            const stripePromise = loadStripe(stripeKey.value)
            stripePromise.then(() => {
                stripeLoaded.value = true
            })
        })

        const handleAddCreditCard = () => {
            submitting.value = true
            // Get stripe element
            const cn = cardNumber.value.stripeElement
            const ce = cardExpiry.value.stripeElement
            const cc = cardCvc.value.stripeElement

            // Access instance methods, e.g. createToken()
            elms.value.instance.createToken({ ...cn, ...ce, ...cc })
                .then(async (result: any) => {
                    // Handle result.error or result.token
                    const paymentService = new PaymentService()
                    if (result.error) {
                        alert(paymentService.getCardErrorMessage(result.error.stripeErrorCode))
                    }
                    await paymentService.createCreditCard(result.token.id)
                    paymentService.setFavoriteCard({
                        card_id: result.token.card.id,
                        last_digits: result.token.card.last4
                    })
                    EventBus.$emit('getCreditCards')

                    if (session?.subscription) {
                        await new SubscriptionService().update(session?.subscription?.id, {
                            card_id: result.token.card.id,
                        });
                        new UserService().syncSession();
                    }
                    EventBus.$emit('refreshMyPlan')

                    goBackCanvas()
                })
                .catch((error: any) => {
                    console.log(error)
                    alert(error?.message)
                })
                .finally(() => {
                    submitting.value = false
                })
        }

        return {
            stripeKey,
            stripeLoaded,
            instanceOptions,
            elementsOptions,
            elms,
            handleAddCreditCard,
            cardNumberOptions,
            cardExpiryOptions,
            cardCvcOptions,
            cardNumber,
            cardExpiry,
            cardCvc,
            submitting
        }
    },
    components: {
        StripeElements,
        StripeElement,
    },
})