<template>
  <div class="card">
    <div class="card-header">
      <p class="text-header mb-0">Conta bancária</p>

      <p
        v-if="bankAccount"
        class="text-subtitle cursor-pointer mb-0"
        :class="{ 'text-secondary': !editing, 'text-danger': editing }"
        @click="handleEdit"
      >
        {{ editing ? "Cancelar alteração" : "Alterar conta bancária" }}
      </p>
    </div>
    <div class="card-body">
      <LoadingContainer v-if="loading" />
      <template v-else>
        <div class="row">
          <template v-if="connectAccount">
            <div class="col-12">
              <TextInput
                :readOnly="true"
                label="Tipo de conta"
                :value="connectAccount?.business_type_name"
              />
            </div>
            <div class="col-12">
              <TextInput
                :readOnly="true"
                label="Nome do titular"
                :value="connectAccount?.legal_name"
              />
            </div>
          </template>
          <div class="col-12">
            <div class="card bg-warning-soft" v-if="editing">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <i class="fa-solid fa-circle-exclamation me-2 text-warning" />
                  <p class="mb-0 text-subtitle">Importante</p>
                </div>
                <p class="text-paragraph">
                  Só é aceito contas correntes. Não aceitamos cadastro de contas
                  poupança ou salário.
                </p>
              </div>
            </div>
          </div>

          <div class="col-12">
            <SelectInput
              v-if="editing"
              required
              :model="v$?.form?.bank_code"
              :options="banks"
              label="Banco"
              :errorMsg="v$.form?.bank_code.required?.$message"
            />
            <TextInput
              v-else
              :readOnly="true"
              label="Banco"
              :value="bankAccount?.bank_name"
            />
          </div>

          <div class="col-12 col-lg-8">
            <TextInput
              :readOnly="!editing"
              label="Agência"
              required
              :model="v$?.form?.agencia"
              :value="bankAccount?.agencia"
              type="text"
              placeholder="Qual a agência?"
              :errorMsg="v$.form?.agencia.required?.$message"
            />
          </div>

          <div class="col-12 col-lg-4">
            <TextInput
              :readOnly="!editing"
              label="Dígito da agência"
              :model="v$.form?.agencia_dv"
              :value="bankAccount?.agencia_dv"
              type="text"
              placeholder="Dígito"
            />
          </div>
          <div class="col-12 col-lg-8">
            <TextInput
              :readOnly="!editing"
              label="Conta"
              required
              :model="v$.form?.conta"
              :value="bankAccount?.conta"
              type="text"
              placeholder="Qual a conta?"
              :errorMsg="v$.form?.conta.required?.$message"
            />
          </div>
          <div class="col-12 col-lg-4">
            <TextInput
              :readOnly="!editing"
              label="Dígito da conta"
              :model="v$.form?.conta_dv"
              :value="bankAccount?.conta_dv"
              type="text"
              placeholder="Dígito"
            />
          </div>
          <div class="col-12" v-if="editing">
            <FormButton
              label="Concluir"
              :loading="submitting"
              :disabled="v$.$invalid"
              class="mb-3"
              @onClick="handleUpdateBankAccount"
              :form="v$.form"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
  <ModalComponent id="update_bank_account_warning" title="Importante">
    <template v-slot:body>
      <p class="text-paragraph">
        {{ warning }}
      </p>
    </template>
    <template v-slot:footer>
      <button class="btn btn-secondary" @click="handleConfirmEdit">
        Continuar
      </button>
    </template>
  </ModalComponent>
</template>

<script lang="ts">
import ModalComponent from "@/components/ModalComponent.vue";
import { useMainStore } from "@/store";
import { toggleModal } from "@/utils/Helper";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { mapState } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  computed: {
    ...mapState(useMainStore, ["session"]),
  },
  mounted() {
    this.loading = true;
    this.updateSession();
    this.getBanks();
    this.setupForm();
  },
  data() {
    return {
      loading: true,
      submitting: false,
      banks: [],
      bankAccount: {} as any,
      connectAccount: {} as any,
      editing: false,
      warning: "",
      form: {
        agencia: "",
        conta: "",
        bank_code: "",
        agencia_dv: "",
        conta_dv: "",
        // document_number: "",
      },
    };
  },
  validations() {
    return {
      form: {
        agencia: {
          required: helpers.withMessage("Informe a agência", required),
        },
        conta: {
          required: helpers.withMessage("Informe a conta", required),
        },
        bank_code: {
          required: helpers.withMessage("", required),
        },

        agencia_dv: {},
        conta_dv: {},
      },
    };
  },
  methods: {
    setupForm() {
      this.warning = `O tipo da sua conta é de pessoa ${
        this.session?.connect_account.business_type == "individual"
          ? "física"
          : "jurídica"
      }, você só pode alterar sua conta bancária para outra conta de pessoa ${
        this.session?.connect_account.business_type == "individual"
          ? "física"
          : "jurídica"
      }.`;

      this.connectAccount = this.session?.connect_account;
      this.bankAccount = this.session?.bank_account;
      this.editing = !this.bankAccount;

      this.form = {
        agencia: this.session?.bank_account?.agencia || "",
        conta: this.session?.bank_account?.conta || "",
        bank_code: this.session?.bank_account?.bank_code || "",
        agencia_dv: this.session?.bank_account?.agencia_dv || "",
        conta_dv: this.session?.bank_account?.conta_dv || "",
        // document_number: this.session?.bank_account?.document_number || "",
      };
    },
    async handleUpdateBankAccount() {
      try {
        this.submitting = true;
        await this.$userService.updateMyBank(this.form);
        await this.$userService.syncSession();
        this.handleEdit();
      } catch (error) {
        console.log(error);
      } finally {
        this.submitting = false;
      }
    },
    async getBanks() {
      try {
        const res = await this.$userService.getBanks();
        this.banks = res.data.map((elem: any) => ({
          ...elem,
          id: elem.code,
          title: elem.name,
        }));
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async updateSession() {
      try {
        await this.$userService.syncSession();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleEdit() {
      if (this.editing || !this.bankAccount) {
        this.editing = !this.editing;
        this.v$.$reset();
        this.setupForm();
      } else {
        toggleModal("update_bank_account_warning");
      }
    },
    handleConfirmEdit() {
      toggleModal("update_bank_account_warning");
      this.editing = !this.editing;
    },
  },
});
</script>

<style scoped></style>
