import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatRoom = _resolveComponent("ChatRoom")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createBlock(_component_ModalComponent, {
    id: "chat_room",
    title: _ctx.userName,
    fullHeight: true,
    onModalClosed: _ctx.updateScreen
  }, {
    content: _withCtx(() => [
      _createVNode(_component_ChatRoom, { type: "modal" })
    ]),
    _: 1
  }, 8, ["title", "onModalClosed"]))
}