import { defineComponent } from "vue";
import { OffcanvasViewsConfig, navigateCanvas } from "../OffcanvasViewsConfig";
import { EOffcanvasViews } from './../OffcanvasViewsConfig';
import { toggleModal } from "@/components/ModalComponent.vue";

export default defineComponent({
    data() {
        return {
            items: [
                {
                    value: "Imagem de perfil",
                    action: () => navigateCanvas(EOffcanvasViews.profile_image, null, false),
                    icon: "fa-solid fa-image",
                    enabled: true,
                },
                {
                    value: "Depoimentos",
                    action: () => navigateCanvas(EOffcanvasViews.ratings, null, false),
                    icon: "fa-solid fa-star",
                    enabled: true,
                },
                {
                    value: "Idiomas",
                    action: () => navigateCanvas(EOffcanvasViews.languages, null, false),
                    icon: "fa-solid fa-earth",
                    enabled: true,
                },
                {
                    value: "Vídeo de introdução",
                    action: (item: any) => {
                        if (item.enabled) {
                            navigateCanvas(EOffcanvasViews.intro_video, null, false)
                        } else {
                            toggleModal('subscription_modal')
                        }
                    },
                    icon: "fa-brands fa-youtube",
                    enabled: this.$userService.isPremium(),
                },
                {
                    value: "Valor da sessão",
                    action: () => navigateCanvas(EOffcanvasViews.appointment_price, null, false),
                    icon: "fa-solid fa-dollar",
                    enabled: true,
                },
                {
                    value: "Informações de formação",
                    action: () => navigateCanvas(EOffcanvasViews.about, null, false),
                    icon: "fa-solid fa-image-portrait",
                    enabled: true,
                },
                {
                    value: "Fotos do CRP",
                    action: () => navigateCanvas(EOffcanvasViews.crp, null, false),
                    icon: "fa-solid fa-id-card",
                    enabled: true,
                },
                {
                    value: "Especialidades",
                    action: () => navigateCanvas(EOffcanvasViews.specialties, null, false),
                    icon: "fa-solid fa-stethoscope",
                    enabled: true,
                },
                {
                    value: "Abordagens",
                    action: () => navigateCanvas(EOffcanvasViews.approaches, null, false),
                    icon: "fa-solid fa-tasks",
                    enabled: true,
                },
                {
                    value: "Disponibilidade de horários",
                    action: () => navigateCanvas(EOffcanvasViews.schedule_info, null, false),
                    icon: "fa-solid fa-clock",
                    enabled: true,
                },
                {
                    value: "Gênero",
                    action: () => navigateCanvas(EOffcanvasViews.gender, null, false),
                    icon: "fa-solid fa-venus-mars",
                    enabled: true,
                },
            ]

        }
    }
})