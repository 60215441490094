import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "offcanvas-body" }
const _hoisted_2 = { class: "h-100 d-flex flex-column justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioInput = _resolveComponent("RadioInput")!
  const _component_FormButton = _resolveComponent("FormButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RadioInput, {
        options: _ctx.options,
        model: _ctx.v$.form.gender
      }, null, 8, ["options", "model"]),
      _createVNode(_component_FormButton, {
        form: _ctx.v$.form,
        label: "Concluir",
        loading: _ctx.submitting,
        onOnClick: _ctx.submit,
        disabled: _ctx.v$.invalid
      }, null, 8, ["form", "loading", "onOnClick", "disabled"])
    ])
  ]))
}