<template>
  <div
    class="d-flex align-items-center p-4 border rounded-4 mb-4 cursor-pointer"
    @click="$emit('handlePatientClicked', item)"
  >
    <img
      :src="item.profile_image_formatted"
      height="50"
      width="50"
      class="me-3"
    />

    <div>
      <p class="text-subtitle mb-0">{{ item.name }}</p>
      <p class="text-paragraph mb-0">
        {{
          currentTab == "single"
            ? item.valid_appointments_count_formatted
            : item.latest_recurrency_frequency
        }}
      </p>
    </div>
    <i class="fa-solid fa-chevron-right ms-auto text-primary fa-xl"></i>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["item", "currentTab"],
  emits: ["handlePatientClicked"],
});
</script>

<style scoped></style>
