<template>
  <div class="offcanvas-body">
    <p class="text-paragraph">
      Você pode criar gerar códigos para valores personalizados.
    </p>
    <TextInput
      required
      label="Código"
      :model="v$.form.code"
      type="text"
      :placeholder="`${session?.name.split(' ')[0].toUpperCase()}10`"
      :errorMsg="v$.form.code.$errors.find((e) => e)?.$message"
      @input="(event) => (form.code = event.target.value.toUpperCase())"
    />
    <TextInput
      required
      label="Desconto do código"
      :model="v$.form.percent_off"
      type="text"
      placeholder="10%"
      :errorMsg="v$.form.percent_off.$errors.find((e) => e)?.$message"
      @input="handlePercentOff"
      :maxLength="3"
    />

    <div
      class="d-flex align-items-center justify-content-between py-4 border-bottom"
    >
      <p class="text-paragraph text-muted mb-0">Valor atual</p>
      <p class="text-paragraph mb-0">
        {{ currentPrice }}
      </p>
    </div>
    <div
      class="d-flex align-items-center justify-content-between py-4 border-bottom"
    >
      <p class="text-paragraph text-muted mb-0">Desconto</p>
      <p class="text-paragraph mb-0">
        {{ discount }}
      </p>
    </div>
    <div
      class="d-flex align-items-center justify-content-between py-4 border-bottom"
    >
      <p class="text-paragraph text-muted mb-0">Valor final</p>
      <p class="text-paragraph mb-0">
        {{ newPrice }}
      </p>
    </div>

    <div class="border-bottom py-4">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <p class="text-subtitle mb-0">Código terá data de validade?</p>
        <SwitchInput
          :model="v$.form.has_redemption"
          id="hasRedemption"
          color="secondary"
          @handleChange="handleHasRedemption"
        />
      </div>
      <p class="text-paragraph text-muted">
        Quando esta opção estiver ativa, seu código só funcionará até a data
        informada.
      </p>
      <TextInput
        v-if="form.has_redemption"
        required
        :model="v$.form.redeem_by"
        type="text"
        :placeholder="redeemByPlaceholder"
        :errorMsg="v$.form.redeem_by.$errors.find((e) => e)?.$message"
        class="mb-0 pb-0"
        :mask="'##/##/####'"
      />
    </div>

    <div class="border-bottom py-4 mb-4">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <p class="text-subtitle mb-0">Limite de uso por paciente?</p>
        <SwitchInput
          :model="v$.form.has_limit"
          id="hasLimit"
          color="secondary"
          @handleChange="handleHasLimit"
        />
      </div>
      <p class="text-paragraph text-muted">
        Quando esta opção estiver ativa, este código só poderá ser utilizado por
        um mesmo paciente a quantidade de vezes informado abaixo.
      </p>
      <TextInput
        v-if="form.has_limit"
        required
        :model="v$.form.max_redemptions_customers"
        placeholder="1x"
        :errorMsg="
          v$.form.max_redemptions_customers.$errors.find((e) => e)?.$message
        "
        class="mb-0 pb-0"
      />
    </div>

    <div class="border p-4 rounded-4 d-flex mb-4">
      <img src="@/assets/img/warning_sign.svg" alt="" class="me-4" />
      <p class="text-paragraph mb-0">
        Lembramos que assim que um código é criado,
        <span class="text-subtitle">não será possível editar</span> as
        informações posteriormente.
      </p>
    </div>

    <FormButton
      label="Criar código"
      :loading="submitting"
      :disabled="v$.$invalid"
      class="mb-3"
      @onClick="handleSubmit"
      :form="v$.form"
    />
  </div>
</template>

<script>
import AddCouponController from "./AddCouponController";
export default AddCouponController;
</script>
