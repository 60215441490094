<template>
  <div>
    <button
      hidden
      :id="`${id}_offcanvas_button`"
      type="button"
      data-bs-toggle="offcanvas"
      :data-bs-target="`#${id}`"
      aria-controls="staticBackdrop"
    ></button>
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      :id="id"
      aria-labelledby="staticBackdropLabel"
      data-bs-backdrop="static"
    >
      <div class="offcanvas-header justify-content-center">
        <i
          class="fa-solid fa-chevron-circle-left display-5 text-primary25 cursor-pointer position-absolute"
          style="left: 20px"
          @click="offcanvasClosed"
        />

        <p class="offcanvas-title text-pagesubtitle" id="staticBackdropLabel">
          {{ title }}
        </p>

        <!-- <i
          class="fa-solid fa-times display-5 text-primary25 cursor-pointer position-absolute"
          style="right: 20px"
          @click="closeOffCanvas"
        /> -->
      </div>

      <slot />

      <div class="offcanvas-body" v-if="$slots.body">
        <slot name="body"></slot>
      </div>
      <div class="offcanvas-footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { closeOffCanvas } from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Title",
    },
    id: {
      type: String,
      default: "",
    },
  },
  emits: ["offcanvasClosed"],
  data() {
    return {
      closeOffCanvas: closeOffCanvas,
    };
  },
  methods: {
    offcanvasClosed() {
      this.$emit("offcanvasClosed");
    },
  },
});

export const toggleOffcanvas = (id: string) => {
  const button = document.getElementById(`${id}_offcanvas_button`);
  if (button) {
    button?.click();
  }
};
</script>
