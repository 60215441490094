interface Number {
    toCurrency(amount?: Number): String;
}
Number.prototype.toCurrency = function (amount?: Number): String {
    if (!amount) { amount = this; }
    let res = String(amount)
    try {
        const numberAmount = Number(amount);
        const formattedAmount = numberAmount
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            .replace(/,/g, '*')
            .replace(/\./g, ',')
            .replace(/\*/g, '.')

        if (numberAmount < 0) {
            res = `-R$${formattedAmount.substring(1)}`; // Adiciona o sinal negativo à frente
        } else {
            res = `R$${formattedAmount}`; // Adiciona o sinal positivo à frente
        }
    } catch (e) {
        console.log(e)
    }
    return res
}

