import { toggleModal } from "@/utils/Helper";
import { mapState } from "pinia";
import { defineComponent } from "vue";
import { EOffcanvasViews, navigateCanvas } from '../OffcanvasViewsConfig';
import { useMainStore } from './../../../store/index';

export default defineComponent({
    computed: {
        ...mapState(useMainStore, ['session'])
    },
    data() {
        return {
            improvements: [
                {
                    key: "minimum_hours",
                    title: "Aumentar horários disponíveis",
                    description: "Ter pelo menos 20 horas disponíveis na agenda.",
                    action: () => navigateCanvas(EOffcanvasViews.schedule_info, null, false),
                    enabled: !useMainStore().session?.profile_improvement['minimum_hours']
                },
                {
                    key: "has_approaches",
                    title: "Adicionar suas abordagens",
                    description: "Completar suas abordagens de atuação.",
                    action: () => navigateCanvas(EOffcanvasViews.approaches, null, false),
                    enabled: !useMainStore().session?.profile_improvement['has_approaches']
                },
                {
                    key: "first_appointment_active",
                    title: "Permitir conversas iniciais",
                    description:
                        "Permitir que as primeiras conversas com seus pacientes tenham um valor mais acessível.",
                    action: () => navigateCanvas(EOffcanvasViews.availability_settings, null, false),
                    enabled: !useMainStore().session?.profile_improvement['first_appointment_active']
                },
                {
                    key: "introduction_video",
                    title: "Adicionar vídeo de introdução",
                    description:
                        "Isso pode auxiliar o paciente a se sentir mais a vontade antes de iniciar uma sessão com você.",
                    action: () => navigateCanvas(EOffcanvasViews.intro_video, null, false),
                    enabled: !useMainStore().session?.profile_improvement['introduction_video'] && useMainStore().session?.is_premium,
                    premium: !useMainStore().session?.is_premium
                },
                {
                    key: "partnerships",
                    title: "Aceitar desconto para empresas",
                    description:
                        "Amplie seu alcance incentivando o bem-estar no ambiente de trabalho.",
                    action: () => navigateCanvas(EOffcanvasViews.availability_settings, null, false),
                    enabled: !useMainStore().session?.profile_improvement['partnerships']
                },
                {
                    key: "premium_user",
                    title: "Faça parte do Psiapp+",
                    description:
                        "Assim você irá aparecer na frente quando um paciente buscar um psi.",
                    action: () => toggleModal('subscription_modal'),
                    enabled: !useMainStore().session?.profile_improvement['premium_user']
                }
            ],
            suggestions: [
                {
                    title: "Ficar disponível agora",
                    description:
                        "Quanto mais tempo você estiver\ndisponível, mais visibilidade seu perfil\nterá.",
                },
                {
                    title: "Depoimento de pacientes",
                    description:
                        "Incentive seus pacientes a deixarem um depoimento sobre você, isso pode encorajar outros pacientes.",
                },

            ]
        }
    }
})