import ISession from "@/interfaces/ISession";
import ChatService from "@/services/ChatService";
import WebSocketService from "@/services/WebSocketService";
import { useMainStore } from "@/store";
import { EventBus } from "@/utils/EventBus";
import moment from "moment";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import ChatRoomContent from "./ChatRoomContent.vue";
import ChatRoomInput from "./ChatRoomInput.vue";

var channel;

export default defineComponent({
    props: {
        type: {
            type: String,
            default: "screen",
        },
    },
    setup() {
        const chat_service = new ChatService();
        const web_socket_service = new WebSocketService();

        const defaultMessage = ChatService.defaultMessage;

        const store = useMainStore()

        const event_bus = EventBus;

        let loadingChat = ref(false);
        let sectionItems = ref([] as any[]);
        let formatted_date = ref("");

        const id = computed(() => store?.chat_room_config?.id);
        const date = computed(() => store?.chat_room_config?.date);
        const user_name = computed(() => store?.chat_room_config?.user_name);
        const mode = computed(() => store?.chat_room_config?.mode);
        const session = computed((): ISession | null => store.session);

        onMounted(async () => {
            loadingChat.value = true;
            await getMessages();
        })

        watch([id, mode], async () => {
            if (id.value) {
                loadingChat.value = true;
                await getMessages();
                setupPusher(id.value);
            }
        });

        const getMessages = async () => {
            try {
                if (id.value && date.value) {
                    loadingChat.value = true;
                    setupPusher(id.value);

                    sectionItems.value = await new ChatService().getMessages(
                        id.value,
                        date.value,
                        sectionItems.value
                    );

                    formatted_date.value = moment(date.value).format("DD/MM/YYYY");

                    event_bus.$emit("UPDATE_CHATS");
                    scrollToBottom();
                }
            } catch (error) {
                console.log(`ERRO NO GET ITEMS DA useChatMessages:`, error);
            } finally {
                loadingChat.value = false;
            }
        };

        const setupPusher = (chatId: number) => {
            channel = web_socket_service.subscribe(`appointment_chat.${chatId}`);
            channel.bind("new-message", (event: any) => {
                if (event.message.user_id !== session.value?.id) {
                    updateMessages(event.message);
                }
            });
        };

        const updateMessages = async (message: any) => {
            sectionItems.value = await chat_service.updateMessages(
                message,
                sectionItems.value
            );
            event_bus.$emit("UPDATE_CHATS");
            scrollToBottom();
        };

        const handleSend = async (msg: string) => {
            try {
                const res = await chat_service.sendMessage({
                    appointment_chat_id: id.value,
                    message: msg,
                });
                updateMessages(res.data);
            } catch (error) {
                console.log(error);
            }
        };

        const scrollToBottom = () => {
            const chatCardBody = document.getElementById("chat_card_body");
            const chatModalBody = document.getElementById("chat_modal_body");
            setTimeout(() => {
                if (chatCardBody) chatCardBody.scrollTop = chatCardBody.scrollHeight;
                if (chatModalBody) chatModalBody.scrollTop = chatModalBody.scrollHeight;
            }, 100);
        };

        return {
            loadingChat,
            sectionItems,
            defaultMessage,
            user_name,
            formatted_date,
            getMessages,
            handleSend
        };
    },
    components: {
        ChatRoomContent,
        ChatRoomInput
    }
});