<template>
  <div class="offcanvas-body">
    <Calendar
      showOnlyFutureDates
      :days="availableDays"
      :paymentMethod="paymentMethod"
    />
    <p class="text-header">Horários disponíveis</p>

    <LoadingContainer v-if="loading" />
    <template v-else-if="availableTimes.length">
      <div
        v-for="(item, index) in availableTimes"
        :key="index"
        @click="handleSelectDate(item)"
        class="d-flex align-items-center py-4 border-bottom justify-content-between cursor-pointer"
      >
        <div class="d-flex align-items-center">
          <p class="text-paragraph mb-0 me-2">{{ item.formattedDate }}</p>
          <span class="badge bg-success" v-if="item.online">Estou online</span>
        </div>
        <i
          v-if="item.date === selectedDate"
          class="fa-solid fa-check fa-xl ms-auto text-secondary"
        ></i>
      </div>
    </template>
    <div
      v-else
      class="d-flex align-items-center justify-content-center py-4"
      style="height: calc(100vh - 600px)"
    >
      <p class="text-paragraph text-muted">Nenhum horário disponível</p>
    </div>
  </div>
  <div class="offcanvas-footer">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <p class="text-subtitle mb-0">Sessão agendada</p>
        <p class="text-label mb-0">Reagendamento</p>
      </div>
      <Button
        :marginVertical="false"
        label="Reagendar"
        :loading="submitting"
        :disabled="!selectedDate"
        @onClick="handleReschedule"
      />
    </div>
  </div>
</template>

<script lang="ts">
import RescheduleAppointmentController from "./RescheduleAppointmentController";
export default RescheduleAppointmentController;
</script>
