const Weekdays = {
    shortEng: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
    eng: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
    ],
    pt: [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
    ],
};

export default Weekdays