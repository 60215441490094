<template>
  <div class="input-group d-flex align-items-center">
    <input
      @keyup.enter="handleSend"
      type="text"
      class="form-control rounded-pill"
      placeholder="Digite sua mensagem..."
      v-model.trim="message"
    />
    <i
      :class="{
        'text-primary': message.length > 0,
        'text-primary50': message.length == 0,
      }"
      @click="handleSend"
      style="z-index: 99"
      class="fa-solid fa-circle-arrow-up position-absolute end-0 me-3 display-5 cursor-pointer"
    ></i>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      message: "",
    };
  },
  emits: ["handleSend"],
  methods: {
    handleSend() {
      this.$emit("handleSend", this.message);
      this.message = "";
    },
  },
});
</script>

<style scoped></style>
