<template>
  <div class="card">
    <div class="card-header">
      <span class="text-header">
        <i class="fa-solid fa-wifi" />
        Recomendações
      </span>
    </div>
    <div class="card-body">
      <p class="text-paragraph">
        Para evitar que a conexão da vídeo chamada tenha uma qualidade
        indesejada, recomendamos que a sessão seja realizada utilizando o Wi-Fi.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped></style>
