import { useMainStore } from "@/store";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from '@vuelidate/validators';
import { mapState } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
    mounted() {
        this.getOccupationAreas()
    },
    data() {
        return {
            loading: true,
            submitting: false,
            v$: useVuelidate(),
            form: {
                occupation_areas: [] as any
            },
            occupationAreas: [] as any
        }
    },
    computed: {
        ...mapState(useMainStore, ['session']),
    },
    validations() {
        return {
            form: {
                occupation_areas: {
                    required: helpers.withMessage('Selecione até 6 especialidades', required),
                },
            }
        }
    },
    methods: {
        async getOccupationAreas() {
            try {
                const res = await this.$userService.getOccupationAreas()
                this.occupationAreas = res.data.map((elem: any) => ({
                    ...elem,
                    selected: this.session?.occupations.some((occ: any) => occ.occupation_area_id == elem.id),
                }));
                this.form.occupation_areas = this.session?.occupations.map((elem: any) => elem.occupation_area_id)
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        async submit() {
            try {
                this.submitting = true
                const params = {
                    occupation_areas: this.occupationAreas
                        .map((elem: any) => ({
                            ...elem,
                            selected: this.form.occupation_areas.some((fl: number) => fl == elem.id),
                        }))
                        .filter((elem: any) => elem.selected)
                        .map((elem: any) => elem.id),
                }
                await this.$userService.update(params)
                this.$eventBus.$emit('showToast', {
                    title: "Alterações feitas",
                    msg: "Suas informações foram salvas",
                })
            } catch (error) {
                console.error(error)
            } finally {
                this.submitting = false
            }
        },
        handleSelect() {
            if (this.form.occupation_areas.length > 6) {
                alert("Você só pode selecionar 6 especialidades");
                this.form.occupation_areas.pop()
            }
        }
    },

})