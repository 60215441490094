import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            faq: [
                {
                    question: "O que é o Psiapp Pro?",
                    answer: "O Psiapp Pro é um aplicativo exclusivo para psicólogos para agendamentos e consultas online para que você consiga trabalhar sem sair de casa."
                },
                {
                    question: "Como começo a realizar consultas?",
                    answer: `Primeiramente, você precisar estar Online para poder ser buscado(a) por pacientes mudando o status na tela inicial, após isso, é só aguardar e averiguar os agendamentos pendentes na tela de sessões.`
                },
                {
                    question: "Como alterar o valor da minha consulta?",
                    answer: `Para fazer alteração do valor da consulta, selecione: Mais > Editar Perfil > Valor da consulta e coloque o valor desejado.`
                },
                {
                    question: "Como mudar meus especialidades?",
                    answer: `Para mudar as suas áreas de atuação, selecione: Mais > Editar Perfil > Especialidades.`
                },
                {
                    question: "Como desativo minha conta?",
                    answer: `Para desativar a sua conta, você deve ir em: Mais > Minha conta > Desativar conta.`
                },
            ]
        }
    },
})