<template>
  <div>
    <div class="card">
      <div class="card-header">
        <p class="text-header mb-0">Minha conta</p>
        <p
          class="text-subtitle text-secondary cursor-pointer mb-0"
          @click="handleToggleOffcanvas(views.account_update)"
        >
          Editar conta
        </p>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <TextInput :readOnly="true" label="Nome" :value="session.name" />
          </div>
          <div class="col-12">
            <TextInput
              :readOnly="true"
              label="Telefone"
              :value="session.formatted_phone"
            />
          </div>
          <div class="col-12">
            <TextInput :readOnly="true" label="Email" :value="session.email" />
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <p class="mb-0 text-header">Outras ações</p>
      </div>
      <div class="card-body">
        <div
          class="d-flex align-items-center border-bottom pb-4 mb-4 cursor-pointer"
          @click="handleToggleOffcanvas(views.password_update)"
        >
          <p class="text-subtitle mb-0">
            <i class="fa-solid fa-key"> </i>
            Alterar senha
          </p>
          <i class="fa-solid fa-chevron-right ms-auto"></i>
        </div>
        <div
          class="d-flex align-items-center pb-4 cursor-pointer"
          @click="handleToggleModal('account_delete')"
        >
          <p class="text-subtitle mb-0">
            <i class="fa-solid fa-trash-alt"> </i>
            Remover conta
          </p>
          <i class="fa-solid fa-chevron-right ms-auto"></i>
        </div>
      </div>
    </div>
    <AccountDelete />
  </div>
</template>

<script>
import MyAccountController from "./MyAccountController";
export default MyAccountController;
</script>
