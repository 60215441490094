<template>
  <ModalComponent id="specialties_modal" title="Especialidades">
    <template v-slot:body>
      <div
        v-for="(specialty, index) in specialties"
        :key="index"
        class="py-4"
        :class="{
          'border-bottom': index < specialties.length - 1,
          'border-0': index === specialties.length - 1,
          'pt-0': index === 0,
        }"
      >
        <p class="text-paragraph mb-0">{{ specialty.occupation.title }}</p>
      </div>
    </template>
  </ModalComponent>
</template>

<script lang="ts">
import SpecialtiesController from "./SpecialtiesController";
export default SpecialtiesController;
</script>
