const paths = {
    not_found: '/not-found',
    login: '/login',
    home: '/home',
    register: '/cadastro',
    onHolding: '/finalizar-cadastro',
    addBankAccount: '/cadastrar-informacoes-bancarias',
    appointments: {
        list: '/sessoes',
        room: '/sessoes/sala',
    },
    chat: {
        list: '/chat'
    },
    financial: {
        list: '/financeiro',
    },
    more: '/mais',
    psiappPlus: '/psiapp-plus'
}

export default paths