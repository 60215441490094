import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "offcanvas-body" }
const _hoisted_2 = {
  key: 0,
  class: "row align-items-center"
}
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "nav nav-tabs nav-overflow border-0" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingContainer = _resolveComponent("LoadingContainer")!
  const _component_MyPatientItem = _resolveComponent("MyPatientItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tabs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "nav-item",
                  key: index
                }, [
                  _createElementVNode("a", {
                    href: "",
                    onClick: _withModifiers(($event: any) => (_ctx.handleTabClicked(item)), ["prevent"]),
                    class: _normalizeClass(["nav-link text-subtitle mt-0", { active: item.active }])
                  }, _toDisplayString(item.value), 11, _hoisted_5)
                ]))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loadingItems)
      ? (_openBlock(), _createBlock(_component_LoadingContainer, { key: 1 }))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_MyPatientItem, {
              item: item,
              currentTab: _ctx.currentTab,
              onHandlePatientClicked: _ctx.handlePatientClicked
            }, null, 8, ["item", "currentTab", "onHandlePatientClicked"])
          ]))
        }), 128))
  ]))
}