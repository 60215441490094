<template>
  <div class="offcanvas-body">
    <TextInput
      required
      label="Formação"
      :model="v$.form.education"
      placeholder="Qual sua formação?"
    />
    <TextInput
      required
      label="Anos de experiência"
      :model="v$.form.years_exp"
      placeholder="Você trabalha há quantos anos na área?"
    />
    <TextInput
      required
      label="CRP"
      :model="v$.form.crp"
      mask="##/######"
      placeholder="00/000000"
    />
    <TextInput
      label="E-psi"
      :model="v$.form.epsi"
      placeholder="Qual número do seu E-psi?"
    />
    <!-- <TextInput
      label="Linkedin/Currículo"
      :model="v$.form.linkedin"
      placeholder="http://www.linkedin.com/in/exemplo/"
    /> -->
    <TextAreaInput
      required
      label="Resumo do currículo"
      :model="v$.form.bio"
      placeholder="Descreva um pouco sobre sua experiencia"
    />
  </div>

  <div class="offcanvas-footer">
    <FormButton
      :marginVertical="false"
      :form="v$.form"
      label="Concluir"
      :loading="submitting"
      @onClick="submit"
      :disabled="v$.invalid"
    />
  </div>
</template>

<script lang="ts">
import AboutController from "./AboutController";
export default AboutController;
</script>
