import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "avatar avatar-lg me-3" }
const _hoisted_4 = {
  key: 0,
  class: "fa-solid fa-question d-flex justify-content-center align-items-center avatar-img rounded-circle bg-light text-primary"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text-subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex justify-content-between align-items-center", { 'mb-4': _ctx.hasChat }])
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.user?.deleted_at)
              ? (_openBlock(), _createElementBlock("i", _hoisted_4))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _ctx.user?.profileImage,
                  alt: "User Profile Picture",
                  class: "avatar-img rounded-circle"
                }, null, 8, _hoisted_5))
          ]),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.user?.deleted_at ? "Paciente removido" : _ctx.user?.name), 1)
        ])
      ], 2),
      (_ctx.hasChat)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            label: "Falar com paciente",
            fullWidth: "",
            outline: "",
            onOnClick: _ctx.handleChat,
            leftIcon: "fa-regular fa-comment-dots"
          }, null, 8, ["onOnClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}