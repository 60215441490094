<template>
  <div
    class="d-flex justify-content-center"
    v-if="uploading"
    style="margin-bottom: 1.5rem"
  >
    <div class="shadow rounded-circle">
      <div
        class="d-flex justify-content-center align-items-center"
        style="width: 12rem; height: 12rem"
      >
        <span
          class="spinner-border spinner-border text-primary"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>
  </div>
  <div @click="openModalForFile" class="text-center" v-else>
    <div
      class="avatar avatar-huge cursor-pointer border border-4 border-card shadow rounded-circle"
    >
      <template v-if="avatar">
        <img :src="avatar" class="avatar-img rounded-circle" />
      </template>
      <template v-else>
        <img :src="placeholder" class="avatar-img rounded-circle" />
      </template>
      <p class="text-subtitle mt-3">Alterar foto</p>
      <!-- <span
        class="position-absolute rounded-circle bg-primary border border-2 border-card shadow d-flex justify-content-center align-items-center"
        style="bottom: 0px; right: 0px; width: 30px; height: 30px"
      >
        <i class="fa-solid fa-pen" style="color: #25282a; font-size: 16px"></i>
      </span> -->
    </div>
  </div>
  <ImageCropper
    modalId="avatar_uploader_modal"
    :accept="'.jpeg,.png,.jpg'"
    :aspectRatio="1 / 1"
    v-on:cropEnd="fileUploaded($event)"
    inputId="avatar_uploader_input"
    :hiddenInput="true"
    backdrop="false"
  />
</template>

<script lang="ts">
import AvatarUploaderController from "./AvatarUploaderController";
export default AvatarUploaderController;
</script>

<style>
.avatar-huge {
  width: 12rem;
  height: 12rem;
}
</style>
