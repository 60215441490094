
import moment from 'moment';
import qs from 'qs';
import NetworkService, { Endpoint } from './NetworkService';
import UserService from './UserService';
import { useMainStore } from '@/store';

export default class AppointmentService {
    private network
    constructor() {
        this.network = new NetworkService()
    }

    static PAYOUT_STATUS = {
        PAID: "paid",
        FAILED: "failed",
        CANCELED: "canceled",
        IN_TRANSIT: "in_transit",
        PENDING: "pending",
    };

    static TYPES = {
        IMEDIATE: "imediate",
        SCHEDULED: "scheduled",
    };

    static STATUS = {
        AGUARDANDO: "aguardando",
        AGENDADO: "agendado",
        CONCLUIDO: "concluido",
        CANCELADO: "cancelado",
        RECUSADO: "recusado",
        ANDAMENTO: "em andamento",
        PENDING: "pending"
    };

    static RESCHEDULE_STATUS = {
        PENDING: "pending",
        CANCELED: "canceled",
        ACCEPTED: "accepted",
        REJECTED: "rejected",
    };

    static MINIMUM_PRICE = 50;
    static MAX_PRICE = 9999;

    static FREQUENCY_TYPES = {
        DAILY: "daily",
        WEEKLY: "weekly",
        MONTHLY: "monthly",
        FORTNIGHTLY: "fortnightly",
    };

    static FREQUENCY_TYPES_LABEL: { [key: string]: string } = {
        daily: "Diária",
        weekly: "Semanal",
        fortnightly: "Quinzenal",
        monthly: "Mensal",
    };

    static FREQUENCY_TYPES_PLURAL_LABEL: { [key: string]: string } = {
        daily: "diárias",
        weekly: "semanais",
        fortnightly: "quinzenais",
        monthly: "mensais",
    };

    static CONNECT_ACCOUNT_DUE: { [key: string]: string } = {
        "business_type": "Tipo de Negócio",
        "external_account": "Conta Bancária",
        "individual.verification.document": "Documento de Verificação Individual",
        "representative.address.city": "Cidade do Endereço do Representante",
        "representative.address.line1": "Linha 1 do Endereço do Representante",
        "representative.address.postal_code": "Código Postal do Endereço do Representante",
        "representative.address.state": "Estado do Endereço do Representante",
        "representative.dob.day": "Dia de Nascimento do Representante",
        "representative.dob.month": "Mês de Nascimento do Representante",
        "representative.dob.year": "Ano de Nascimento do Representante",
        "representative.email": "Email do Representante",
        "representative.first_name": "Primeiro Nome do Representante",
        "representative.id_number": "Número de Identificação do Representante",
        "representative.last_name": "Sobrenome do Representante",
        "representative.phone": "Telefone do Representante",
        "representative.political_exposure": "Exposição Política do Representante",
        "representative.verification.document": "Documento de Verificação do Representante",
        "tos_acceptance.date": "Data de Aceite dos Termos de Serviço",
        "tos_acceptance.ip": "Aceite dos Termos de Serviço"
    };

    create = (params: any) => {
        return this.network.post(Endpoint.appointments, params)
    }
    list = async (params: any) => {
        const res = await this.network.get(Endpoint.appointments, params)
        res.data = res.data.map((elem: any) => this.prepareAppointment(elem))
        return res
    }
    show = (id: any) => {
        return this.network.get(`${Endpoint.appointments}/${id}`)
    }
    update = (id: any, params: any) => {
        return this.network.put(`${Endpoint.appointments}/${id}`, params)
    }
    remove = (id: any) => {
        return this.network.delete(`${Endpoint.appointments}/${id}`)
    }
    createEvent = (params: any) => {
        return this.network.post(Endpoint.callEvents, params);
    };
    prepareAppointment(data: any) {
        let date = moment(data.date)
        data.formatted_date = `${date.format('DD/MM')} - ${date.format('dddd').capitalizeFirst()}`
        data.formatted_time = `${date.format('HH[h]mm')} às ${date.add(50, 'minutes').format('HH[h]mm')}`

        data.formatted_type = data?.type == AppointmentService.TYPES.IMEDIATE ? 'Imediata' : 'Agendada'

        data.is_waiting_boleto_payment = data.payment_method === 'boleto' &&
            (data.status == AppointmentService.STATUS.AGUARDANDO || data.status == AppointmentService.STATUS.AGENDADO)
        // && data.pg_status != 'succeeded'

        data.isCancelable = data.status == AppointmentService.STATUS.AGENDADO && !data.is_waiting_boleto_payment

        switch (data.status) {
            case AppointmentService.STATUS.AGUARDANDO:
                data.status_label = 'Aguardando confirmação'
                data.status_color = 'text-warning'
                data.status_icon = 'fa-solid fa-clock'
                data.showButton = true
                break;
            case AppointmentService.STATUS.ANDAMENTO:
                data.status_label = 'Em andamento'
                data.status_color = 'text-info'
                data.status_icon = ''
                data.showButton = true
                break;
            case AppointmentService.STATUS.CONCLUIDO:
                data.status_label = 'Finalizada'
                data.status_color = 'text-muted'
                data.status_icon = ''
                data.showButton = false
                break;
            case AppointmentService.STATUS.AGENDADO:
                data.status_label = 'Confirmada'
                data.status_color = 'text-success'
                data.status_icon = 'fa-solid fa-check-circle'
                data.showButton = true
                break;
            case AppointmentService.STATUS.CANCELADO:
                data.status_label = 'Cancelada'
                data.status_color = 'text-danger'
                data.status_icon = ''
                data.showButton = false
                break;
            case AppointmentService.STATUS.RECUSADO:
                data.status_label = 'Recusada'
                data.status_color = 'text-danger'
                data.status_icon = ''
                data.showButton = false
                break;
            default:
                break;
        }

        if (data.call_link) {
            const metadata = `${navigator.userAgent}|psi-pro-web|no-model|${process.env.VUE_APP_VERSION_STRING}|no-os-version`

            const wherebyConfig = {
                floatSelf: "",
                minimal: "",
                lang: "pt",
                skipMediaPermissionPrompt: "",
                chat: "on",
                people: "off",
                displayName: data.psychologist?.name,
                avatarUrl: process.env.VUE_APP_IMG_BASE_URL + data.psychologist?.profile_image,
                externalId: data.psychologist?.id,
                metadata,
            };

            data.callLink = `${data?.call_link}?${qs.stringify(wherebyConfig)}`;
        }

        if (data.user) {
            data.user.profileImage = require(`@/assets/img/avatars/${data?.user?.profile_image}.png`)
        }

        // if (data.payout) {
        data.payout = this.preparePayout(data.payout || { status: 'pending' }, data.date, data.value)
        // }

        // FINANCIAL
        data.financialAppointmentDateLabel = `Sessão realizada em ${moment(data.date).format("DD/MM/YYYY [-] HH:mm")}`

        data.hasPendingReschedule =
            data.rescheduleRequest &&
            data.rescheduleRequest.status == AppointmentService.RESCHEDULE_STATUS.PENDING;

        // IN PROGRESS
        data.inProgressStatusLabel =
            data?.status == "em andamento"
                ? "Sessão em andamento"
                : "Nova sessão imediata";
        data.inProgressTime = `Sessão das ${moment(data?.date).format(
            "HH[h]mm"
        )} às ${moment(data?.date).add(50, "minutes").format("HH[h]mm")}`;
        data.inProgressButtonLabel =
            data?.status == "em andamento" ? "Entrar na sala" : "Ver solicitação";

        if (data.hasPendingReschedule) {
            data.status_label = "Reagendamento Pendente";
        }

        // CARD
        data.cardStatusLabel =
            data?.status == AppointmentService.STATUS.AGUARDANDO
                ? "Aguardando confirmação"
                : "Sessão confirmada";

        data.cardStatusColor =
            data?.status == AppointmentService.STATUS.AGUARDANDO || data.hasPendingReschedule
                ? "text-secondary"
                : "text-primary50";
        data.cardFormattedDate = `Dia ${moment(data?.date).format("DD | dddd")}`;
        data.cardFormattedTime = `das ${moment(data?.date).format("HH:mm")} às ${moment(
            data?.date
        )
            .add(50, "minutes")
            .format("HH:mm")}`;


        data.payment_error = data.pg_status === 'requires_payment_method'

        if (data.recurrency) {
            if (
                data.recurrency.frequency ==
                AppointmentService.FREQUENCY_TYPES.WEEKLY &&
                data.recurrency.interval == 2
            ) {
                data.recurrency.frequencyLabel =
                    AppointmentService.FREQUENCY_TYPES_LABEL["fortnightly"];
            } else {
                data.recurrency.frequencyLabel =
                    AppointmentService.FREQUENCY_TYPES_LABEL[data.recurrency.frequency];
            }

            data.recurrency.frequencyText = this.getFrequencyDateText(
                data.recurrency.frequency,
                data.recurrency.interval,
                data.recurrency.reference_date
            );

            if (data?.recurrency.next_appointment_date) {
                data.recurrency.nextAppointmentDate = moment(
                    data?.recurrency?.next_appointment_date?.date
                ).format("DD/MM");
            }
        }


        if (
            data.type == AppointmentService.TYPES.SCHEDULED &&
            data.reschedule_requests &&
            data.reschedule_requests.length &&
            data.reschedule_requests[0].status !==
            AppointmentService.RESCHEDULE_STATUS.CANCELED
        ) {
            data.rescheduleRequest = this.prepareRescheduleRequest(
                data.reschedule_requests[0]
            );
        }

        if (data?.ratings && data?.ratings.length) {
            const my_rating = data?.ratings.find(
                (elem: any) => elem.user_id === useMainStore().session?.id
            )
            if (data.status == AppointmentService.STATUS.CONCLUIDO && my_rating?.rating) {
                data.my_rating = my_rating
            }
        }

        return data
    }

    prepareRescheduleRequest = (data: any) => {
        let myRequest = useMainStore().session?.id == data.user_id;

        const itemConfig = {
            formattedDate: `${moment(data?.new_date).format("DD/MM")} - ${moment(
                data?.new_date
            )
                .format("dddd")
                .capitalizeFirst()}`,
            formattedTime: `das ${moment(data?.new_date).format(
                "HH[h]mm"
            )} às ${moment(data?.new_date).add(50, "minutes").format("HH[h]mm")}`,
            myRequest,
        };

        const descriptionDate = `dia ${moment(data?.new_date).format("DD/MM")}`;

        const descriptionTime = `${moment(data?.new_date).format(
            "HH[h]mm"
        )} às ${moment(data?.new_date).add(50, "minutes").format("HH[h]mm")}`;

        const userTypeLabel = myRequest ? "paciente" : "psi";

        const config: { [key: string]: any } = {
            pending: {
                ...itemConfig,
                pendingByMe: !myRequest,
                color: "text-primary",
                bgColor: "bg-primary-soft",
                title: "Reagendamento pendente",
                icon: "fa-solid fa-clock",
                description: `<p class="text-paragraph mb-0">A solicitação de reagendamento para o <span class="text-subtitle">${descriptionDate}</span> no horário das <span class="text-subtitle">${descriptionTime}</span>, encontra-se aguardando confirmação por parte do paciente.</p>`
            },
            rejected: {
                ...itemConfig,
                color: "text-warning",
                bgColor: "bg-warning-soft",
                title: "Reagendamento rejeitado",
                icon: "fa-solid fa-times-circle",
                description: `<p class="text-paragraph mb-0">A solicitação de reagendamento para o <span class="text-subtitle">${descriptionDate}</span> no horário das <span class="text-subtitle">${descriptionTime}</span>, não foi confirmado por parte do ${userTypeLabel}.</p>`

            },
            accepted: {
                ...itemConfig,
                color: "text-success",
                bgColor: "bg-success-soft",
                title: "Reagendamento confirmado",
                icon: "fa-solid fa-check-circle",
                description: `<p class="text-paragraph mb-0">O reagendamento foi confirmado pelo ${userTypeLabel}.</p>`
            }
        }
        return { ...data, ...config[data?.status] };
    }

    getFrequencyDateText = (frequency: any, interval: number, date: string, capitalize = false) => {
        const weekDay = moment(date).day();
        const d = moment(date).format("dddd");
        let day: any = d.split("-");
        //dia no plural
        day = day.length > 1 ? `${day[0]}s-${day[1]}s` : `${day[0]}s`;
        const acronym = [6, 0].includes(weekDay) ? "aos" : "nas";

        switch (frequency) {
            case AppointmentService.FREQUENCY_TYPES.DAILY:
                return;
            case AppointmentService.FREQUENCY_TYPES.WEEKLY:
                if (interval > 1) {
                    return `${capitalize ? "A" : "a"
                        } cada duas semanas ${acronym} ${day} às ${moment(date).format(
                            "HH[h]mm"
                        )}.`;
                } else {
                    return `${capitalize ? "T" : "t"
                        }odas as semanas ${acronym} ${day} às ${moment(date).format(
                            "HH[h]mm"
                        )}.`;
                }
            case AppointmentService.FREQUENCY_TYPES.MONTHLY:
                return `${capitalize ? "T" : "t"}odos os meses nos dias ${moment(
                    date
                ).format("DD")} às ${moment(date).format("HH[h]mm")}.`;
            case AppointmentService.FREQUENCY_TYPES.FORTNIGHTLY:
                return `${capitalize ? "A" : "a"
                    } cada duas semanas ${acronym} ${day} às ${moment(date).format(
                        "HH[h]mm"
                    )}.`;
        }
    };

    preparePayout(data: any, appointment_date = '', appointment_value = 0) {
        const status_config = this.payoutStatus(data.main_payout_info?.status || data.status, data.main_payout_info?.arrival_date || data.arrival_date)
        const formatted_appointment_date = moment(data.date || appointment_date).format("DD/MM/YYYY [-] HH:mm")
        const appointment_date_label = `Sessão realizada em ${formatted_appointment_date}`
        const formatted_arrival_date = moment(data?.main_payout_info?.arrival_date || data.arrival_date).format("DD/MM/YYYY")
        const value = data.net || data.amount || appointment_value * 0.8;
        const formatted_value = value.toCurrency()

        if (data.bank_account) {
            data.bank_account = {
                ...data.bank_account,
                type_label:
                    data.bank_account?.account_holder_type == "individual"
                        ? "PF"
                        : "PJ",
                last_digits: `***${data?.bank_account?.last4}`,
            };
        }

        if (data.transactions) {
            data.transactions = data.transactions.map((item: any) => {
                return {
                    ...item,
                    refer_to_another_payout: this.referToAnotherPayout(item),
                    payout_type: this.payoutType(item?.type, item?.reporting_category),
                    appointment_user_name: item?.appointment ? `Sessão com ${item?.appointment?.user?.name}` : null,
                    appointment_date: item?.appointment ? `Realizada em ${moment(item?.appointment?.date).format("DD/MM/YY [-] HH:mm")}` : null,
                    payout_date: this.referToAnotherPayout(item) ? `Data do repasse: ${moment(item?.source_payout?.arrival_date).format("DD/MM/YY")}` : null,
                    payout_net: item.net.toCurrency(),
                    has_action: this.referToAnotherPayout(item) || item.appointment
                }


            })
        }

        const payout = {
            ...data,
            status_config,
            appointment_date_label,
            formatted_arrival_date,
            formatted_value
        }

        return payout
    }
    referToAnotherPayout = (item: any) => {
        return (
            item?.type == "payout_failure" &&
            item?.reporting_category == "payout_reversal" &&
            item?.source_payout
        );
    };
    financial = async (params: any) => {
        const res = await this.network.get(Endpoint.payouts, params);
        res.data.payouts = res.data.payouts.map((item: any) => this.preparePayout(item))
        return res
    };
    showPayout = (id: number) => {
        return this.network.get(`${Endpoint.payouts}/${id}`);
    };
    payoutStatus = (status: string, date: string) => {
        const formatted_date = moment(date).format('DD/MM/YYYY')
        switch (status) {
            case AppointmentService.PAYOUT_STATUS.PAID:
                return {
                    financial: {
                        status: "Repasse realizado",
                        color: "text-primary",
                    },
                    financial_appointments: {
                        status: `Repasse realizado em ${formatted_date}`,
                        color: "text-primary",
                    },
                    payout_detail: {
                        status: "Realizado",
                        color: "text-success",
                    },
                    appointment_detail: {
                        status: "Realizado",
                        color: "text-success",
                    },
                };
            case AppointmentService.PAYOUT_STATUS.FAILED:
                return {
                    financial: {
                        status: "Repasse com falha",
                        color: "text-danger",
                    },
                    financial_appointments: {
                        status: `Repasse com falha em ${formatted_date}`,
                        color: "text-danger",
                    },
                    payout_detail: {
                        status: "Falha no repasse",
                        color: "text-warning",
                    },
                    appointment_detail: {
                        status: "Falha no repasse",
                        color: "text-warning",
                    },
                };
            case AppointmentService.PAYOUT_STATUS.CANCELED:
                return {
                    financial: {
                        status: "Repasse cancelado",
                        color: "text-danger",
                    },
                    financial_appointments: {
                        status: `Repasse cancelado em ${formatted_date}`,
                        color: "text-danger",
                    },
                    payout_detail: {
                        status: "Cancelado",
                        color: "text-danger",
                    },
                    appointment_detail: {
                        status: "Cancelado",
                        color: "text-danger",
                    },
                };
            case AppointmentService.PAYOUT_STATUS.IN_TRANSIT:
                return {
                    financial: {
                        status: "Repasse em trânsito",
                        color: "text-primary50",
                    },
                    financial_appointments: {
                        status: `Repasse em trânsito`,
                        color: "text-primary50",
                    },
                    payout_detail: {
                        status: "Em trânsito",
                        color: "text-primary50",
                    },
                    appointment_detail: {
                        status: "Em trânsito",
                        color: "text-primary50",
                    },
                };
            case AppointmentService.PAYOUT_STATUS.PENDING:
            default:
                return {
                    financial: {
                        status: "Aguardando repasse",
                        color: "text-primary50",
                    },
                    financial_appointments: {
                        status: `Aguardando repasse`,
                        color: "text-primary50",
                    },
                    payout_detail: {
                        status: "Aguardando",
                        color: "text-primary50",
                    },
                    appointment_detail: {
                        status: "Aguardando",
                        color: "text-primary50",
                    },
                };
        }
    };
    payoutType = (type: string, reportingCategory: string) => {
        //   adjustment + fee -> reembolso de taxa
        //   payment + charge -> pagamento de sessao
        //   payment_refund  + refund -> reembolso de sessao
        //   payout + payout -> montante do repasse (tira essa palavra total q é redundante)
        //   payout_failure + payout_reversal -> reembolso de falha em repasse
        const key = `${type}/${reportingCategory}`;

        switch (key) {
            case "adjustment/fee":
                return "Reembolso da taxa";
            case "payment/charge":
                return "Pagamento da sessão";
            case "payment_refund/refund":
                return "Reembolso da sessão";
            case "payout/payout":
                return "Montante do repasse";
            case "payout_failure/payout_reversal":
                return "Reembolso de falha no repasse";
            default:
                return type.capitalizeFirst();
        }
    };
    callRatings = async () => {
        const res = await this.network.get(Endpoint.callRating);
        if (res.data.length) {
            res.data = res.data.map((rating: any) => ({
                ...rating,
                formatted_profile_image: new UserService().getAvatar(rating.user.profile_image),
                formatted_date: moment(rating.created_at).format("DD[ de ]MMMM[ de ]YYYY")
            }))
        }
        return res
    };

    reviewCallRatings = (params: any) => {
        return this.network.post(`${Endpoint.reviewCallRating}`, params);
    };

    recurrenciesVerification = (params: any) => {
        return this.network.post(Endpoint.recurrenciesVerification, params);
    };

    updateFile = (id: number, params: any) => {
        return this.network.postMultipart(`${Endpoint.appointments}/${id}/edit`, params,);
    };
    updateRescheduleRequest = (id: number, params: any) => {
        return this.network.put(`${Endpoint.rescheduleRequests}/${id}`, params);
    };
    rescheduleAppointment = (params: any) => {
        return this.network.post(`${Endpoint.rescheduleRequests}`, params);
    };
    getStatus(data: any) {
        let rescheduleRequest = data?.rescheduleRequest;
        if (
            rescheduleRequest &&
            rescheduleRequest.status == AppointmentService.RESCHEDULE_STATUS.PENDING
        ) {
            return "Reagendamento Pendente";
        }
        switch (data?.status) {
            case "aguardando":
                return "Aguardando confirmação";
            case "agendado":
                return "Confirmado";
            case "concluido":
                return "Sessão finalizada";
            case "recusado":
            case "cancelado":
                return "Sessão cancelada";
            case "em andamento":
                return "Sessão iniciada";
            default:
                break;
        }
    }
    getStatusColor(data: any) {
        let rescheduleRequest = data?.rescheduleRequest;
        if (
            rescheduleRequest &&
            rescheduleRequest.status == AppointmentService.RESCHEDULE_STATUS.PENDING
        ) {
            return 'text-secondary'
        }
        switch (data?.status) {
            case "aguardando":
                return 'text-warning';
            case "agendado":
                return 'text-success';
            case "concluido":
            case "em andamento":
                return 'text-primary';
            case "recusado":
            case "cancelado":
                return 'text-danger';
            default:
                break;
        }
    }

    recurrencies = async (id: number) => {
        const res = await this.network.get(`${Endpoint.recurrencies}/${id}`);
        res.data = this.prepareRecurrentAppointment(res.data);
        return res;
    };

    prepareRecurrentAppointment = (data: any) => {
        data.profile_image_formatted = new UserService().getAvatar(
            data.profile_image
        );

        if (data.latest_recurrency?.frequency) {
            data.latest_recurrency.frequency = this.fixFrequency(
                data.latest_recurrency?.frequency,
                data.interval
            );

            data.latest_recurrency_frequency =
                AppointmentService.FREQUENCY_TYPES_LABEL[
                data.latest_recurrency?.frequency
                ];
        }

        if (data?.frequency) {
            data.title = `Sessões ${AppointmentService.FREQUENCY_TYPES_PLURAL_LABEL[data.frequency]
                }`;
            data.description = new AppointmentService().getFrequencyDateText(
                data?.frequency,
                data.interval,
                data.reference_date,
                true
            );
        }

        data.status_label = data.status == "active" ? "ativa" : "cancelada";

        data.status_color =
            data.status == "active" ? 'text-success' : 'text-primary50';

        if (data.next_appointment) {
            data.next_appointment.formatted_datetime = `${moment(data.next_appointment.date).format("DD/MM")} - ${moment(data.next_appointment.date)
                .format(`dddd`)
                .capitalizeFirst()} às ${moment(data.next_appointment.date).format(`HH[h]mm`)}`
            data.next_appointment.status_label = this.getStatus(data.next_appointment);
            data.next_appointment.status_color = this.getStatusColor(data.next_appointment);
        }

        if (data.past_appointments) {
            data.past_appointments = data.past_appointments.map((appointment: any) => ({
                ...appointment,
                formatted_datetime: `${moment(appointment.date).format("DD/MM")} - ${moment(appointment.date)
                    .format(`dddd`)
                    .capitalizeFirst()} às ${moment(appointment.date).format(`HH[h]mm`)}`,
                status_label: this.getStatus(appointment),
                status_color: this.getStatusColor(appointment)
            }))
        }

        return data;
    };

    fixFrequency = (frequency: string, interval: number) => {
        return frequency == AppointmentService.FREQUENCY_TYPES.WEEKLY &&
            interval == 2
            ? "fortnightly"
            : frequency;
    };

    cancelRecurrency = (id: number) => {
        return this.network.delete(`${Endpoint.recurrencies}/${id}`);
    };

    callRating = (params: any) => {
        return this.network.post(`${Endpoint.callRating}`, params);
    };
}