<template>
  <div class="card">
    <div class="card-header">
      <span class="text-header">Política de cancelamento</span>
    </div>
    <div class="card-body">
      <p class="text-paragraph">
        Sessões pagas via boleto não podem ser canceladas. No entanto, é
        possível realizar o reagendamento, se necessário.
      </p>

      <Button
        v-if="item?.isCancelable"
        label="Cancelar esta sessão"
        fullWidth
        outline
        outlineColor="danger"
        @onClick="toggleModal(modal.id)"
      />
    </div>
  </div>
  <ModalComponent :id="modal.id" :title="modal.title" backdrop="false">
    <template v-slot:body>
      <RadioInput
        :model="v$.form.cancel_all"
        :options="cancelAllOptions"
        v-if="item.recurrency"
      />

      <TextInput
        required
        label="Motivo"
        :model="v$.form.reason_for_cancellation"
        placeholder="Por que deseja cancelar?"
      />
    </template>
    <template v-slot:footer>
      <Button
        outline
        outlineColor="primary"
        label="Não, voltar"
        @onClick="toggleModal(modal.id)"
      />
      <FormButton
        label="Sim, cancelar esta sessão"
        color="danger"
        @onClick="handleCancelConfirmed"
        :loading="submitting"
        :disabled="v$.$invalid"
        :form="v$.form"
      />
    </template>
  </ModalComponent>
</template>

<script lang="ts">
import { toggleModal } from "@/components/ModalComponent.vue";
import { closeOffCanvas } from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["item", "callback"],
  data() {
    return {
      submitting: false,
      modal: {
        id: "cancel_appointment_modal",
        title: this.item.recurrency
          ? "Qual sessão você deseja cancelar?"
          : "Tem certeza que deseja cancelar esta sessão?",
      },
      toggleModal: (id: string) => toggleModal(id),
      form: {
        reason_for_cancellation: "",
        cancel_all: false,
      },
      v$: useVuelidate(),
      cancelAllOptions: [
        { id: false, title: "Somente esta sessão" },
        { id: true, title: "Esta sessão e as próximas" },
      ],
    };
  },
  validations: {
    form: {
      reason_for_cancellation: {
        required: helpers.withMessage(
          "Informe o motivo de cancelamento da sessão",
          required
        ),
      },
      cancel_all: {},
    },
  },
  methods: {
    async handleCancelConfirmed() {
      try {
        this.submitting = true;
        let params: any = {
          status: this.item?.status == "agendado" ? "cancelado" : "recusado",
          reason_for_cancellation: this.form.reason_for_cancellation,
        };

        if (this.form.cancel_all) {
          params.cancel_recurrency = true;
        }

        await this.$appointmentService.update(this.item?.id, params);
        this.$userService.syncSession();

        if (this.item?.status == "pendente") {
          closeOffCanvas();
          return;
        }
        this.callback();
        toggleModal(this.modal.id);
        this.$eventBus.$emit("UPDATE_APPOINTMENTS");
      } catch (e) {
        console.log(e);
      } finally {
        this.submitting = false;
      }
    },
  },
});
</script>
