<template>
  <div class="offcanvas-body">
    <LoadingContainer v-if="loading" />
    <div v-html="terms"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  mounted() {
    this.getTerms();
  },
  data() {
    return {
      terms: "",
      loading: true,
    };
  },
  methods: {
    async getTerms() {
      try {
        const res = await this.$userService.getTerms();
        this.terms = res.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped></style>
