<template>
  <ModalComponent
    :title="title"
    id="confirm_alert_modal"
    v-on:modalClosed="alertClosed"
  >
    <template v-slot:body>
      <p class="text-paragraph">
        {{ message }}
      </p>
    </template>
    <template v-slot:footer>
      <button
        type="button"
        @click="cancelAction"
        class="btn btn-outline-primary"
      >
        {{ cancellationButtonLabel }}
      </button>

      <button
        type="button"
        @click="confirmAction"
        class="btn"
        :class="`btn-${buttonColor}`"
      >
        {{ confirmationButtonLabel }}
      </button>
    </template>
  </ModalComponent>
</template>
<script>
import ConfirmationAlertController from "./ConfirmationAlertController";
export default ConfirmationAlertController;
</script>
<style scoped></style>
