<template>
  <div class="card">
    <div class="card-header">
      <span class="text-header">Avaliação</span>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <p class="text-paragraph mb-0">Qualidade da ligação</p>
        <VotingStars disabled :value="rating?.rating" />
      </div>
      <hr />
      <p class="text-subtitle">Problemas relatados:</p>
      <p class="text-paragraph">
        {{ rating?.description || "Nenhum problema relatado" }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["rating"],
});
</script>

<style scoped></style>
