<template>
  <div class="card">
    <div class="card-header">
      <p class="text-header mb-0">Ajuda</p>
      <!-- <p class="text-subtitle text-secondary mb-0 cursor-pointer">
        Entre em contato
      </p> -->
    </div>
    <div class="card-body">
      <div class="accordion" id="accordionFAQ">
        <div v-for="(item, index) in faq" :key="index" class="accordion-item">
          <h2 class="accordion-header" :id="`heading${index}`">
            <button
              class="accordion-button text-subtitle"
              :class="{ collapsed: index !== 0 }"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#collapse${index}`"
              aria-expanded="true"
              :aria-controls="`collapse${index}`"
            >
              {{ item.question }}
            </button>
          </h2>
          <div
            :id="`collapse${index}`"
            class="accordion-collapse collapse"
            :class="{ show: index === 0 }"
            data-bs-parent="#accordionFAQ"
            :aria-labelledby="`heading${index}`"
          >
            <div class="accordion-body text-paragraph">
              {{ item.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <p class="text-header mb-0">Entre em contato</p>
    </div>
    <div class="card-body">
      <p class="text-paragraph">
        Caso você precisa de ajuda com o aplicativo, estaremos a disposição para
        te ajudar no email abaixo:
      </p>
      <a href="mailto:contato@psiapp.com" class="text-subtitle text-secondary"
        >contato@psiapp.com</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import SupportController from "./SupportController";
export default SupportController;
</script>
