<template>
  <div class="form-group">
    <label v-if="label" class="form-label text-muted">
      {{ label }} <i v-if="required" class="text-danger">*</i>
    </label>
    <div
      v-for="(item, index) in options"
      :key="'option_' + index"
      class="d-flex align-items-center"
      :class="{ 'border-bottom': options.length > index + 1 }"
    >
      <label
        :for="'option_' + index"
        class="text-paragraph mb-0 flex-fill cursor-pointer py-3"
      >
        {{ item.name || item.title }}
      </label>
      <input
        hidden
        type="radio"
        v-model="model.$model"
        :value="item.id"
        :id="'option_' + index"
        @change="onChange($event)"
      />
      <i
        class="fa-solid fa-check fa-xl text-secondary"
        v-if="model.$model == item.id"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      required: false,
      default: () => {},
    },
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(event) {
      this.$emit("onChange", event.target.value);
    },
  },
};
</script>
