<template>
  <div class="alert bg-warning-soft">
    <p class="text-subtitle text-warning">
      <i class="fa-solid fa-barcode"></i>
      Pagamento via boleto
    </p>
    <p class="text-paragraph mb-0">
      Atenção, sessões pagas via boleto não podem ser canceladas.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped></style>
