import { toggleModal } from "@/utils/Helper";
import { defineComponent } from "vue";

export interface IConfirmationAlert {
  title?: string | null;
  message?: string | null;
  confirmationButtonLabel?: string | null;
  cancellationButtonLabel?: string | null;
  confirmCallback: () => void | null;
  cancelCallback: () => void | null;
  buttonColor?: string | null;
}

export default defineComponent({
  name: "ConfirmationAlert",
  mounted() {
    this.$eventBus.$on(
      "showConfirmationAlert",
      ({
        title = "Deseja continuar",
        message = "Tem certeza que deseja continuar?",
        confirmationButtonLabel = "Sim",
        cancellationButtonLabel = "Não",
        buttonColor = 'secondary',
        confirmCallback,
        cancelCallback,
      }: IConfirmationAlert) => {

        this.title = title
        this.message = message;
        this.confirmationButtonLabel = confirmationButtonLabel;
        this.cancellationButtonLabel = cancellationButtonLabel;
        this.buttonColor = buttonColor;
        this.confirmCallback = confirmCallback;
        this.cancelCallback = cancelCallback;
        toggleModal('confirm_alert_modal')
      }
    );
  },
  data() {
    return {
      confirmCallback: () => { },
      cancelCallback: () => { },
      title: "",
      message: "",
      confirmationButtonLabel: "",
      cancellationButtonLabel: "",
      buttonColor: ''
    } as IConfirmationAlert;
  },
  methods: {
    alertClosed() {
      this.cancelAction();
    },
    confirmAction() {
      this.confirmCallback();
      toggleModal('confirm_alert_modal')
    },
    cancelAction() {
      if (this.cancelCallback) {
        this.cancelCallback();
      }
      toggleModal('confirm_alert_modal')
    },
  },
});
