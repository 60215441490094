<template>
  <div class="offcanvas-body">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="d-flex align-items-center py-4 cursor-pointer"
      :class="{ 'border-bottom': index !== items.length - 1 }"
      @click="item.action(item)"
    >
      <i :class="item.icon" class="text-primary fa-lg" style="width: 35px" />
      <div>
        <div class="d-flex align-items-center">
          <p
            class="mb-0 text-paragraph"
            :class="{ 'text-primary50': !item.enabled }"
          >
            {{ item?.value }}
          </p>
          <span v-if="!item.enabled" class="ms-2 badge bg-info rounded-4"
            >Paiapp +</span
          >
        </div>
      </div>
      <i class="fa-solid fa-chevron-right ms-auto"></i>
    </div>
  </div>
</template>

<script>
import ProfileEditController from "./ProfileEditController";
export default ProfileEditController;
</script>
