
interface String {
    capitalizeFirst(): String;
    limitString(): String;
    clear(): String;
    formatCPF(): String;
    formatPhone(): String;
    toPattern(): String;
    toPercentage(): string;
    toQuantity(): string
    toFormattedDate(): string
}
String.prototype.capitalizeFirst = function () {
    let res = this
    try {
        res = this.charAt(0).toUpperCase() + this.slice(1);
    } catch (e) {
        console.log(e)
    } finally {
        return res
    }
}
String.prototype.limitString = function (limit = 40) {
    let res = this
    try {
        res = this.length >= limit ? `${this.substr(0, limit - 1)}...` : this
    } catch (e) {
        console.log('erro', e)
        return res
    }
    finally {
        return res
    }
}
String.prototype.clear = function () {
    let res = this
    try {
        res = this.replace(/[^\w\s]/gi, '').replace(' ', '').replace(/[_-]/g, "")
    } catch (e) {
        return res
    }
    finally {
        return res
    }
}
String.prototype.formatCPF = function () {
    let res = this
    try {
        // Remove qualquer caractere que não seja número
        res = res.replace(/\D/g, "");
        // Aplica a formatação
        res = res.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } catch (error) {
        return res

    } finally {
        return res
    }
}
String.prototype.formatPhone = function () {
    let res = this
    try {
        const phoneCleaned = res.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

        // Define os padrões de máscara com base no tamanho da string
        const patternWithNine = /^(\d{2})(\d{5})(\d{4})$/;
        const patternWithNoNine = /^(\d{2})(\d{4})(\d{4})$/;

        // Aplica a máscara de acordo com o tamanho da string
        const formattedPhone = phoneCleaned.replace(
            phoneCleaned.length === 11 ? patternWithNine : patternWithNoNine,
            phoneCleaned.length === 11 ? "($1) $2-$3" : "($1) $2-$3"
        );

        return formattedPhone;
    } catch (error) {
        console.log(error)
        return res
    }
}

// Função para formatar porcentagem
String.prototype.toPercentage = function () {
    let value = this;
    // Remove qualquer caractere que não seja número
    const number = value.replace(/[^0-9]/g, "");

    if (number) {
        const formattedValue = parseInt(number, 10);
        return `${formattedValue}%`;
    } else {
        return "";
    }
};

// Função para formatar quantidade de vezes
String.prototype.toQuantity = function toQuantity() {
    let qty = this;
    // Remove qualquer caractere que não seja número
    const number = qty.replace(/[^0-9]/g, "");

    if (number) {
        const formattedValue = parseInt(number, 10);
        return `${formattedValue}x`;
    } else {
        return "";
    }
};

// String.prototype.toFormattedDate = function toFormattedDate(
//     mode = 1,
//     showTodayAsString = false
// ) {
//     let res = this;
//     if (!res) return "";
//     try {
//         const date = new Date(this);
//         switch (mode) {
//             case 1:
//                 // hoje, ontem ou data
//                 if (moment(date).isSame(moment(), "day")) {
//                     //mesmo dia
//                     res = showTodayAsString ? "Hoje" : moment(date).format("HH:mm");
//                 } else if (moment(date).isSame(moment().add(-1, "days"), "day")) {
//                     res = "Ontem";
//                 } else {
//                     res = moment(date).format("DD/MM/YYYY");
//                 }
//                 break;
//             case 2:
//                 // 12 de Jun
//                 res = `${moment(date).format("DD [de] MMM")}`;
//                 break;
//             case 3:
//                 // 12/07 - Quinta feira às 16:43
//                 res = `${moment(date).format("DD/MM")} - ${moment(date)
//                     .format(`dddd`)
//                     .capitalizeFirst()} às ${moment(date).format(`HH[h]mm`)}`;
//                 break;
//             case 4:
//                 // 12/07 - Quinta feira
//                 const m = moment(date).format("dddd").capitalizeFirst();
//                 res = `${moment(date).format("DD/MM")} - ${m}`;
//                 break;
//             case 5:
//                 // Julho de 2022
//                 res = `${moment(date).format("MMMM [de] YYYY")}`.capitalizeFirst();
//                 break;
//             case 6:
//                 // Dia 23/07 | Segunda-feira
//                 res = `Dia ${moment(date).format("DD/MM")} | ${`${moment(date).format(
//                     "dddd"
//                 )}`.capitalizeFirst()}`;
//                 break;
//             case 7:
//                 // 10 de Junho de 2023
//                 res = `${moment(date).format("DD")} de ${`${moment(date).format(
//                     "MMMM [de] YYYY"
//                 )}`.capitalizeFirst()}`;
//                 break;
//         }
//     } catch (e) {
//         return res;
//     } finally {
//         return res;
//     }
// };
