<template>
  <div>
    <div class="d-flex flex-fill"></div>
    <!-- CHAT CREATED AT -->
    <div class="text-center sticky-top mb-3">
      <span class="badge bg-white text-primary text-label" style="width: 76px">
        {{ formattedDate }}
      </span>
    </div>
    <template v-for="(item, index) in sectionItems" :key="index">
      <!-- DEFAULT MESSAGE -->
      <div
        class="bg-light py-3 px-3 rounded-5 d-flex mb-3 justify-content-center align-items-center position-relative"
        v-if="item.isDefault"
      >
        <img
          src="@/assets/img/icon_default_message.svg"
          width="30"
          class="position-absolute start-0 ms-4"
        />
        <p class="mb-0 text-center text-label" style="width: 400px">
          {{ defaultMessage }}
        </p>
      </div>

      <!-- DATE -->
      <div class="text-center sticky-top" v-if="item.isHeader">
        <span
          class="badge bg-white border-light text-primary text-label"
          style="width: 76px"
        >
          {{ item.title }}
        </span>
      </div>

      <!-- MESSAGE -->
      <div class="d-flex mb-3" v-if="!item.isHeader && !item.isDefault">
        <img
          :src="item.image"
          alt=""
          height="38"
          width="38"
          class="rounded-circle me-3 mt-1"
        />
        <div>
          <div class="d-flex align-items-center">
            <p class="mb-0 text-subtitle me-3">
              {{ item.name }}
            </p>
            <p class="mb-0 text-label text-muted">
              {{ item.time }}
            </p>
          </div>
          <p class="text-paragraph">
            {{ item.message }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

interface ISectionItem {
  isHeader: boolean;
  isDefault: boolean;
  title: string;
  name: string;
  time: string;
  message: string;
  image: string;
}

export default defineComponent({
  props: {
    sectionItems: {
      type: Array as PropType<ISectionItem[]>,
      required: true,
    },
    defaultMessage: {
      type: String,
      required: true,
    },
    formattedDate: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped></style>
