import IPhoneCode from "@/interfaces/IPhoneCode";
import { useMainStore } from "@/store";
import { isNameValid } from "@/utils/FormValidators";
import { toggleModal } from "@/utils/Helper";
import PhoneCodes from "@/utils/PhoneCodes";
import { EOffcanvasViews, OffcanvasViewKey, OffcanvasViewsConfig, navigateCanvas } from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
import { mapState } from "pinia";
import { defineComponent } from "vue";
import AccountDelete from "./AccountDelete/AccountDelete.vue";
import AccountUpdate from "./AccountUpdate/AccountUpdate.vue";
import PasswordUpdate from "./PasswordUpdate/PasswordUpdate.vue";

export default defineComponent({
    data() {
        return {
            views: EOffcanvasViews,
            v$: useVuelidate(),
            phoneCodes: PhoneCodes,
            accountForm: {
                name: '',
                country_code: '',
                phone: '',
                email: '',
            },
            phoneMask: '(##) #####-####',
            phoneCode: PhoneCodes[0] as IPhoneCode,
        }
    },
    validations() {
        return {
            accountForm: {
                name: {
                    required: helpers.withMessage('Informe um nome válido', required),
                    minLength: helpers.withMessage('Informe um nome válido', minLength(3)),
                    fullName: helpers.withMessage('Informe um nome válido', (value: string) => isNameValid(value)),
                },
                country_code: {
                    required: helpers.withMessage('Informe o DDI', required),
                },
                phone: {
                    required: helpers.withMessage('Informe um telefone válido', required),
                    minLength: helpers.withMessage('Informe um telefone válido', minLength(this.phoneCode?.mask.length)),
                },
            }
        }
    },
    computed: {
        ...mapState(useMainStore, ['session']),
        toggle() {
            return window.innerWidth < 964
        }
    },
    methods: {
        handleToggleModal(id: string) {
            toggleModal(id)
        },
        handleToggleOffcanvas(config: OffcanvasViewKey) {
            navigateCanvas(OffcanvasViewsConfig[config].path as OffcanvasViewKey, null, this.toggle)
        },
    },
    components: {
        AccountUpdate,
        PasswordUpdate,
        AccountDelete
    }
})