<template>
  <div>
    <div class="card">
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="text-paragraph">Status</span>
            <span class="text-subtitle" :class="item?.status_color"
              >{{ item?.status_label }}
              <i :class="item?.status_icon"></i>
            </span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="text-paragraph"> Tipo de sessão</span>
            <span class="text-subtitle">{{ item?.formatted_type }}</span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="text-paragraph">Dia</span>
            <span class="text-subtitle">{{ item?.formatted_date }}</span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="text-paragraph">Horário</span>
            <span class="text-subtitle">das {{ item?.formatted_time }}</span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="text-paragraph">Duração</span>
            <span class="text-subtitle">50 minutos</span>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center"
            v-if="item.can_reschedule"
          >
            <Button
              outline
              fullWidth
              label="Reagendar sessão"
              @onClick="handleReschedule"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  EOffcanvasViews,
  navigateCanvas,
  OffcanvasViewsConfig,
} from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  methods: {
    handleReschedule() {
      navigateCanvas(
        EOffcanvasViews.reschedule_appointment,
        {
          ...OffcanvasViewsConfig.reschedule_appointment,
          props: { appointmentId: this.item?.id },
        },
        false
      );
    },
  },
});
</script>

<style scoped></style>
