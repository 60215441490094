import { defineComponent } from "vue";

export default defineComponent({
    props: {
        uploading: {
            type: Boolean,
            default: false
        },
        inputId: {
            type: String,
            default: 'image_uploader_input'
        },
        placeholder: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        inputKey: {
            type: String,
            default: ''
        }
    },
    emits: ['onImageUploaded'],
    methods: {
        openModalForFile() {
            document.getElementById(this.inputId)?.click()
        },
        async fileUploaded(event: any) {
            this.$emit('onImageUploaded', { ...event, key: this.inputKey })
        },
    }
})