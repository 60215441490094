import { useMainStore } from "@/store";
import NetworkService, { Endpoint } from "./NetworkService";

export default class PaymentService extends NetworkService {
  constructor() {
    super()
  }

  ERROR_TOKENS = {
    generic_reason: "tok_visa_chargeDeclined",
    insufficient_founds: "tok_visa_chargeDeclinedInsufficientFunds",
    lost_card: "tok_visa_chargeDeclinedLostCard",
    stolen_card: "tok_visa_chargeDeclinedStolenCard",
    expired_card: "tok_chargeDeclinedExpiredCard",
    incorrect_cvv: "tok_chargeDeclinedIncorrectCvc",
    processing_error: "tok_chargeDeclinedProcessingError",
  };

  async getCreditCards() {
    const store = useMainStore()
    const res = await this.get(Endpoint.cards)
    const cards = res.data.map((elem: any) => {
      return {
        card_id: elem.id,
        last_digits: elem.card.last4,
      }
    })
    let currentCardId = store.session?.favorite_card?.card_id
    let newFavoriteCard = null
    if (cards.length) {
      if (currentCardId) {
        newFavoriteCard = currentCardId ? cards.find((elem: any) => elem.card_id == currentCardId) : null
      } else {
        newFavoriteCard = cards[0]
      }
    }
    this.setFavoriteCard(newFavoriteCard)
    return { status: true, data: cards, favorite_card: newFavoriteCard }
  }

  createCreditCard(card_hash: string) {
    return this.post(Endpoint.cards, { card_hash });
  }

  setFavoriteCard(card: any) {
    const store = useMainStore()
    if (store.session) {
      store.saveSession({
        ...store.session, favorite_card: card
      })
    }
  }

  removeCard(card_id: string | number) {
    return this.delete(`${Endpoint.cards}?card_id=${card_id}`);
  }

  getCardErrorMessage(stripeErrorCode: string) {
    let msg = "";
    switch (stripeErrorCode) {
      case "invalid_expiry_month":
        msg = "Mês de vencimento do cartão inválido";
        break;
      case "invalid_expiry_year":
        msg = "Ano de vencimento do cartão inválido";
        break;
      case "invalid_cvc":
        msg = "Código de segurança do cartão inválido";
        break;
      case "incorrect_number":
        msg = "Número do cartão inválido";
        break;
      default:
        msg = "Verifique as informações do seu cartão e tente novamente";
    }
    return msg;
  }
}
