<template>
  <div class="offcanvas-body">
    <p class="text-paragraph">
      Adicione um vídeo seu do Youtube contando um pouco da sua experiência como
      profissional.
    </p>
    <YoutubeInput
      label="Link do Youtube"
      :value="session?.formatted_intro_video_link"
      height="200"
      :model="v$.form.intro_video_link"
    />
    <FormButton
      label="Concluir"
      :form="v$.form"
      :loading="submitting"
      :disabled="v$.invalid"
      @onClick="submit"
    />
  </div>
</template>

<script lang="ts">
import IntroVideoController from "./IntroVideoController";
export default IntroVideoController;
</script>
