<template>
  <div class="offcanvas-body">
    <p class="text-paragraph">
      Aqui você poderá escolher qual é a melhor forma para atender seus
      pacientes.
    </p>

    <!-- BLOCK AGENDA & ONLINE -->
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex align-items-center">
            <img src="@/assets/img/calendar_icon.svg" />
            <p class="text-header mb-0 ms-3">Sessões agendadas</p>
          </div>
          <SwitchInput
            :id="settingsType.block_agenda"
            color="secondary"
            :checked="!session?.block_agenda"
            @handleChange="
              updateAvailabilitySettings(settingsType.block_agenda)
            "
            :loading="settingsLoading.block_agenda"
          />
        </div>
        <p class="text-paragraph">
          Baseado nos horários disponíveis da sua agenda, os pacientes irão
          solicitar agendamento. Você receberá uma notificação e terá as opções
          de aceitar ou cancelar.
        </p>
        <hr class="my-4" />
        <div
          class="d-flex justify-content-between align-items-center cursor-pointer"
          @click="handleNavigation(views.appointment_price)"
        >
          <p class="mb-0 text-paragraph">Valor fixo por sessão</p>
          <div class="d-flex align-items-center">
            <p class="mb-0 text-paragraph me-2">
              {{ `R$${session?.appointment_price}` }}
            </p>
            <i class="fa-solid fa-chevron-right"></i>
          </div>
        </div>
        <hr class="my-4" />
        <div
          class="d-flex justify-content-between align-items-center cursor-pointer"
          @click="handleNavigation(views.schedule_info)"
        >
          <p class="mb-0 text-paragraph">Meus horários</p>
          <i class="fa-solid fa-chevron-right"></i>
        </div>
        <hr class="my-4" />
        <div class="d-flex justify-content-between align-items-center pb-3">
          <p class="mb-0 text-paragraph">Disponível agora</p>
          <SwitchInput
            :id="settingsType.online"
            color="secondary"
            :checked="session?.online"
            @handleChange="updateAvailabilitySettings(settingsType.online)"
            :loading="settingsLoading.online"
          />
        </div>
      </div>
    </div>

    <!-- FIRST APPOINTMENT -->
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex align-items-center">
            <img src="@/assets/img/users_icon.svg" />
            <p class="text-header mb-0 ms-3">Conversa inicial</p>
          </div>
          <SwitchInput
            :id="settingsType.first_appointment"
            color="secondary"
            :checked="session?.first_appointment"
            @handleChange="
              updateAvailabilitySettings(settingsType.first_appointment)
            "
            :loading="settingsLoading.first_appointment"
          />
        </div>
        <p class="text-paragraph">
          Permitir que a primeira sessão com um novo paciente tenha um valor
          diferenciado para ambos se conhecerem antes de iniciarem um tratamento
          juntos.
        </p>
        <hr class="my-4" />
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0 text-paragraph">Valor fixo por sessão</p>
          <div class="d-flex align-items-center">
            <p class="mb-0 text-subtitle me-2">
              {{
                `R$${
                  session?.first_appointment_price
                    ? session?.first_appointment_price
                    : session?.appointment_price
                }`
              }}
            </p>
          </div>
        </div>
        <hr class="my-4" />
        <div
          class="d-flex justify-content-between align-items-center pb-3 cursor-pointer"
          @click="handleNavigation(views.appointment_price)"
        >
          <p class="mb-0 text-paragraph">Editar valor</p>
          <i class="fa-solid fa-chevron-right"></i>
        </div>
      </div>
    </div>

    <!-- PARTNERSHIPS -->
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="d-flex align-items-center">
            <img src="@/assets/img/suitcase_icon.svg" />
            <p class="text-header mb-0 ms-3">Desconto para empresas</p>
          </div>
          <SwitchInput
            :id="settingsType.partnerships"
            color="secondary"
            :checked="session?.partnerships"
            @handleChange="
              updateAvailabilitySettings(settingsType.partnerships)
            "
            :loading="settingsLoading.partnerships"
          />
        </div>
        <p class="text-paragraph">
          Ao ativar esta função, você se tornará visível para os funcionários
          das empresas parceiras do Psiapp, que oferecem até 30% de desconto em
          sessões como um benefício para os pacientes.
        </p>
        <hr class="my-4" />
        <div class="d-flex justify-content-between align-items-center pb-3">
          <p class="mb-0 text-paragraph">Desconto nas sessões</p>
          <p class="mb-0 text-subtitle">Até 30%</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ESettingsType } from "@/enums/ESettingsType";
import { useMainStore } from "@/store";
import {
  EOffcanvasViews,
  IOffcanvasConfig,
  OffcanvasViewKey,
  OffcanvasViewsConfig,
  navigateCanvas,
} from "@/views/OffcanvasViews/OffcanvasViewsConfig";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "modal",
    },
  },
  computed: {
    session() {
      return useMainStore().session;
    },
    toogle() {
      return window.innerWidth > 964;
    },
  },
  data() {
    return {
      views: EOffcanvasViews,
      store: useMainStore(),
      settingsType: ESettingsType,
      settingsLoading: {
        block_agenda: false,
        online: false,
        first_appointment: false,
        partnerships: false,
      },
    };
  },
  methods: {
    async updateAvailabilitySettings(type: ESettingsType) {
      try {
        const { params, settingsLoading }: any =
          await this.$userService.updateAvailabilitySettings(type);
        this.settingsLoading = {
          ...this.settingsLoading,
          ...settingsLoading,
        };
        await this.$userService.update(params);
        this.$eventBus.$emit("UPDATE_HOME");
      } catch (error) {
        console.log(error);
      } finally {
        this.settingsLoading = {
          block_agenda: false,
          online: false,
          first_appointment: false,
          partnerships: false,
        };
      }
    },
    handleNavigation(path: OffcanvasViewKey) {
      const toggleOffcanvas: boolean = this.toogle && this.type !== "modal";
      const offcanvas_config: IOffcanvasConfig = OffcanvasViewsConfig[path];

      navigateCanvas(
        offcanvas_config.path as OffcanvasViewKey,
        offcanvas_config,
        toggleOffcanvas
      );
    },
  },
});
</script>
